import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../parts/Footer'; 

const Tradingconditions = props => {
 

  return (
    <div>
        <div className=''>
            <div>
                <img className='img-fluid w-100' src='/trade-condition.jpg' />
            </div>
            <div className='container'>
                <br/>
                <p className='cus-strip'>
                Do your interests lie in trading financial instruments, Our Currency, Stocks, CFDs, etc. accounts offer the best opportunity to trade these assets and hundreds more.
                </p>
                <p className='cus-strip'>
                EazyMarkets offers various accounts types, trading software and tools to facilitate individuals and institutional customers to trade all kinds of Stocks, Forex, Cryptocurrencies and Derivatives online. All Retail, Affiliates and White Label clients have the opportunity to access various spreads and liquidity via state of the art automated trading platform. EazyMarkets provides an unparalleled trading condition and variety of account options that clients can select to enjoy a tailored trading experience that perfectly suits their needs. Coupled with superior trading conditions and lightning fast execution, EazyMarkets provides all the tools and services needed for clients of any level to realise their trading ambitions.
                </p>
                <p className='cus-strip'>
                As a company that was built by traders for traders, we recognise that investors’ demands have become increasingly sophisticated nowadays and the need for a flexible trading environment is more essential than ever for their success. Therefore, we have heavily invested in delivering trading conditions that not only surpass our Clients’ expectations, but also suit their investment objectives, trading style and risk tolerance. 
                </p>
                <p className='cus-strip'>
                The standard account was our initial startup plan offering tight floating spreads, low commissions and instant execution, with our growing community, we decided to incorporate the mini account to give low investors a chance to benefit from this platform.
                </p>
                <p className='cus-strip'>
                Our Accounts feature a range of benefits that are sure to fulfill your trading needs.
                Have a closer look at our trading accounts – we’re confident that you’ll find what you’re looking for.
                </p>
                <p className='cus-strip mb-3'>
                We will continue working hard to maintain our lead as a provider of superior trading conditions.
                </p>
            </div>
        </div>
        <Footer />
    </div>
  );
};

export default Tradingconditions;
