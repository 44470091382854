import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../Alert';
import { login } from '../../../actions/auth';

export const Login = ({ login, isAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();
    // console.log(email);

    await login(email, password);
    setIsLoading(false);
  };

  if (isAuthenticated) {
    return window.location.replace("/dashboard");;
  }
  return (
    <Fragment>
      <div className="theCoverHeader" style={{paddingTop: "50px"}}>
        <div className='container'>
          <div className='' >
            <div className='row'>
           
              <div className='col-md-5 mx-auto'>
                <div className='p-3 my-2 auth-con ' >
              
                  <div className='text-center my-3'>
                    <h3>Sign In</h3>
                    {/* <img width='120' src='/egoras-favicon.png' alt='' /> */}
                  </div>
                  <form onSubmit={e => onSubmit(e)}>
                    <div className='form-group mt-4'>
                      <input
                        type='text'
                        className='auth-input form-control'
                        placeholder='Enter Email Address'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        type='password'
                        className='auth-input form-control'
                        placeholder='Enter Password'
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                      />
                    </div>
                  <Alert />

                    {/* <button type='submit' disabled={isLoading} className='btn btn-auth btn-block'>
                      {isLoading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : null}{' '}
                      Sign In
                    </button> */}

                    <div className='text-center'>
                      <button type='submit' disabled={isLoading} class="btn btn-manager my-2">
                        {isLoading ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : null}{' '}
                        Sign In
                      </button>
                    </div>
                  </form>
                  <div className='mt-4'>
                    <span>Don't have an account?</span>
                    <span className='float-right'>
                      <a href='/register' className='text-primary'>Sign Up</a>
                    </span>
                  </div>
                  <div className='text-center mt-3'>
                    <a href='/password/forget' className='text-primary'>Forget Password</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// export default Login;

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);
