import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { faCheckCircle, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../Alert';
import { activate } from '../../../actions/auth';

export const Activate = ({match, activate, isActivated}) => {
    const [formData, setFormData] = useState({
        id: '',
        msg: "",
        isSuccess: false
      });
      const [isLoading, setIsLoading] = useState(true);
      const { id, msg, isSuccess } = formData;
      
    useEffect(() => {
        setFormData({ ...formData, ['id']: match.params.id });
        //   const ff = { ...formData, ['id']: match.params.id };
        activate(match.params.id).then((data) => {
            // console.log(data.data);
            setFormData({...formData, ['msg']: data.data.msg, ['isSuccess']: data.data.isSuccess})
            setIsLoading(false);
        });
        
    }, []);



  return (
    <div className="theCoverHeader">
        <div className='container' style={{paddingTop: '100px'}}>
            <div className='row'>
                <div className='col-md-6 mx-auto'>
                    <div className='my-5'>
                        <div className=' text-center'>
                            {isLoading ? (
                                    <div className="loadingDiv">
                                <FontAwesomeIcon icon={faSpinner} spin />
                                <p>Activating please wait...</p>
                                </div>
                            ) : (
                               isSuccess ? ( 
                                <div className={isSuccess + " loadingDiv"}>
                                <FontAwesomeIcon icon={faCheckCircle} />
                                <p className='lead'>
                           
                                {msg}
                                </p>
                                 <hr />
                                 <p className='lead'>
                                     <Link to='/login' className='btn btn-primary btn-sm'>Continue to Login</Link>
                                 </p>
                                 </div>
                               ) : (
                                <div className={isSuccess + " loadingDiv"}>
                                    <FontAwesomeIcon icon={faTimes} />
                                <p className='lead'>
                           
                                {msg}
                                </p>
                                 <hr />
                                 <p className='lead'>
                                     <Link to='/login' className='btn btn-primary btn-sm'>Continue to Login</Link>
                                 </p>
                                 </div>
                               )
                            )}
                           
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

// export default Activate;

Activate.propTypes = {
    forgetpassword: PropTypes.func.isRequired,
    isActivated: PropTypes.bool
  };
  
  const mapStateToProps = state => ({
    isActivated: state.auth.isActivated
  });

  
  export default connect(null, { activate })(Activate);
