import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../parts/Footer';

export const Privacy = () => {
  return (
    <div>
      <div className='container'>
      <section className='pt-5'>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <div className='row'>
              <div className='col-md-3'>
                <h6>Privacy Policy</h6>
              </div>
              <div className='col-md-9'>
                <section>
                  <p className='cus-strip'>
                    1. Privacy and Cookie
                    <br />
                    This Privacy and Cookie Notice provides information on how EazyMarkets collects and processes your personal data when you visit our website or mobile applications.
                  </p>
                </section>
                <section>
                  <p className='cus-strip'>
                  2. The Data We Collect About You?
                  <br />
                  We collect your personal data in order to provide and continually improve our products and services. We may collect, use, store and transfer the following different kinds of personal data about you: Here for marketing and personal data optimization purposes.
                  </p>
                  <p className='cus-strip'>Information you provide to us: We receive and store the information you provide to us including your identity data, contact data, delivery address and financial data.</p>
                  <p className='cus-strip'>Information on your use of our website: We automatically collect and store certain types of information regarding your use of the EazyMarkets including information about your searches, views, downloads and transactions.</p>
                  <p className='cus-strip'>Information from third parties and publicly available sources: We may receive information about you from third parties including our carriers; payment service providers; merchants/brands; and advertising service providers.</p>
                </section>
                <section>
                  <p className='cus-strip'>
                  3. Cookies and How We Use Them
                  <br />
                  A cookie is a small file of letters and numbers that we put on your computer if you agree.
                  </p>
                  <p className='cus-strip'>
                  Cookies allow us to distinguish you from other users of our website, which helps us to provide you with an enhanced browsing experience. For example we use cookies for the following purposes: Recognizing and counting the number of visitors and to see how visitors move around the site when they are using it (this helps us to improve the way our website works, for example by ensuring that users can find what they are looking for).
                  </p>
                  <p className='cus-strip'>
                  Identifying your preferences and subscriptions e.g. language settings and Sending you newsletters and commercial/advertising messages tailored to your interests.
                    
                  </p>

                  <p className='cus-strip'>
                  Our approved third parties may also set cookies when you use
                    our website. Third parties include search engines, providers
                    of measurement and analytics services, social media networks
                    and advertising companies.
                  </p>
                </section>
                <section>
                  <p className='cus-strip'>
                  4. How We Use Your Personal Data
                  <br />
                   We use your personal data to operate, provide, develop and improve the products and services that we offer, including the following: Managing your relationship with us.
                  </p>
                  <p className='cus-strip'>Enabling you to participate in promotions, competitions and surveys. Improving our website, applications, products and services Recommending/advertising products or services which may be of interest to you.</p>
                  <p className='cus-strip'>Complying with our legal obligations, including verifying your identity where necessary.</p>
                </section>
                <section>
                  <p className='cus-strip'>
                  5. Your Legal Rights
                  <br />
                  It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
                  Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to access, correct or erase your personal data, object to or restrict processing of your personal data, and unsubscribe from our emails and newsletters.
                  </p>
                </section>
                <section>
                  <p className='cus-strip'>
                  6. Further Details
                  <br />
                  If you are looking for more information on how we process your personal data, or you wish to exercise your legal rights in respect of your personal data, please contact support@eazy-markets.com.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
        <Footer />
    </div>
  );
};

export default Privacy;
