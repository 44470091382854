import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Collapse, Button, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { faHome, faSpinner, faLessThan, faMoneyCheckAlt, faListAlt, faWallet, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe'
import { setAlert } from '../../../actions/alert';
import Alert from '../Alert';
import { userStat } from '../../../actions/auth';

import {  fetchDepositLinks } from "../../../actions/transactions";
import { makeInvestment } from '../../../actions/transactions';
import { makeWithdrawal } from '../../../actions/transactions';
import { submitWallet } from '../../../actions/auth';
import { submitChangePass } from '../../../actions/auth';
import axios from "axios";
import {
  API_URL as api_url  
} from "../../../actions/types.js"; 

const Dashboard2 = ({ setAlert, makeInvestment, auth, userStat, userstat, transact, submitWallet, makeWithdrawal, fetchDepositLinks, depositlinks, submitChangePass }) => {

  const [isOpenMore, setIsOpenMore] = useState(false);
  const [cStage, setcStage] = useState(0);
  const [isactivePlan, setIsactivePlan] = useState(0);
  const [portStage, setPortStage] = useState(0);
  const [depLinkData, setDepLinkData] = useState([]);
  const [depHistData, setDepHistData] = useState([]);
  const [withdHistData, setWithdHistData] = useState([]);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    // fetchDepositLinks();

    axios.get(
      api_url + "/api/transact/deposit/links",
      null,
      config
    ).then((data)  =>{
      // console.log(data.data.data, "Hello World");
      setDepLinkData(data.data.data);
    })
  }, []);




  const toggleMore = () => setIsOpenMore(!isOpenMore);

  const [activeTab, setActiveTab] = useState('1');
  
  const changeToggle = e => {
    e.preventDefault();
    console.log(e.currentTarget.id);
    
    if(e.currentTarget.id == cStage){
      setcStage(0)
    }else{
      setcStage(e.currentTarget.id)
    }
  };

  const portToggle = e => {
    // console.log(e.currentTarget.id);
    
    if(e.currentTarget.id == portStage){
      setPortStage(0)
    }else{
      setPortStage(e.currentTarget.id)
    }
  };

  
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTab2, setActiveTab2] = useState('1');

  const toggle2 = tab2 => {
    if (activeTab2 !== tab2) setActiveTab2(tab2);
  };

  const [activeTab3, setActiveTab3] = useState('1');

  const toggle3 = tab3 => {
    if (activeTab3 !== tab3) setActiveTab3(tab3);
  };

  const options = [
    {
      label: "Bitcoin (BTC)",
      value: "Bitcoin",
    },
    {
      label: "Ethereum (ETH)",
      value: "Ethereum",
    },
    {
      label: "Litecoin (LTC)",
      value: "Litecoin",
    },
    {
      label: "Bitcoin Cash (BCH)",
      value: "Bitcoin Cash",
    },
  ];


  const [formData, setFormData] = useState({
    coinselect: ''
  });
 

const { coinselect } = formData;

const coinsymbol = '';


  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  if (coinselect == 'Bitcoin') {
     let coinsymbol = 'BTC';
  } else if (coinselect == 'Ethereum') {
     let coinsymbol = 'ETH';
  } else if (coinselect == 'Litecoin') {
     let coinsymbol = 'LTC';
  } else if (coinselect == 'Bitcoin Cash') {
     let coinsymbol = 'BCH';
  }

  const [copySuccess, setCopySuccess] = useState('Copy');
    

    // your function to copy here

    const copyToClipBoard = async copyMe => {
        try {
        await navigator.clipboard.writeText(copyMe);
        setCopySuccess('Copied!');
        } catch (err) {
        setCopySuccess('Failed to copy!');
        }
    };
  
    const [formData1, setFormData1] = useState({
      microplan: 100,
      standardplan: 500,
      premiumplan: 5000
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);

    const { microplan, standardplan, premiumplan } = formData1;
    // const [userId, setUserId] = useState([]);
    const [userInfo, setUserInfo] = useState({
      userid: "",
      firstname: "",
      lastname: "",
      useremail: "",
      phonenumber: "",
      walletAddress: ""
      // balance: ""
    });
    useEffect(() => {
      if(auth.user !== null){
        // setUserId(auth.user);
        console.log(auth.user);
        
        const firstname = auth.user.firstname;
        const lastname = auth.user.lastname;
        const userid = auth.user.id;
        const useremail = auth.user.email;
        const phonenumber = auth.user.phonenumber;
        const walletAddress = auth.user.walletAddress;
        // console.log(userid); 
        setUserInfo({
          userid: userid,
          firstname: firstname,
          lastname: lastname,
          useremail: useremail,
          phonenumber: phonenumber,
          walletAddress: walletAddress
        });
        userStat(useremail).then((data) => {
            // console.log('ok');
        });
        
        const body = JSON.stringify({ useremail });
        console.log(body);
        axios.get(
          api_url + "/api/transact/get/histories/"+useremail,
          null,
          config
        ).then((data)  =>{
          // console.log(data.data.data1, "Hello World");
          // console.log(data.data.data2, "Hello World");
          setDepHistData(data.data.data1);
          setWithdHistData(data.data.data2);
        })
      }
    }, [auth]);
    const { firstname, lastname, userid, useremail, phonenumber, walletAddress } = userInfo;

  //   useEffect(() => {

  //     console.log(useremail);
      
     
  //  }, [])

    
    const [bankData, setBankData] = useState({
      deposit: 0,
      earnings: 0,
      increase: 0,
      investment: 0,
      percentage: 0
      // balance: ""
    });
    useEffect(() => {
        console.log(userstat);
        
        if(userstat !== null){
           
            if (typeof userstat !== undefined) {
               
               
                // console.log(deposit);
                setBankData({
                  deposit: userstat.deposit || 0,
                  earnings: userstat.earnings || 0,
                  increase: userstat.increase || 0,
                  investment: userstat.investment || 0,
                  percentage: userstat.percentage || 0,
                });
                // console.log(parseFloat(deposit));

                if (earnings == 0) {
                  setIsactivePlan(0)
                } else {
                  setIsactivePlan(1)
                }

            }
            
        } else {
          setBankData({
            deposit: 0,
            earnings: 0
          });
          setIsactivePlan(0)
        }
    
    }, [userstat]);
    
    
    
  
  const trigerMicro = async e => {
    // setIsLoading(true);
    e.preventDefault();
    // console.log(microplan);
    

    if (e.currentTarget.id == "micro") {
      setIsLoading1(true);
      if (deposit >= microplan) {
    
        let investPlan = 'Micro';
        let res = await makeInvestment( useremail, deposit, investPlan );
        setIsLoading1(false);
        if(res.success == true){
          
            setAlert('Investment successful.', 'success');
            setBankData({
              investment: deposit,
              deposit: 0,
              earnings,
              increase,
              percentage,
            });

        }
        
      } else {
        setAlert('Insufficent amount for this plan', 'danger');
        setIsLoading1(false);
        
      }
    } else if (e.currentTarget.id == "standard") {
      setIsLoading2(true);
      if (deposit >= standardplan) {
       
        let investPlan = 'Standard';
        let res = await makeInvestment( useremail, deposit, investPlan );
        setIsLoading2(false);
        if(res.success == true){
          // setIsRegSuccess(true);
            console.log('ok');
            
            setAlert('Investment successful.', 'success');
            setBankData({
              investment: deposit,
              deposit: 0,
              earnings,
              increase,
              percentage,
            });
            
        } 
        
      } else {
        setAlert('Insufficent amount for this plan', 'danger');
        setIsLoading2(false);
        
      }
    } else if (e.currentTarget.id == "premium") {
      setIsLoading3(true);
      if (deposit >= premiumplan) {
       
        let investPlan = 'Premium';
        let res = await makeInvestment( useremail, deposit, investPlan );
        setIsLoading3(false);
        if(res.success == true){
          // setIsRegSuccess(true);
            console.log('ok');
            setAlert('Investment successful.', 'success');
            setBankData({
              investment: deposit,
              deposit: 0,
              earnings,
              increase,
              percentage,
            });
        } 
        
      } else {
        setAlert('Insufficent amount for this plan', 'danger');
        setIsLoading3(false);
        
      }
    }

    
  }
  const { deposit, earnings, increase, percentage, investment } = bankData;

  const [walletData, setWalletData] = useState({
    walletAdd: '',
    otherAddr:  '',
    coinLabel: ''  
  });
 

const { walletAdd, otherAddr, coinLabel } = walletData;

const onChange3 = e =>
setWalletData({ ...walletData, [e.target.name]: e.target.value });

const onSubmitWithdrawal = async e => {
  // console.log(e.currentTarget.id);
  setIsLoading(true);
  e.preventDefault();
  // console.log(coinLabel);
  
  const coinName = coinLabel.split("_");
  const coinSp = coinName[1];
  if (earnings === '0') {
    console.log('insufficent fund');
    setAlert('Invalid amount.', 'danger');
    setIsLoading(false);
    
  } else {
    if (coinLabel == '') {

      const coinSp = ''
      // console.log(walletAddress);
        let res = await makeWithdrawal(walletAddress, firstname, lastname, useremail, earnings, coinSp);
        if(res.success == true){
          setIsLoading(false);
          // console.log('ok');
          setAlert('Your withdrawal request has been received and is being processed.', 'success');
  
        } else{
          setIsLoading(false);
        }
         
    } else {
      // console.log(coinLabel);
        let res = await makeWithdrawal(otherAddr, firstname, lastname, useremail, earnings, coinSp);
        if(res.success == true){
          setIsLoading(false);
          // console.log('ok');
          setAlert('Your withdrawal request has been received and is being processed.', 'success');
  
        } else{
          setIsLoading(false);
        }
    }
  }
  
};

  const onChange2 = e =>
  setWalletData({ ...walletData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();
    // console.log(walletAdd);
 

    if (walletAdd == '') {
      setAlert('Enter your wallet address.', 'danger');
      // setIsLoading(false);
    } else {
      // console.log(username);
      let res = await submitWallet(walletAdd, useremail);
      // setIsLoading(false);
      if(res.success == true){
        // setIsRegSuccess(true);
        setcStage(0);
        setAlert('Wallet Address updated successfully.', 'success');

      }

      
    }
  };

  const [changePassword, setChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
    
    // balance: ""
  });

  const { oldPassword, newPassword, confirmPassword } = changePassword;

  const onChange6 = e =>
  setChangePassword({ ...changePassword, [e.target.name]: e.target.value });

  const onSubmitPass = async e => {
    // setIsLoading(true);
    e.preventDefault();
    console.log(oldPassword);

    if (newPassword !== confirmPassword) {
      console.log('Password do not match');
      setAlert('Password do not match.', 'danger');
      
    } else {
      let res = await submitChangePass(useremail, oldPassword, confirmPassword);
      // setIsLoading(false);
      if(res.success == true){
        // setIsRegSuccess(true);
        setcStage(0);
        setAlert('Password changed successfully.', 'success');

      }
    }
 

    // if (walletAdd == '') {
    //   setAlert('Enter your wallet address.', 'danger');
    //   // setIsLoading(false);
    // } else {
    //   // console.log(username);
    //   let res = await submitWallet(walletAdd, useremail);
    //   // setIsLoading(false);
    //   if(res.success == true){
    //     // setIsRegSuccess(true);
    //     setcStage(0);
    //     setAlert('Wallet Address updated successfully.', 'success');

    //   }
    // }
  };

  return (
    <div>
      <Alert absoluteClass="absoluteClass" />
      <div className='dash-body' style={{height: '100%'}}>
        {/* <div className='container pb-3 mb-5'> */}
          {/* <div className='row'> */}
            {/* <div className='col-md-12'> */}
              
              <TabContent activeTab={activeTab}>
                <TabPane tabId='1' className='mb-5'>
                  
                  <div className='container pb-3 mb-5'>
                    <div className='p-3'>
                      <h3 className='text-center'>Hi, {firstname+' '+lastname}</h3>
                      <div className='my-2'>
                        <div className='row'>
                          <div className='col-md-4 mb-4'>
                            <div className='text-center mt-3'>
                              <div className='giveaway2'>
                                <img className='img-fluid' width='80' src='bank.svg' />
                              </div>
                              <h6>Make Deposit</h6>
                            </div>
                          </div>
                          
                          <div className='col-md-4 mb-4'>
                            <div className='text-center mt-3'>
                              <div className='giveaway2'>
                                <img className='img-fluid' width='80' src='return-of-investment.svg' />
                              </div>
                              <h6>Invest</h6>
                            </div>
                          </div>
                          
                          <div className='col-md-4 mb-4'>
                            <div className='text-center mt-3'>
                              <div className='giveaway2'>
                                <img className='img-fluid' width='80' src='reward2.svg' />
                              </div>
                              <h6>Earn Profit</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h5 className='text-center mt-4'>Multiple Investment Options</h5>
                        <div className='row'>
                          <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                              <div className='dash-card'>
                                
                                <div className='packages'>
                                  <div className='p-item d-flex justify-content-around p-2 cus-strip light-con'>
                                    <div className='text-center font-weight-bold text-uppercase plan-cl'>
                                      MICRO ACCOUNT
                                    </div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    {/* <div className=''><b>Execution Type:</b></div><div className='d-inline-block text-right'> Instant Market Execution</div> */}
                                    <div className=''><b>Execution Type:</b></div><div className='d-inline-block text-right ml-auto'>  Instant Market Execution</div>

                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Automated Trading</b></div><div className='d-inline-block text-right ml-auto'>   Yes</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Min. Investment:</b></div><div className='d-inline-block text-right ml-auto'> $100</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Expected Return in %:</b></div><div className='d-inline-block text-right ml-auto'> 20 - 25%</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Available for Withdrawals:</b></div><div className='d-inline-block text-right'> After 3 Working Days</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Traded Instruments:</b></div><div className='d-inline-block text-right'> Cryptocurrencies, Forex, CFD, Indices, Commodities</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Commission:</b></div><div className='d-inline-block text-right ml-auto'> 3% on profit</div>
                                  </div>
                                  
                                </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                              <div className='dash-card'>
                                
                                <div className='packages'>
                                  <div className='p-item d-flex justify-content-around p-2 cus-strip light-con'>
                                    <div className='text-center font-weight-bold text-uppercase plan-cl'>
                                      STANDARD ACCOUNT
                                    </div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Execution Type:</b></div><div className='d-inline-block text-right ml-auto'>  Instant Market Execution</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Automated Trading</b></div><div className='d-inline-block text-right ml-auto'>   Yes</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Min. Investment:</b></div><div className='d-inline-block text-right ml-auto'> $500</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Expected Return in %:</b></div><div className='d-inline-block text-right ml-auto'> 100 - 120%</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Available for Withdrawals:</b></div><div className='d-inline-block text-right'> After 10 Working Days</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Traded Instruments:</b></div><div className='d-inline-block text-right ml-auto'> Cryptocurrencies, Forex, CFD, Indices, Commodities</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Commission:</b></div><div className='d-inline-block text-right ml-auto'> 3% on profit</div>
                                  </div>
                                  
                                </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                              <div className='dash-card'>
                                
                                <div className='packages'>
                                  <div className='p-item d-flex justify-content-around p-2 cus-strip light-con'>
                                    <div className='text-center font-weight-bold text-uppercase plan-cl'>
                                      PREMIUM ACCOUNT
                                    </div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Execution Type:</b></div><div className='d-inline-block text-right ml-auto'>  Instant Market Execution</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Automated Trading</b></div><div className='d-inline-block text-right ml-auto'>   Yes</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Min. Investment:</b></div><div className='d-inline-block text-right ml-auto'> $5000</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Expected Return in %:</b></div><div className='d-inline-block text-right ml-auto'> 500 - 600%</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Available for Withdrawals:</b></div><div className='d-inline-block text-right'> After 30 Working Days</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip light-con'>
                                    <div className=''><b>Traded Instruments:</b></div><div className='d-inline-block text-right ml-auto'> Cryptocurrencies, Forex, CFD, Indices, Commodities</div>
                                  </div>
                                  <div className='p-item d-flex justify-content-start p-2 cus-strip dark-con'>
                                    <div className=''><b>Commission:</b></div><div className='d-inline-block text-right ml-auto'> 3% on profit</div>
                                  </div>
                                  
                                </div>
                              </div>
                          </div>
                          
                        </div>
                      </div>
                      <div className='mt-4'>
                        <div className='text-center mt-5'>
                          <h5>Our Top Platform, Built For You</h5>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div class="media media-dash-item mt-3"> 
                              <img class="mr-3" width='35' src="dash/secure.svg" alt="Generic placeholder image" />
                              <div class="media-body">
                                <h6 class="mt-0">SAFE AND SECURE</h6>
                                Benefiting from the leading-edge hybrid wallet technology, EazyMarkets doesn’t hold any assets or private keys on escrow!
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div class="media media-dash-item mt-3"> 
                              <img class="mr-3" width='35' src="dash/telegram.svg" alt="Generic placeholder image" />
                              <div class="media-body">
                                <h6 class="mt-0">INSTANT TRADING</h6>
                                EazyMarkets core matching technology enables +200K instant pairs per second!
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 mt-3'>
                            <div class="media media-dash-item mt-3">
                              <img class="mr-3" width='35' src="dash/trade.svg" alt="Generic placeholder image" />
                              <div class="media-body">
                                <h6 class="mt-0">Automated Trading</h6>
                                EazyMarkets is not just another exchange! It provides users with smart trading solutions!
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 mt-3'>
                            <div class="media media-dash-item mt-3">
                              <img class="mr-3" width='35' src="dash/extension-manager.svg" alt="Generic placeholder image" />
                              <div class="media-body">
                                <h6 class="mt-0">EXTENSIVE MARKETS</h6>
                                Trade varieties of digital assets, currencies, commodities, stocks and indices.
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 mt-3'>
                            <div class="media media-dash-item mt-3"> 
                              <img class="mr-3" width='35' src="dash/multiple-connector-points.svg" alt="Generic placeholder image" />
                              <div class="media-body">
                                <h6 class="mt-0">DIVERSITY AND SIMPLICITY</h6>
                                Enjoy diversified trading solutions, including spot, margin, futures, swap, and many more all in one place!
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 mt-3'>
                            <div class="media media-dash-item mt-3">
                              <img class="mr-3" width='35' src="dash/withdraw.svg" alt="Generic placeholder image" />
                              <div class="media-body">
                                <h6 class="mt-0">INVEST & WITHDRAW AT EASE</h6>
                                Make investments and withdrawals instantly from EazyMarkets using various cryptocurrencies 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-4'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <h5 className='text-center mt-3 mb-4'>A Show-off of Our Expert Traders</h5>
                            <div className='invest-stat'>
                              <div className='stat-step'>
                                <div className='stat-number'>
                                  <span>1</span>
                                </div>
                                <div className='stat-manager-info'>
                                  <div className='manager-img-div'>
                                    <img className='img-fluid' src='/expert1.jpg' />
                                  </div>
                                  <div className='manager-name-div'>
                                    <div className='manager-name'>George Lopez</div>
                                    <div className='manager-number'>HGD_Invest</div>
                                  </div>
                                </div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>4.8/5</div>
                                <div className='stat-step-text'>User score</div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>1M<sup>+</sup></div>
                                <div className='stat-step-text'>Trading Vol.</div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>40<sup>+</sup></div>
                                <div className='stat-step-text'>Trading Sys.</div>
                              </div>
                            </div>
                            <div className='invest-stat'>
                              <div className='stat-step'>
                                <div className='stat-number'>
                                  <span>2</span>
                                </div>
                                <div className='stat-manager-info'>
                                  <div className='manager-img-div'>
                                    <img className='img-fluid' src='/expert2.jpg' />
                                  </div>
                                  <div className='manager-name-div'>
                                    <div className='manager-name'>Theo Davis</div>
                                    <div className='manager-number'>EA Tech</div>
                                  </div>
                                </div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>4.7/5</div>
                                <div className='stat-step-text'>User score</div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>4M<sup>+</sup></div>
                                <div className='stat-step-text'>Trading Vol.</div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>38<sup>+</sup></div>
                                <div className='stat-step-text'>Trading Sys.</div>
                              </div>
                            </div>
                            <div className='invest-stat'>
                              <div className='stat-step'>
                                <div className='stat-number'>
                                  <span>3</span>
                                </div>
                                <div className='stat-manager-info'>
                                  <div className='manager-img-div'>
                                    <img className='img-fluid' src='/expert3.jpg' />
                                  </div>
                                  <div className='manager-name-div'>
                                    <div className='manager-name'>Oscar Miller</div>
                                    <div className='manager-number'>Week Trader</div>
                                  </div>
                                </div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>4.4/5</div>
                                <div className='stat-step-text'>User score</div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>3M<sup>+</sup></div>
                                <div className='stat-step-text'>Trading Vol.</div>
                              </div>
                              <div className='stat-step split'>
                                <div className='stat-step-number'>37<sup>+</sup></div>
                                <div className='stat-step-text'>Trading Sys.</div>
                              </div>
                            </div>
                            <div>
                            {!isOpenMore ? (
                              // <FontAwesomeIcon icon={faSpinner} spin />
                            <button className='btn pt-0 text-left cus-strip text-primary' onClick={toggleMore} style={{ marginBottom: '1rem' }}> See more</button>
                            ) : null}{' '}
                            <Collapse isOpen={isOpenMore}>
                              <div>
                                <div className='invest-stat'>
                                  <div className='stat-step'>
                                    <div className='stat-number'>
                                      <span>4</span>
                                    </div>
                                    <div className='stat-manager-info'>
                                      <div className='manager-img-div'>
                                        <img className='img-fluid' src='/expert4.jpg' />
                                      </div>
                                      <div className='manager-name-div'>
                                        <div className='manager-name'>Arthur Jones</div>
                                        <div className='manager-number'>FX Bilion</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>4.9/5</div>
                                    <div className='stat-step-text'>User score</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>2M<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Vol.</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>51<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Sys.</div>
                                  </div>
                                </div>
                                <div className='invest-stat'>
                                  <div className='stat-step'>
                                    <div className='stat-number'>
                                      <span>5</span>
                                    </div>
                                    <div className='stat-manager-info'>
                                      <div className='manager-img-div'>
                                        <img className='img-fluid' src='/expert5.jpg' />
                                      </div>
                                      <div className='manager-name-div'>
                                        <div className='manager-name'>Freddie Brown</div>
                                        <div className='manager-number'>Tali Invest</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>4.6/5</div>
                                    <div className='stat-step-text'>User score</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>3M<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Vol.</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>49<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Sys.</div>
                                  </div>
                                </div>
                                <div className='invest-stat'>
                                  <div className='stat-step'>
                                    <div className='stat-number'>
                                      <span>6</span>
                                    </div>
                                    <div className='stat-manager-info'>
                                      <div className='manager-img-div'>
                                        <img className='img-fluid' src='/expert6.jpg' />
                                      </div>
                                      <div className='manager-name-div'>
                                        <div className='manager-name'>Leo Johnson</div>
                                        <div className='manager-number'>Forex Wisdom</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>4.3/5</div>
                                    <div className='stat-step-text'>User score</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>1M<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Vol.</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>39<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Sys.</div>
                                  </div>
                                </div>
                                <div className='invest-stat'>
                                  <div className='stat-step'>
                                    <div className='stat-number'>
                                      <span>7</span>
                                    </div>
                                    <div className='stat-manager-info'>
                                      <div className='manager-img-div'>
                                        <img className='img-fluid' src='/expert7.jpg' />
                                      </div>
                                      <div className='manager-name-div'>
                                        <div className='manager-name'>Noah Smith</div>
                                        <div className='manager-number'>aqaa</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>4.5/5</div>
                                    <div className='stat-step-text'>User score</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>3M<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Vol.</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>50<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Sys.</div>
                                  </div>
                                </div>
                                <div className='invest-stat'>
                                  <div className='stat-step'>
                                    <div className='stat-number'>
                                      <span>8</span>
                                    </div>
                                    <div className='stat-manager-info'>
                                      <div className='manager-img-div'>
                                        <img className='img-fluid' src='/expert8.jpg' />
                                      </div>
                                      <div className='manager-name-div'>
                                        <div className='manager-name'>Jack Wilson</div>
                                        <div className='manager-number'>TradeWitJack</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>4.7/5</div>
                                    <div className='stat-step-text'>User score</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>1M<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Vol.</div>
                                  </div>
                                  <div className='stat-step split'>
                                    <div className='stat-step-number'>40<sup>+</sup></div>
                                    <div className='stat-step-text'>Trading Sys.</div>
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId='2' className='mb-5'>
                  <div className='p-3' style={{textAlign: "center"}}>
                  <div className='' id="tradingVD">
                  <Iframe url="https://chart.eazy-markets.com/dashboard.php"
                    // height="500"
                    width="100%"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"/>
             
                 
                  
                 
                 </div>
                  </div>
                </TabPane>
                <TabPane tabId='3' className='mb-5'>
                  <div className=''>
                    <div>
                      <Nav tabs className='border-bottom-0 funds-nav'>
                        <NavItem className='text-center flex-fill text-dark'>
                          <NavLink
                            className={classnames({ active: activeTab2 === '1' })}
                            onClick={() => { toggle2('1'); }}
                          >
                            Portfolio
                          </NavLink>
                        </NavItem>
                        <NavItem className='text-center flex-fill text-dark'>
                          <NavLink
                            className={classnames({ active: activeTab2 === '2' })}
                            onClick={() => { toggle2('2'); }}
                          >
                            History
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab2}>
                        <TabPane tabId="1">
                          <div className='container'>
                            <div className='row'>
                              <div className='col-md-10 mx-auto'>
                                <div className='p-3'>
                                  <div className='d-flex justify-content-between'>
                                    <div>
                                      <p className='font-sm mb-1'>Deposit Bal.</p>
                                      <h3 id='' className='port-bal mb-0'><span className="text-dark"><b>$</b> {deposit}</span></h3>
                                      {/* <span className='font-sm mb-1 ml-45 text-success'>${increase} ({percentage}%)</span> */}
                                    </div>
                                    <div>
                                      <p className='font-sm mb-1'>Portfolio Value</p>
                                      <h3 id='' className='port-bal mb-0'><span className="text-dark"><b>$</b> {investment}</span></h3>
                                      <span className='font-sm mb-1 ml-45 text-success'>${increase} ({percentage}%)</span>
                                    </div>
                                    <div>
                                      <p className='font-sm mb-1'>Withdrawal Bal.</p>
                                      <h3 className='port-bal mb-0'><span className="text-dark"><b>$</b> {earnings}</span></h3>
                                    </div>
                                  </div>
                                
                                  <div className='py-2'>
                                    <div className='row'>
                                      <div className='col-md-4 col-4 px-1'>
                                        <Link to='/deposit' className='btn btn-funds btn-block'>DEPOSIT</Link>
                                        {/* <button id='1' onClick={portToggle} className='btn btn-funds btn-block'>DEPOSIT</button> */}
                                      </div>
                                      <div className='col-md-4 col-4 px-1'>
                                        <button id='2' onClick={portToggle} className='btn btn-funds btn-block'>INVEST</button>
                                      </div>
                                      <div className='col-md-4 col-4 px-1'>
                                        <button id='3' onClick={portToggle} className='btn btn-funds btn-block'>WITHDRAW</button>
                                      </div>
                                    </div>
                                  </div>
                                  
                                      {
                                        <div>
                                            {portStage == 2 ? (
                                                <div className='row'>
                                                <div className='col-md-4 mb-4'>
                                                  <div className='mt-3'>
                                                      <div className='p-2 py-3 funds-con'>
                                                        <h6 className='text-center'>Micro Account</h6>
                                                        <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                          <div className=''><b>Min. Investment:</b></div><div className='d-inline-block text-right ml-auto'> $100</div>
                                                        </div>
                                                        <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                          <div className=''><b>Expected Return in %:</b></div><div className='d-inline-block text-right ml-auto'> 20 - 25%</div>
                                                        </div>
                                                        <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                          <div className=''><b>Available for Withdrawal:</b></div><div className='d-inline-block text-right ml-auto'> After 3 Working Days</div>
                                                        </div>
                                                        <div className='text-center mt-3'>
                                                          
                                                          <button id='micro' onClick={trigerMicro} disabled={isLoading1} className='btn btn-acc btn-acc-color px-3'>
                                                            {isLoading1 ? (
                                                              <FontAwesomeIcon icon={faSpinner} spin />
                                                            ) : null}{' '}
                                                            Invest
                                                          </button>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </div>
                                                  <div className='col-md-4 mb-4'>
                                                    <div className='mt-3'>
                                                        <div className='p-2 py-3 funds-con'>
                                                          <h6 className='text-center'>Standard Account</h6>
                                                          <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                            <div className=''><b>Min. Investment:</b></div><div className='d-inline-block text-right ml-auto'> $500</div>
                                                          </div>
                                                          <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                            <div className=''><b>Expected Return in %:</b></div><div className='d-inline-block text-right ml-auto'> 100 - 120%</div>
                                                          </div>
                                                          <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                            <div className=''><b>Available for Withdrawal:</b></div><div className='d-inline-block text-right ml-auto'> After 10 Working Days</div>
                                                          </div>
                                                          <div className='text-center mt-3'>
                                                            <button id='standard' onClick={trigerMicro} disabled={isLoading2} className='btn btn-acc btn-acc-color px-3'>
                                                              {isLoading2 ? (
                                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                              ) : null}{' '}
                                                              Invest
                                                              </button>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <div className='col-md-4 mb-4'>
                                                    <div className='mt-3  mb-4'>
                                                        <div className='p-2 py-3 funds-con'>
                                                          <h6 className='text-center'>Premium Account</h6>
                                                          <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                            <div className=''><b>Min. Investment:</b></div><div className='d-inline-block text-right ml-auto'> $5000</div>
                                                          </div>
                                                          <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                            <div className=''><b>Expected Return in %:</b></div><div className='d-inline-block text-right ml-auto'> 500 - 600%</div>
                                                          </div>
                                                          <div className='p-item d-flex justify-content-start py-0 px-2 cus-strip '>
                                                            <div className=''><b>Available for Withdrawal:</b></div><div className='d-inline-block text-right ml-auto'> After 30 Working Days</div>
                                                          </div>
                                                          <div className='text-center mt-3'>
                                                            <button id='premium' onClick={trigerMicro} disabled={isLoading3} className='btn btn-acc btn-acc-color px-3'>
                                                              {isLoading3 ? (
                                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                              ) : null}{' '}
                                                              Invest
                                                            </button>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    }

                                    {
                                        <div>
                                            {portStage == 1 ? (
                                                <div className=''>
                                                    <div className='col-md-12'>
                                                        <p className='cus-strip my-2' style={{fontSize: '12px'}}>
                                                          You fund your EazyMarkets trade account using Bitcoin and other listed altcoins. We're partnered with several cryptocurrency payment services like simplex, moonpay, coinmama. These are to ensure a seamless transaction on the userbase.
                                                        </p>
                                                        <div className="form-group">
                                                            <label for="" className='cus-strip'>select</label>
                                                            <select name='coinselect' value={coinselect} 
                                                                  onChange={e => onChange(e)} className="form-control cus-form-control custom-select" id="">
                                                                <option value=''>Choose coin</option>
                                                                  {depLinkData.map((option) => (
                                                                      <option value={option.address}>{option.coin}</option>
                                                                  ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                         
                                                          
                                                          
                                                          <div className="input-group mb-2 mr-sm-2">
                                                            
                                                            <input type="text" className="form-control cus-form-control" id="" value={coinselect} placeholder="" disabled/>
                                                            <div className="input-group-append">
                                                              <div className="input-group-text py-1">
                                                                <button className='btn p-0'>{copySuccess}</button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className='text-center'>
                                                            <button className='btn btn-primary'>Proceed</button>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            ) : null}
                                        </div>
                                    }

                                    {
                                        <div>
                                            {portStage == 3 ? (
                                                <div className=''>
                                                    <div className='col-md-12'>
                                                        <form onSubmit={e => onSubmitWithdrawal(e)}>
                                                          <div className="form-group">
                                                              <label for="exampleFormControlSelect1" className='cus-strip'>Withdraw to your BTC wallet</label>
                                                              <input type='text' className='form-control cus-form-control'name='walletAddress' value={walletAddress} disabled/>
                                                          </div>
                                                          
                                                          <div>
                                                            <button className='btn cus-strip' id='6' onClick={changeToggle} style={{ marginBottom: '1rem' }}> Use another address?</button>
                                                            <Collapse  className={cStage == 6 ? "trans show": "trans"}>
                                                              <div>
                                                                <div className="form-group">
                                                                  {/* <label for="exampleFormControlSelect1" className='cus-strip'>Use another address?</label> */}
                                                                  <select name='coinLabel' value={coinLabel} onChange={e => onChange3(e)} className="form-control cus-form-control custom-select" id="">
                                                                    <option value=''>Choose coin</option>
                                                                      {options.map((option) => (
                                                                        <option value={option.label+'_'+option.value}>{option.label}</option>
                                                                      ))}
                                                                  </select>
                                                              </div>

                                                              <div className=''>
                                                                <p className="otp-pass cus-strip mb-1">Wallet Address</p>
                                                                
                                                                <div className="input-group mb-2 mr-sm-2">
                                                                  <input type="text" onChange={e => onChange3(e)} className="form-control cus-form-control" value={otherAddr} name='otherAddr' placeholder="Paste your wallet here" />
                                                                </div>
                                                                
                                                              </div>
                                                              </div>
                                                            </Collapse>
                                                          </div>
                                                          <div className='text-center'>
                                                            <button type='sumit' disabled={isLoading} className='btn btn-primary btn-sm'>
                                                              {isLoading ? (
                                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                              ) : null}{' '}
                                                              OK
                                                            </button>
                                                          </div>
                                                        </form>

                                                    </div>
                                                    
                                                </div>
                                            ) : null}
                                        </div>
                                    }
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div className='mt-3 container'>
                            <Nav tabs className='border-bottom-0'>
                              <NavItem className='text-center flex-fill cus-strip'>
                                <NavLink
                                  className={classnames({ active: activeTab3 === '1' })}
                                  onClick={() => { toggle3('1'); }}
                                >
                                  Deposit History
                                </NavLink>
                              </NavItem>
                              <NavItem className='text-center flex-fill cus-strip'>
                                <NavLink
                                  className={classnames({ active: activeTab3 === '2' })}
                                  onClick={() => { toggle3('2'); }}
                                >
                                  Withdrawal History
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab3}>
                              <TabPane tabId="1">
                                <div className='row'>
                                  <div className='col-md-7 mx-auto'>
                                    <table className="table table-bordered mt-3 history-table">
                                      <thead>
                                        <tr>
                                          <th className='cus-strip'scope="col">Date</th>
                                          <th className='cus-strip'scope="col">Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        { 
                                            depHistData.map((dsa, i) =>{
                                            return (
                                              // <span>{dsa.id}</span>
                                              <tr>
                                              <td className='cus-strip'><Moment format="D MMM YYYY" withTitle>{dsa.createdAt}</Moment></td>
                                              <td className='cus-strip'>${dsa.amount}</td>
                                            </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </TabPane>
                              <TabPane tabId="2">
                                <div className='row'>
                                  <div className='col-md-7 mx-auto'>
                                    <table className="table table-bordered mt-3 history-table">
                                      <thead>
                                        <tr>
                                          <th className='cus-strip'scope="col">Date</th>
                                          <th className='cus-strip'scope="col">Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        { 
                                            withdHistData.map((dsa, i) =>{
                                            return (
                                              // <span>{dsa.id}</span>
                                              <tr>
                                              <td className='cus-strip'><Moment format="D MMM YYYY" withTitle>{dsa.createdAt}</Moment></td>
                                              <td className='cus-strip'>${dsa.amount}</td>
                                            </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId='4' className='mb-5'>
                  <div className='profile-head p-2'>
                      <h6 className='text-white'>{firstname+' '+lastname}</h6>
                      <p className='text-white'>{useremail}</p>
                      <p className='text-white'>{phonenumber}</p>
                  </div>
                  <div className='p-3'>
                    {/* <Alert absoluteClass="absoluteClass" /> */}
                    <div className='row'>
                      
                      <div className='col-md-12 mx-auto'>
                      {/* <div className="form-group row">
                          <label for="inputEmail3" className="col-sm-6 col-8 col-form-label cus-strip">Language:</label>
                          <div className="col-sm-6 col-4">
                            <select className="custom-select mr-sm-2" id="inlineFormCustomSelect">
                              <option selected>Eng</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div> */}
                        <div className='profile-collapse mt-5'>
                        {/* <Alert /> */}
                        {/* <Alert absoluteClass="absoluteClass" /> */}
                          <button className='pl-0 btn btn-block text-left' id='1' onClick={changeToggle} ><img width='20' className='img-fluid' src='/wallet.svg' /> Payment Detail</button>
                       
                          <Collapse className={cStage == 1 ? "trans show": "trans"}>
                            <div>
                             
                              <form onSubmit={e => onSubmit(e)}>
                                <div className="form-group mb-1">
                                  <label className="form-label-sm cus-strip">Enter BTC Wallet Address</label>
                                  <div className="">
                                    <input type="text" name='walletAdd' value={walletAdd} onChange={e => onChange2(e)} className="form-control form-control-sm cus-form-control" placeholder="Enter BTC Wallet Address" />
                                  </div>
                                </div>
                                <button type='submit' className='btn cus-strip text-primary'>Save</button>
                              </form>
                            </div>
                          </Collapse>
                        </div>

                        <div className='profile-collapse'>
                          <Link to='/invitefriend' className='pl-0 btn btn-block text-left' ><img width='20' className='img-fluid' src='/gift.svg' /> Invite a friend</Link>
                          
                        </div>

                        <div className='profile-collapse'>
                          {/* <Alert /> */}
                          
                          <button className='pl-0 btn btn-block text-left' id='2' onClick={changeToggle} ><img width='20' className='img-fluid' src='/padlock.svg' /> Security</button>
                          <Collapse  className={cStage == 2 ? "trans show": "trans"}>
                            <div>
                              <h6 className='cus-strip my-2'>Change Password</h6>
                              <form onSubmit={e => onSubmitPass(e)}>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <div className="form-group">
                                      <label className='cus-strip' for="inputEmail4">Old Password</label>
                                      <input name='oldPassword' value={oldPassword} onChange={e => onChange6(e)} type="text" className="form-control cus-form-control" placeholder="Old Password" />
                                    </div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className="form-group">
                                      <label className='cus-strip' for="inputEmail4">New Password</label>
                                      <input name='newPassword' value={newPassword} onChange={e => onChange6(e)} type="text" className="form-control cus-form-control" placeholder="New Password" />
                                    </div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className="form-group mb-1">
                                      <label className='cus-strip' for="inputEmail4">Confirm New Password</label>
                                      <input name='confirmPassword' value={confirmPassword} onChange={e => onChange6(e)} type="text" className="form-control cus-form-control" placeholder="Confirm New Password" />
                                    </div>
                                  </div>
                                  <button type='submit' className='btn cus-strip text-primary'>Save</button>
                                </div>
                              </form>
                            </div>
                          </Collapse>
                        </div>

                        <div className='profile-collapse'>
                          <Link to='/support' className='pl-0 btn btn-block text-left' ><img width='20' className='img-fluid' src='/call-center.svg' /> Support</Link>
                          
                        </div>

                        <div className='profile-collapse'>
                          <Link to='/about' className='pl-0 btn btn-block text-left' ><img width='25' className='img-fluid' src='/group.svg' /> About EazyMarkets</Link>
                          
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  {/* < */}
                </TabPane>
              </TabContent>
              
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
        <div className='position-fixed' style={{bottom: '0px',left: '0px',width: '100%',background: '#0d141e'}}>
          <Nav tabs>
            <NavItem className='dash-nav flex-fill'>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              > 
                <div>
                  <FontAwesomeIcon icon={faHome} className='fa-lg' />
                </div>
                Home 
              </NavLink>
            </NavItem>
            <NavItem className='dash-nav flex-fill'>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faListAlt} className='fa-lg' />
                </div>
                Markets
              </NavLink>
            </NavItem>
            <NavItem className='dash-nav flex-fill'>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3');
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faWallet} className='fa-lg' />
                </div>
                Funds
              </NavLink>
            </NavItem>
            <NavItem className='dash-nav flex-fill'>
              <NavLink id='profile'
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  toggle('4');
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faUserCircle} className='fa-lg' />
                </div>
                Profile
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    </div>
  );
};

// export default Dashboard2;

Dashboard2.propTypes = {
  setAlert: PropTypes.func.isRequired,
  makeInvestment: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  userstat: state.userstat,
  transact: state.transact,
  depositlinks: state.depositlinks,
  // isAuthenticated: state.auth.isAuthenticated
});
// export default connect({ setAlert })(Register);
export default connect(mapStateToProps, { setAlert, userStat, makeInvestment, submitWallet, makeWithdrawal, fetchDepositLinks, submitChangePass })(Dashboard2);
