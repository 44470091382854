import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../parts/Footer';

export const About = () => {
  return (
    <div>
      <div className='container'>
      <section className='pt-5'>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <div className='row'>
              <div className='col-md-3'>
                <h5>About Us</h5>
              </div>
              <div className='col-md-9'>
                <section>
                  {/* <h5>1. About this Notice</h5> */}
                  <p className='cus-strip'>
                  EazyMarkets is a UK-based company that specializes in Digitally-enabled financial services. EazyMarkets is bringing a revolution with its services by developing borderless financial services that ensure a faster, simpler, and uninterrupted flow of Investments.
                  </p>
                </section>
                <section>
                  {/* <h5>2. The Data We Collect About You?</h5> */}
                  <p className='cus-strip'>
                    Launched on April 10th, 2019, EazyMarkets runs on the advanced algorithmic system to automate daily trades and it is built with an aim to provide a user-friendly experience where users can access a wide range of financial products and services backed by industry-leading security processes and insurance protection.
                    Our software system was developed to give our investors an expected range of profits according to the investments ((scheme.))
                    This unique system provides our clients the opportunity to grow their investment in the secured safe environment. 
                  </p>
                </section>
                <section>
                  {/* <h5>3. Cookies and How We Use Them</h5> */}
                  
                  <p className='cus-strip'>
                  We have built our relations on trust, simplicity, and integrity where all are free to express themselves for fruitful collaboration. This helps us in empowering everyone collectively and create innovative solutions. We maintain the same values in developing our products and give our customers a service of the highest order.
                  </p>
                  <p className='cus-strip'>
                    Through its services, EazyMarkets is upgrading lives every day.
                  </p>
                </section>
                <div className='text-center mt-5'>
                  <h5>Meet Our Team:</h5>
                </div>
                <section className='mt-4'>
                  <p>
                    <img className='img-fluid left-align' src='/images1.jpg' />
                  </p>
                  <h5>Jack Davis – Head Market Analyst</h5>
                  <p className='cus-strip' style={{textAlign: 'justify'}}><span >With over 25 years experience working for a host of globally recognized organisations in the City of London,&nbsp;Jack&nbsp;Davis&nbsp;is a passionate advocate of keeping things simple, doing what is probable and understanding how the news, charts and sentiment work together to&nbsp;provide trading opportunities across all asset classes and all time frames.</span></p>
                  <p className='cus-strip' style={{textAlign: 'justify'}}><span>Jack&nbsp;has been trading the global markets since 1997 and has also run his own consultancy. He believes that knowing yourself and employing effective risk management are the keys to successful trading.</span></p>
                </section>
                <section>
                  <p>
                    <img className='img-fluid left-align' src='/images2.jpg' />
                  </p>
                  <h5>Harry Moore – Market Analyst</h5>
                  <p className='cus-strip' style={{textAlign: 'justify'}}><span >With more than 7 years of experience as an independent trader, Harry is a graduate in Biology specializing in terrestrial ecology analysis. He also completed multiple specializations in leadership.</span></p>
                  <p className='cus-strip' style={{textAlign: 'justify'}}><span>In his career as an independent trader he has dedicated himself to studying trading and has specialized in technical and fundamental analysis, with extensive knowledge in financial markets oriented to stock market strategies and teaching.</span></p>
                </section>
                <section className='mt-4'>
                  <p>
                    <img className='img-fluid left-align' src='/images3.jpg' />
                  </p>
                  <h5>Oscar Hernandez, Market Analyst – EZ Educational Office – UK</h5>
                  <p className='cus-strip' style={{textAlign: 'justify'}}><span >Oscar Hernandez is a trader with more than 12 years of experience as a researcher, lecturer and independent consultant in business and investment. He is dedicated and takes an effective approach in any research or analysis before making any decisions.</span></p>
                  <p className='cus-strip' style={{textAlign: 'justify'}}><span>He began to venture into Forex trading since 2012, and has been actively sharing his knowledge with other traders since 2015. Using the “less is more” approach, he believes that a simple but efficient approach is the best way to build self as a trader in the financial industry.</span></p>
                </section>
                <section className='mt-4'>
                  <p>
                    <img className='img-fluid left-align' src='/images4.jpg' />
                  </p>
                  <h5>Olivia Thomas, Market Analyst – EZ Educational Office – USA</h5>
                  
                  <p className='cus-strip' style={{textAlign: 'justify'}}><span >Having completed her five-year-long studies in the UK, Olivia Thomas has been awarded a BSc in Mathematics and Physics from the University of Bath and a MSc degree in Mathematics, while she holds a postgraduate diploma (PGdip) in Actuarial Science from the University of Leicester.</span></p>
                  <p className='cus-strip' style={{textAlign: 'justify'}}><span>Following her various academic endeavors, Olivia set eyes on the fascinating Forex industry where she has obtained valuable experiences after being active in the field for the past few years. In 2019, she joined EazyMarkets as a Market Analyst with a mission to actively support the company’s clients.</span></p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
      <Footer />
    </div>
  );
};

export default About;
