import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../parts/Footer'; 

const Protectfunds = props => {

  return (
    <div>
        <div className=' mt-4'>
            <div className='container'>
                <h6>Important Strength and Security Facts about EazyMarkets</h6>
                <div className='text-center'>
                    <img className='img-fluid' width='' src='/funds.jpg' />
                </div>
                <br/>
                <h6>Financial Strength</h6>
                <p className='cus-strip'>
                When placing your money with a broker, you need to make sure your broker is secure and can endure through good and bad times. The financial statements of EazyMarkets are available on our website for your review.
                </p>
                <p className='cus-strip'>
                On a consolidated basis, EazyMarkets exceeds $8.5 billion in equity capital, over $6.0 billion in excess of regulatory requirements.
                EazyMarkets owners are our public company, EazyMarkets Ltd. (18.5%) and the firm's employees and their affiliates (81.5%). Unlike at most other firms, where management owns a relatively small share, we participate substantially in the downside just as much as in the upside. Because of this vested interest, we run our business conservatively.
                We manage our brokerage and market making businesses in separate companies, which are registered with local securities and/or commodities regulators. We maintain strict systematic and procedural separation between the two business lines and we do not commingle or utilize client-segregated assets for proprietary operations. Although certain affiliates of EazyMarkets trade for their own account, our client-facing businesses do not conduct proprietary trading. We have completed the winding down of the bulk of our market making operations.
                We hold no material positions in over-the-counter securities or derivatives. We hold no CDOs, MBS or CDS.
                Our positions are marked to market daily and the resulting payables/receivables are reconciled to outside sources automatically.
                Our real-time margining system marks all client positions to market continuously. All orders are credit vetted before being executed and positions in accounts with inadequate margin deposits are liquidated automatically.

                </p>
                <h6>How We Handle Client Assets</h6>
                <p className='cus-strip'>
                Client money is segregated in special bank or custody accounts, which are designated for the exclusive benefit of clients of EazyMarkets. This protection (the SEC term is "reserve" and the CFTC term is "segregation") is a core principle of securities and commodities brokerage. By properly segregating the client's assets, if no money or stock is borrowed and no futures positions are held by the client, then the client's assets are available to be returned to the client in the event of a default by or bankruptcy of the broker.
 
                </p>
                <p className='cus-strip'>
                As a practice, EazyMarkets holds an excess amount of its own money in these reserve and segregated accounts to ensure that there is more than enough cash to protect all clients.
 
                </p>
                <p className='cus-strip'>Securities client money is protected as follows:</p>
                <p className='cus-strip'>
                A majority is invested in U.S. Treasury securities, including direct investments in Treasury bills, Treasury Notes and reverse repurchase agreements, where the collateral received is in the form of U.S. Treasury securities. These transactions are conducted with third parties and guaranteed through a central counterparty clearing house (Fixed Income Clearing Corp., a subsidiary of the Depository Trust & Clearing Corp.). The collateral remains in the possession of EazyMarkets and held at a custody bank in a segregated Reserve Safekeeping Account for the exclusive benefit of clients. U.S. Treasury securities may also be pledged to a clearing house to support client margin requirements on securities options positions.
                Client cash is maintained on a net basis in the reserve accounts, which reflects the long balances of some clients and loans to others.  
 
                </p>
                <p className='cus-strip'>
                No single bank holds more than 5% of total client funds held by EazyMarkets. As of December 2020, the following banks held deposits from EazyMarkets (this list is subject to change over time at EazyMarkets discretion). Certain banks, which are affiliates or branches of foreign financial institutions, are subject to regulatory oversight by the Federal Reserve and the Office of the Comptroller of the Currency.
                Branch Banking and Trust Company
                Bank of the West
                BMO Harris Bank, N.A.
                Citizens Bank, N.A.
                KeyBank National Association
                Standard Chartered Bank
                SunTrust Bank
                US Bank, N.A.
 
                </p>
                <p className='cus-strip mb-4'>
                Current SEC regulations require broker-dealers to perform a detailed reconciliation of client money and securities (known as the "reserve computation") at least weekly to ensure that client monies are properly segregated from the broker-dealer's own funds. In order to further enhance our protection of our clients' assets, EazyMarkets sought and received approval from FINRA (the Financial Industry Regulatory Authority), to perform and report the reserve computation on a daily basis, instead of once per week. EazyMarkets initiated daily computations in February 2020 along with daily adjustments of the money set aside in safekeeping for our clients. Reconciling our accounts and client reserves daily instead of weekly is just another way that Interactive Brokers seeks to provide state-of-the-art protection.
 
                </p>
                
            </div>
        </div>
        <Footer />
    </div>
  );
};

export default Protectfunds;
