import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../parts/Footer';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from "axios";
import { setAlert } from '../../../actions/alert';
import Alert from '../Alert';


import {
  API_URL as api_url  
} from "../../../actions/types.js"; 

export const Users = ({ setAlert, auth }) => {
  const [isLoading, setIsLoading] = useState(false);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const [bankData, setBankData] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);

  useEffect(() => {

     axios.get(
      api_url + "/api/users/get/referals",
      null,
      config
    ).then((data)  =>{
      console.log(data.data.data, "Hello World");
      setAllUsersData(data.data.data);
    })
    
  }, [])
    
//   const { deposit, investment, earnings, increase, percentage, email } = editInfo;



  return (
    <div>
      <div className='container'>

        <div className='row'>
            <div className='col-md-7 mx-auto'>
              
              <div className='p-3 table-responsive'>
                <table className="table table-bordered mt-3 history-table">
                  <thead>
                    <tr>
                        <th className='cus-strip' scope="col">No</th>
                        <th className='cus-strip' scope="col">Email</th>
                        <th className='cus-strip' scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                      { 
                          allUsersData.map((dsa, i) =>{
                              if (dsa.referal) {
                                let ii = 1;
                                return (
                                    // {/* // <span>{dsa.id}</span> */}
                                    <tr>
                                        <td className='cus-strip'>{ii++}</td>
                                        <td className='cus-strip'>{dsa.referal}</td>
                                        <td className='cus-strip'><a href={'/scapio/users/referers/'+dsa.referal} className='btn btn-primary btn-sm text-white'>View Referrals</a></td>
                                    </tr>
                                   )
                              }
                          
                         })
                       }
                    </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
    // auth: state.auth,
    // allusers: state.allusers,
    // bankstat: state.bankstat,
    // transact: state.transact
    // isAuthenticated: state.auth.isAuthenticated
  });

// export default Users;

export default connect(mapStateToProps, { setAlert })(Users);
