import axios from 'axios';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  FORGET_PASSWORD,
  FORGET_PASSWORD_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_STATS,
  USER_STATS_FAIL,
  USER_REFERRAL,
  USER_REFERRAL_FAIL,
  ACTIVATION,
  ACTIVATION_FAIL,
  LOGOUT,
  FETCH_STORE_KEEPER_SUCCESS,
  FETCH_STORE_KEEPER_FAIL,
  ADD_WALLET,
  ADD_WALLET_FAIL,
  API_URL as api_url,
} from './types';
import setAuthToken from "../utils/setAuthToken";

export const loadUser = () => async (dispatch) => {
  // console.log("Call Me If You CAN");
  
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(api_url + "/api/auth");
    // console.log("Yes I call You because i can", res.data);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Fetch User Stat
export const userStat = (useremail) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // console.log(userid);
  
  try {
    const res = await axios.get(
      api_url + "/api/users/stat/" + useremail,
      null,
      config
    );
    // console.log(res);
    
    dispatch({
      type: USER_STATS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USER_STATS_FAIL,
    });
    console.log('bad');
    
  }
};


// Get Referals
export const getReferals = (useremail) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // console.log(useremail);
  
  try {
    const res = await axios.get(
      api_url + "/api/users/get/referrals/" + useremail,
      null,
      config
    );
    // console.log(res);
    
    dispatch({
      type: USER_REFERRAL,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USER_REFERRAL_FAIL,
    });
    console.log('bad');
    
  }
};



// Register User
export const register = (firstname, lastname, country, countrycode, phonenumber, useremail, password, ref) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ firstname, lastname, country, countrycode, phonenumber, useremail, password, ref });
  // console.log(body);

  try {
    const res = await axios.post(api_url+'/api/users', body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });
    return {
      success: true
    }

    //dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(errors);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });

    return {
      success: false
    }
  }

  // console.log(res.data);
};

// Login User
export const login = (email, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, password });
  // console.log(body);

  try {
    const res = await axios.post(api_url + "/api/auth", body, config);
    console.log(res);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
     dispatch(loadUser());
  } catch (err) {
    console.log(err);

    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Submit Wallet
export const submitWallet = (walletAdd, useremail ) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ walletAdd, useremail });
  console.log(body);

  try {
    const res = await axios.post(api_url + '/api/users/add/wallet', body, config);

    dispatch({
      type: ADD_WALLET,
      payload: res.data
    });
    return {
      success: true
    }

    // dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    // console.log(errors);
    

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ADD_WALLET_FAIL
    });
    return {
      success: false
    }
  }
};

// Forgetpassword User
export const forgetpassword = email => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email });
  // console.log(body);

  try {
    const res = await axios.post(api_url + '/api/users/reset/password', body, config);

    dispatch({
      type: FORGET_PASSWORD,
      payload: res.data
    });
    return {
      success: true
    }

    // dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    // console.log(errors);
    

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: FORGET_PASSWORD_FAIL
    });
    return {
      success: false
    }
  }
};

// Resetpassword User
export const resetpassword = ( password, id ) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ password, id });
  // console.log(body);

  try { 
    const res = await axios.post(api_url + '/api/users/change/password', body, config);

    dispatch({
      type: RESET_PASSWORD,
      payload: res.data
    });
    return {
      success: true
    }

    // dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: RESET_PASSWORD_FAIL
    });
    return {
      success: false
    }
  }
};



// Change Password User
export const submitChangePass = ( useremail, oldPassword, confirmPassword ) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ useremail, oldPassword, confirmPassword });
  console.log(body);

  try { 
    const res = await axios.post(api_url + '/api/users/profile/change/password', body, config);

    dispatch({
      type: CHANGE_PASSWORD,
      payload: res.data
    });
    return {
      success: true
    }

    // dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CHANGE_PASSWORD_FAIL
    });
    return {
      success: false
    }
  }
};

// Log user out
export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

// Activte User
export const activate = ( id ) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ id });
  // console.log(body);

  try {
    const res = await axios.post(api_url + '/api/users/activate', body, config);

    return res;
   
    // console.log(res.statusText);

  
  } catch (err) {
    return err;
  
  }
};
