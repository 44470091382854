import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faBars, faFileAlt, faSignInAlt, faSignOutAlt, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import { login, logout } from '../../../actions/auth';
import PropTypes from 'prop-types';

export const Header = ({ auth, logout, match }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  

  const toggle = () => setIsOpen(!isOpen);
  
  const logoutUser = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    window.location.replace("/");


  }


  useEffect(() => {
    if(localStorage.getItem("token") == null || localStorage.getItem("token") == undefined){

    }else{
      setIsLoggedIn(true);
      console.log("He is logged");
    }
  },  []);


  useEffect(() => {
    if(localStorage.getItem("token") == null || localStorage.getItem("token") == undefined){

    }else{
      setIsLoggedIn(true);
      console.log("He is logged");
    }
  },  [match]);

  return (
    <div className="TheoverallHeaderWrapper">
      
     
            <Fragment>
           
             </Fragment>
        
      
      <nav className="navbar navbar-expand-lg RedUces  fixed-top navbar-dark mobile-head" style={{backgroundColor: '#0d141e', marginTop: "50px"}}>
      <div className="container-fluid " id="toNav" >
                    <ul className=" ml-auto">
                      <li className="nav-item active">
                      { isLoggedIn ? null :(  <Fragment><a className=" btn text-white" href="/login" >  <FontAwesomeIcon icon={faSignInAlt} /> Login </a> <a href='/register' class="btn btn-sell btn-acc-color mt-1 p-2"><FontAwesomeIcon icon={faUser} /> Open Account</a></Fragment>)}
                        
                      </li>
                      <li className="nav-item">
                      { isLoggedIn ? ( <Fragment><a href='/dashboard' class=" btn btn-sell btn-acc-color mt-1 p-2"><FontAwesomeIcon icon={faUser} /> My Account</a> <a href='' onClick={logoutUser} class="btn btn-sell-danger bg-danger btn-acc-color mt-1 p-2" style={{backgroundColor: "red !important"}}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</a></Fragment>) : null
}
                      </li>
                      
                    </ul>
                    
                  </div>
        <a className="navbar-brand" href="/">
          <img style={{height: "40px"}} className='img-fluid logo-img d-none d-md-block' src='/21.svg' />
          <img style={{height: "35px"}} className='img-fluid logo-img d-block d-md-none' src='/21.svg' />
        </a>
        { isLoggedIn ? (
        <a className="nav-link text-white ml-auto  mobile-head dashboardBtn" href="/dashboard" style={{fontSize: '12px'}}> <FontAwesomeIcon icon={faUser} /> My Account </a>

        ) : (
        <a className="nav-link text-white ml-auto loginM  mobile-head" href="/login" style={{fontSize: '12px'}}> <FontAwesomeIcon icon={faSignInAlt} /> Login </a>

                  ) }
       


        {
          !isOpen ? (
            <button onClick={toggle} className="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><FontAwesomeIcon icon={faBars} /></button>

          ) : (
            <button onClick={toggle} className="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><FontAwesomeIcon icon={faTimes} /> </button>

          )
        }

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          

          
                <Fragment>
                <ul className="navbar-nav ml-auto">
            
                  <li className="nav-item ">
                    <a className="nav-link text-white" href="/howitworks">How it works</a>
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link text-white" href="/invitefriend">Refer a friend</a>
                    {/* <Link className="nav-link text-white" to="/login">Refer a friend</Link> */}
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link text-white" href="/education">Education</a>
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link text-white" href="/about">About us</a> 
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link text-white" href="/support">Contact us</a>
                  </li>
                  { isLoggedIn ? (<li className="nav-item ">
                    <a className="nav-link text-white" onClick={logoutUser} href="">Sign Out</a>
                  </li>) : null }
                </ul>
                </Fragment>
          
        </div>
      </nav>
      <div className='clearfix'></div>
    </div>
  );
};


Header.propTypes = {
  // login: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool
};



const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(mapStateToProps, { logout })(Header);
