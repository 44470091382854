import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { faHome, faLessThan, faGreaterThan, faListAlt, faWallet, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import { Footer } from '../parts/Footer'; 

const Automatedtrade = props => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
        <div className=''>
            <div>
                <img className='img-fluid w-100' src='/trading.jpg' />
            </div>
            <div className='container'>
                <br/>
                <p className='cus-strip'>
                Designed for EVERYONE from Novice to professional traders.
                </p>
                <p className='cus-strip'>
                Eazy-Markets utilizes a series of automated instructions to generate buy and sell orders and instantly executes this order once the citeria are met. 
                </p>
                <p className='cus-strip'>
                We make use of a trading strategy which is based on technical analysis, advanced statistical and mathematical computations to automate the process.
                </p>
                <p className='cus-strip'>
                The auto trading enables us to carry out many trades in a small amount of time, whilst ensuring the best possible turnouts for our investors with an added benefit of taking the emotion out of your trading decisions. That’s because all the rules of the trade are already built into the set parameter.
                </p>
                <p className='cus-strip'>
                The Eazy-Markets team spends millions a year on trading teams that specialise in building black-box trading models to get an edge in the market. This usually consists of PhD scientists, mathematicians and engineers. One of the biggest attractions to the automated trading models is the fact that they remove human error. Managing emotions like fear and greed are the biggest obstacle for all human traders - a problem Eazy-Markets trading strategies simply do not have.
                </p>
                <p className='cus-strip'>
                This type of strategy is the domain of algorithmic traders as trades are taken within nano-seconds to get the best prices. While the concept is quite simple, in practice, only algorithmic trading robots can take advantage of these price differences as they may only happen for a few seconds or less. Therefore, this type of strategy is mainly designed for high-frequency traders with access to the best speeds and execution models. Most institutional traders utilising high-frequency arbitrage trading strategies will have internet cables connecting directly to these exchanges to take trades within nano-seconds.
                </p>
                <p className='cus-strip mb-3'>
                Eazy-Markets also uses a trend-following momentum trading strategy
                This is a popular type of algorithmic trading strategy used by all types of traders, both large and small. The idea is that if a trend is in place then the market could continue in that direction until there are signals it has come to end. This is actually one reason why movements in the financial markets have changed considerably over time. Nowadays price moves tend to go much further and faster due to a lot of algorithms jumping on board the move very quickly.
                </p>
                <br />
            </div>
        </div>
        <Footer />
    </div>
  );
};

export default Automatedtrade;
