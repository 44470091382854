import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Link } from 'react-router-dom';
import { faHome, faMinus, faCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from 'reactstrap';
import Footer from '../parts/Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
export const Education = () => {
    const [cStage, setcStage] = useState(0);
    const changeToggle = e => {
        e.preventDefault();
        console.log(e.currentTarget.id);
        
        if(e.currentTarget.id == cStage){
          setcStage(0)
        }else{
          setcStage(e.currentTarget.id)
        }
      };
  return (
    <div>
        <div className='d-none d-md-block' style={{backgroundImage: `url(/placeweb3.png)`,backgroundRepeat: 'no-repeat',  backgroundSize: "cover", height: '50vh', width: '100%'}}>
            {/* <img  src='/placeweb3.png' /> */}
        </div>
        <div className='d-block d-md-none' style={{backgroundImage: `url(/placeweb3a.png)`,backgroundRepeat: 'no-repeat', backgroundSize: "cover", height: '20vh', width: '100%'}}>
            {/* <img  src='/placeweb3.png' /> */}
        </div>
      <div className='container'>
      <section className='pt-5'>
        <div className='row'>
          <div className='col-md-12 mx-auto px-0 px-md-3'>
            <div className=''>
                <ol style={{listStyle: 'disc', marginLeft: '25px', marginBottom: '20px'}}>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='1' onClick={changeToggle} >Intro to Financial Trading  </button></h6></li>
                    <Collapse className={cStage == 1 ? "trans show": "trans"}>
                        <div>
                            <p className='cus-strip'>Financial trading is the buying and selling of financial assets. It is carried out in one of two ways: via an exchange or over the counter (OTC). An exchange is a highly-organised marketplace where you can trade a specific or various type of instrument. For example, you can trade US shares on the New York Stock Exchange (NYSE). When you trade over the counter, the trade is made directly between two parties. For example, investing in any asset from a trading broker such as EazyMarkets.</p>
                            <h6 style={{fontSize: '14px'}}>What is traded in financial trading?</h6>
                            <p className='cus-strip'>Financial instruments such as forex, cryptocurrencies, shares or bonds, or derivatives such as CFDs, futures or options can be traded. Whatever the instrument being traded, the intended outcome is always the same: to make a profit. If you buy an instrument at a low price and sell it at a higher price, you make a profit. If you sell an instrument for less than you bought it, you’ll make a loss.</p>
                            <h6 style={{fontSize: '14px'}}>Who trades?</h6>
                            <p className='cus-strip'>In financial markets, millions of companies, individuals, institutions and even governments are all trading at the same time. But what is a trader? A trader is defined as a person who buys and sells financial instruments with the aim of making a profit.</p>
                            <p className='cus-strip'>Some traders stick to a particular instrument or asset class, while others have more diverse portfolios. Some do lots of research before placing a trade, while others read charts and watch out for trends. But trades all have one thing in common – they are all intended for profit making. With EazyMarkets, you access varieties of instruments with our leading-edge strategy which automates this process for users. Our trade experts has a built in strategy which is a combined efforts plus several years of hard work to enable our investors earn without prior information on financial trading.</p>
                            <h6 style={{fontSize: '14px'}}>Which markets can be traded?</h6>
                            <p className='cus-strip'>There are thousands of different financial markets to be traded, including shares, indices, cryptocurrencies and forex. EazyMarkets trades on more than 16,000 financial instruments, which includes more than 500 cryptocurrency pairs, 12,000 shares, different currency pairs and over 30 indices.</p>
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='2' onClick={changeToggle} >What is Technical Analysis?  </button></h6></li>
                    <Collapse className={cStage == 2 ? "trans show": "trans"}>
                        <div>
                            <p className='cus-strip'>Technical analysis is a means of examining and predicting price movements in the financial markets, by using historical price charts and market statistics. It is based on the idea that if a trader can identify previous market patterns, they can form a fairly accurate prediction of future price trajectories.</p>
                            <p className='cus-strip'>It is one of the two major schools of market analysis, the other being fundamental analysis. Whereas fundamental analysis focuses on an asset’s ‘true value’, with the meaning of external factors and intrinsic value both considered, technical analysis is based purely on the price charts of an asset. It is solely the identification of patterns on a chart that is used to predict future movements.</p>
                            <h6 style={{fontSize: '14px'}}>Examples of technical analysis tools</h6>
                            <p className='cus-strip'>Technical analysts have a wide range of tools that they can use to find trends and patterns on charts. These include moving averages, support and resistance levels, Bollinger bands, and more. All of the tools have the same purpose: to make understanding chart movements and identifying trends easier for technical traders.</p>
                            <h6 style={{fontSize: '14px'}}>Pros of technical analysis</h6>
                            <p className='cus-strip'>Being able to identify the signals for price trends in a market is a key component of any trading strategy. All traders need to work out a methodology for locating the best entry and exit points in a market, and using technical analysis tools is a very popular way of doing so.</p>
                            <p className='cus-strip'>In fact, technical analysis tools are so commonly used, that many believe they have created self-fulfilling trading rules: As more and more traders use the same indicators to find support and resistance levels, there will be more buyers and sellers congregated around the same price points, and the patterns will inevitably be repeated.</p>
                            <h6 style={{fontSize: '14px'}}>Cons of technical analysis</h6>
                            <p className='cus-strip'>There will always be an element of market behaviour that is unpredictable. There is no definitive guarantee that any form of analysis – technical or fundamental – will be 100% accurate. Although historical price patterns give us an insight into an asset’s likely price trajectory, that is no promise of success.</p>
                            <p className='cus-strip'>Traders should use a range of indicators and analysis tools to get the highest level of assurance possible, and have a risk management strategy in place to protect against adverse movements.</p>
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='3' onClick={changeToggle} >What is Fundamental Analysis?  </button></h6></li>
                    <Collapse className={cStage == 3 ? "trans show": "trans"}>
                        <div>
                            <p className='cus-strip'>Fundamental analysis is a method of evaluating the intrinsic value of an asset and analysing the factors that could influence its price in the future. This form of analysis is based on external events and influences, as well as financial statements and industry trends.</p>
                            <p className='cus-strip'>Fundamental analysis is one of two major methods of market analysis, with the other being technical analysis. While technical traders will derive all the information they need to trade from charts, fundamental traders look at factors outside of the price movements of the asset itself.</p>
                            <h6 style={{fontSize: '14px'}}>Examples of fundamental analysis</h6>
                            <p className='cus-strip'>There are various tools and techniques that can be used for fundamental analysis, but they have been categorised into two types of fundamental analysis: top-down analysis and bottom-up analysis. Top-down analysis takes a broader view of the economy, starting with the entire market before narrowing down into a sector, industry and finally a specific company. Conversely, bottom-up analysis starts with a specific stock and widens out to consider all the factors that impact its price.</p>
                            <p className='cus-strip'>Most fundamental analysis is used for evaluating share prices, but it can be used across a range of asset classes, such as bonds and forex.</p>
                            <p className='cus-strip'>The tools that traders might choose for their fundamental analysis vary depending on which asset is being traded. For example, share traders might choose to look at the figures in a company’s earnings report: revenue, earning per share (EPS), projected growth or profit margins. While forex traders may choose to assess the figures released by central banks that allow insight into the state of a country’s economy.</p>
                            <h6 style={{fontSize: '14px'}}>Pros of fundamental analysis</h6>
                            <p className='cus-strip'>Fundamental analysis helps traders and investors to gather the right information to make rational decisions about what position to take. By basing these decisions on financial data, there is limited room for personal biases.</p>
                            <p className='cus-strip'>Rather than establishing entry and exit points, fundamental analysis seeks to understand the value of an asset, so that traders can take a much longer-term view of the market. Once the trader has determined a numerical value for the asset, they can compare it to the current market price to assess whether the asset is over or under-valued. The aim is to then profit from the market correction.</p>
                            <h6 style={{fontSize: '14px'}}>Cons of fundamental analysis</h6>
                            <p className='cus-strip'>Fundamental analysis can be time consuming, it requires multiple areas of analysis which can make the process extremely complicated.</p>
                            <p className='cus-strip'>As fundamental analysis takes a much longer-term view of the market, the results of the findings are not suitable for quick decisions. Traders looking to create a methodology for entering and exiting trades in the short-term might be better suited to technical analysis.</p>
                            <p className='cus-strip'>It is also important to consider the best and the worst-case scenario. While fundamental analysis provides a more well-rounded view of the market, it is possible for negative economic, political or legislative changes to surprise markets.</p>
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='4' onClick={changeToggle} >Leverage Trading and Risk Management  </button></h6></li>
                    <Collapse className={cStage == 4 ? "trans show": "trans"}>
                        <div>
                        
                            <h6 style={{fontSize: '14px'}}>What is leverage?</h6>
                            <p className='cus-strip'>Leverage enables you to gain a large exposure to a financial market while only tying up a relatively small amount of your capital. In this way, leverage magnifies the scope for both gains and losses.</p>
                            
                            <h6 style={{fontSize: '14px'}}>Is leveraged dealing risky?</h6>
                            <p className='cus-strip'>Even though you only put up a relatively small amount of capital to open a position, your profit or loss is based on the full value of the position. Therefore, the amount you gain or lose could be relatively large compared to your initial outlay.</p>
                            <h6 style={{fontSize: '14px'}}>Protect against adverse movements for free</h6>
                            <p className='cus-strip'>Set a stop-loss to close your position automatically if the market moves against you. There’s no trigger charge, but no guarantee of protection against slippage – so your position could be closed out at a worse level if the market gaps.</p>
                            <p className='cus-strip'>What’s more, you don't pay for your stop if it’s triggered. If this happens, our guaranteed stop premiums offer excellent value in the market for most major indices and FX pairs.</p>
                            <h6 style={{fontSize: '14px'}}>You Don’t miss out on profits</h6>
                            <p className='cus-strip'>We also place a trailing stop when ww open your trade and it will move with your profit. If the market turns, your position will close out at your trailing stop’s new level. So we can lock in your profits without the need to monitor your position and adjust your stop.<br />
                            Like regular stop-losses, trailing stops don’t protect against slippage.
                            </p>
                            <h6 style={{fontSize: '14px'}}>Take profit automatically</h6>
                            
                            <p className='cus-strip'>We also set a limit order in line with your profit target, and we’ll close your position for you when the price hits your chosen level.</p>
                            <h6 style={{fontSize: '14px'}}>Always know your profit</h6>
                            <p className='cus-strip'>Keep an eye on the always-visible balance  in our platform, and react quickly if the market moves against you.</p>
                            <h6 style={{fontSize: '14px'}}>Trade with limited risk</h6>
                            <p className='cus-strip'>You needn’t risk more than you can afford when trading. Our limited-risk accounts can help protect you, ensuring that all your positions either have a guaranteed stop, or are on inherently limited-risk markets.</p>
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='5' onClick={changeToggle} >Trading Psychology  </button></h6></li>
                    <Collapse className={cStage == 5 ? "trans show": "trans"}>
                        <div>
                          <h6 style={{fontSize: '14px'}}>How important is psychology in trading?</h6>
                            <p className='cus-strip'>Maintaining the right mindset is one of the most important factors in being a successful trader. Find out how you can improve your trading psychology to minimise the effect of emotions and biases during your experiences on EazyMarkets.</p>
                            
                            <h6 style={{fontSize: '14px'}}>What is trading psychology?</h6>
                            <p className='cus-strip'>Trading psychology refers to a trader’s mindset during their time on the markets. It can determine the extent to which they succeed in securing a profit or it can provide an explanation as to why a trader incurred heavy losses.</p>
                            <p className='cus-strip'>Innate human characteristics like biases and emotions play a pivotal role in trading psychology. The main focus of learning about trading psychology is to become aware of the various pitfalls which are associated with a negative psychological trait and to develop more positive characteristics. Traders well-versed in trading psychology will generally not act on bias or emotion. They, therefore, stand a better chance of yielding a profit during their time on the markets or, at the very least, of minimising their losses.</p>
                            <p className='cus-strip'>
                            Trading psychology is different for every trader, as it is influenced by each individual’s own emotions and pre-determined biases. Some of the emotions which impact trading are: Happiness, Impatience, Anger, Fear, Pride etc. 
                            </p>
                            <h6 style={{fontSize: '14px'}}>How to improve your trading psychology</h6>
                            <p className='cus-strip'>Improving your trading psychology can most easily be achieved by becoming aware of your own emotions, biases and personality traits. Once you have acknowledged these, you can put a trading plan in place that takes these factors into account with the hope of mitigating any effect that they might have on your decision making.</p>
                            <p className='cus-strip'>As an example, if you are a naturally confident person, you may find that overconfidence and pride hamper your decision-making. For example, you might let losses run in the hope that the market will turnaround, rather than incurring a small loss on your trading account. This could lead to greater losses or the eventual collapse of your trading account.</p>
                            <p className='cus-strip'>To counter this, you might use stops as a way to minimise your losses and to make the decision about when to close a particular trade before you open the position. By doing this, you have become aware of your own biases and emotions as you have made a conscious decision not to act on them but rather, you have taken steps to combat them.</p>
                            <h6 style={{fontSize: '14px'}}>How does bias affect trading?</h6>
                            <p className='cus-strip'>Biases affect trading as they are, by definition, a predetermined personal disposition in favour of one thing over another. As a result, they can hinder your decision making during your time on the markets because they might cloud your judgments and lead you to act on gut feeling rather than reasoned fundamental or technical analysis.
                            </p>
                            <p className='cus-strip'>This is because trading bias means that you could be more likely to trade an asset that you have had past success on, or to avoid an asset on which you have incurred a historic loss. It is important that traders are aware of their conscious biases as this can help them overcome them and approach the markets with a more rational and calculated mindset.</p>
                            <h6 style={{fontSize: '14px'}}>There are five main types of bias:</h6>
                            
                            <p className='cus-strip'>Representative bias means that you will stick to or be more inclined to replicate previously successful trades. You might do this without carrying out analysis for every trade of this type because in the past, it has paid off for you. However, even if two trades seem similar, it is important to approach every trade on its own merits rather than on historical success</p>
                            <p className='cus-strip'>Negativity bias makes you more inclined to only look at the negative side of a trade, rather than acknowledging what went right. This could mean that you scrap an entire strategy when, in fact, you might only have needed to tweak it slightly to turn a profit.</p>
                            <p className='cus-strip'>Status quo bias means that you will continue to use old strategies or trades rather than exploring new ones – you will stick to the status quo. The danger arises when you fail to assess whether those old methods are still viable in the current market.</p>
                            <p className='cus-strip'>Confirmation bias is when you seek out, or give greater weight to, news and analysis that confirms your pre-formulated ideas. It may also be that you don’t seek out, or disregard, information which disproves your convictions.</p>
                            <p className='cus-strip'>Gambler’s fallacy is where you assume that because an asset has been increasing, it will continue to rise. There is no reason to believe that it should, similar to how there is no reason that a coin should land tails side up – rather than heads – after doing so a few times in a row.</p>
                            
                            
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='6' onClick={changeToggle} >Margin Trading  </button></h6></li>
                    <Collapse className={cStage == 6 ? "trans show": "trans"}>
                        <div>
                            <p className='cus-strip'>The initial margin is the minimum amount you’ll need to put up to open a position. It is sometimes called the deposit margin, or just the deposit.</p>
                          <h6 style={{fontSize: '14px'}}>Maintenance margin</h6>
                            <p className='cus-strip'>The maintenance margin, also known as variation margin, is extra money that we might need to request from you if your position moves against you. Its purpose is to ensure you have enough money in your account to fund the present value of the position at all times – covering any running losses.</p>
                          <h6 style={{fontSize: '14px'}}>Things to remember</h6>
                            <p className='cus-strip'>You should ensure that you have enough funds in your account to cover both margin and losses. If there isn't, you may be put on margin call.</p>
                            <p className='cus-strip'>CFD accounts are margined independently: funds in one account will not cover the margin requirement or losses in another.</p>
                            <p className='cus-strip'>You are able to limit your potential losses and reduce your margin requirement by the use of different stops (this is available to tier one only).Gambler’s fallacy is where you assume that because an asset has been increasing, it will continue to rise. There is no reason to believe that it should, similar to how there is no reason that a coin should land tails side up – rather than heads – after doing so a few times in a row</p>
                            
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='7' onClick={changeToggle} >Trading Forex, Indices, Commodities  </button></h6></li>
                    <Collapse className={cStage == 7 ? "trans show": "trans"}>
                        <div>
                            <h6 style={{fontSize: '14px'}}>What is forex trading?</h6>
                            <p className='cus-strip'>Forex, or foreign exchange, can be explained as a network of buyers and sellers, who transfer currency between each other at an agreed price. It is the means by which individuals, companies and central banks convert one currency into another – if you have ever travelled abroad, then it is likely you have made a forex transaction.</p>
                            <p className='cus-strip'>While a lot of foreign exchange is done for practical purposes, the vast majority of currency conversion is undertaken with the aim of earning a profit. The amount of currency converted every day can make price movements of some currencies extremely volatile. It is this volatility that can make forex so attractive to traders: bringing about a greater chance of high profits, while also increasing the risk.</p>
                            <h6 style={{fontSize: '14px'}}>How do currency markets work?</h6>
                            <p className='cus-strip'>Unlike shares or commodities, forex trading does not take place on exchanges but directly between two parties, in an over-the-counter (OTC) market. The forex market is run by a global network of banks, spread across four major forex trading centres in different time zones: London, New York, Sydney and Tokyo. Because there is no central location, you can trade forex 24 hours a day.</p>
                            <h6 style={{fontSize: '14px'}}>There are three different types of forex market:</h6>
                            <p className='cus-strip'>Spot forex market: the physical exchange of a currency pair, which takes place at the exact point the trade is settled – ie ‘on the spot’ – or within a short period of time</p>
                            <p className='cus-strip'>Forward forex market: a contract is agreed to buy or sell a set amount of a currency at a specified price, to be settled at a set date in the future or within a range of future dates</p>
                            <p className='cus-strip'>Future forex market: a contract is agreed to buy or sell a set amount of a given currency at a set price and date in the future. Unlike forwards, a futures contract is legally binding</p>
                            <p className='cus-strip'>​Most traders speculating on forex prices will not plan to take delivery of the currency itself; instead they make exchange rate predictions to take advantage of price movements in the market.</p>
                            <h6 style={{fontSize: '14px'}}>What is a base and quote currency?</h6>
                            <p className='cus-strip'>A base currency is the first currency listed in a forex pair, while the second currency is called the quote currency. Forex trading always involves selling one currency in order to buy another, which is why it is quoted in pairs – the price of a forex pair is how much one unit of the base currency is worth in the quote currency.</p>
                            <p className='cus-strip'>Each currency in the pair is listed as a three-letter code, which tends to be formed of two letters that stand for the region, and one standing for the currency itself. For example, GBP/USD is a currency pair that involves buying the Great British pound and selling the US dollar.</p>
                            <p className='cus-strip'>So in the example below, GBP is the base currency and USD is the quote currency. If GBP/USD is trading at 1.35361, then one pound is worth 1.35361 dollars.</p>
                            <p className='cus-strip'>If the pound rises against the dollar, then a single pound will be worth more dollars and the pair’s price will increase. If it drops, the pair’s price will decrease. So if you think that the base currency in a pair is likely to strengthen against the quote currency, you can buy the pair (going long). If you think it will weaken, you can sell the pair (going short).</p>
                            <h6 style={{fontSize: '14px'}}>To keep things ordered, most providers split pairs into the following categories:</h6>
                            <p className='cus-strip'>Major pairs. Seven currencies that make up 80% of global forex trading. Includes EUR/USD, USD/JPY, GBP/USD, USD/CHF, USD/CAD and AUD/USD</p>
                            <p className='cus-strip'>Minor pairs. Less frequently traded, these often feature major currencies against each other instead of the US dollar. Includes: EUR/GBP, EUR/CHF, GBP/JPY</p>
                            
                            <h6 style={{fontSize: '14px'}}>What moves the forex market?</h6>
                            <p className='cus-strip'>The forex market is made up of currencies from all over the world, which can make exchange rate predictions difficult as there are many factors that could contribute to price movements. However, like most financial markets, forex is primarily driven by the forces of supply and demand, and it is important to gain an understanding of the influences that drives price fluctuations here.</p>
                            <h6 style={{fontSize: '14px'}}>Central banks</h6>
                            <p className='cus-strip'>Supply is controlled by central banks, who can announce measures that will have a significant effect on their currency’s price. Quantitative easing, for instance, involves injecting more money into an economy, and can cause its currency’s price to drop.</p>
                            <h6 style={{fontSize: '14px'}}>News reports</h6>
                            <p className='cus-strip'>Commercial banks and other investors tend to want to put their capital into economies that have a strong outlook. So, if a positive piece of news hits the markets about a certain region, it will encourage investment and increase demand for that region’s currency.</p>
                            <p className='cus-strip'>Unless there is a parallel increase in supply for the currency, the disparity between supply and demand will cause its price to increase. Similarly, a piece of negative news can cause investment to decrease and lower a currency’s price. This is why currencies tend to reflect the reported economic health of the region they represent.</p>
                            <h6 style={{fontSize: '14px'}}>Market sentiment</h6>
                            <p className='cus-strip'>Market sentiment, which is often in reaction to the news, can also play a major role in driving currency prices. If traders believe that a currency is headed in a certain direction, they will trade accordingly and may convince others to follow suit, increasing or decreasing demand.</p>
                            <h6 style={{fontSize: '14px'}}>Economic data</h6>
                            <p className='cus-strip'>Economic data is integral to the price movements of currencies for two reasons – it gives an indication of how an economy is performing, and it offers insight into what its central bank might do next.</p>
                            <p className='cus-strip'>Say, for example, that inflation in the eurozone has risen above the 2% level that the European Central Bank (ECB) aims to maintain. The ECB’s main policy tool to combat rising inflation is increasing European interest rates – so traders might start buying the euro in anticipation of rates going up. With more traders wanting euros, EUR/USD could see a rise in price.</p>
                            <h6 style={{fontSize: '14px'}}>Credit ratings</h6>
                            <p className='cus-strip'>Investors will try to maximise the return they can get from a market, while minimising their risk. So alongside interest rates and economic data, they might also look at credit ratings when deciding where to invest.</p>
                            <p className='cus-strip'>A country’s credit rating is an independent assessment of its likelihood of repaying its debts. A country with a high credit rating is seen as a safer area for investment than one with a low credit rating. This often comes into particular focus when credit ratings are upgraded and downgraded. A country with an upgraded credit rating can see its currency increase in price, and vice versa.</p>
                            
                            
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='8' onClick={changeToggle} >Trading Crypto, Metals & Shares  </button></h6></li>
                    <Collapse className={cStage == 8 ? "trans show": "trans"}>
                        <div>
                          <h6 style={{fontSize: '14px'}}>What is cryptocurrency trading?</h6>
                            <p className='cus-strip'>Cryptocurrency trading is the act of speculating on cryptocurrency price movements via a CFD trading account, or buying and selling the underlying coins via an exchange.</p>
                          <h6 style={{fontSize: '14px'}}>CFD trading on cryptocurrencies</h6>
                            <p className='cus-strip'>CFDs trading are derivatives, which enable you to speculate on cryptocurrency price movements without taking ownership of the underlying coins. You can go long (‘buy’) if you think a cryptocurrency will rise in value, or short (‘sell’) if you think it will fall.</p>
                            <p className='cus-strip'>Both are leveraged products, meaning you only need to put up a small deposit – known as margin – to gain full exposure to the underlying market. Your profit or loss are still calculated according to the full size of your position, so leverage will magnify both profits and losses.</p>
                          <h6 style={{fontSize: '14px'}}>Buying and selling cryptocurrencies via an exchange</h6>
                            <p className='cus-strip'>When you buy cryptocurrencies via an exchange, you purchase the coins themselves. You’ll need to create an exchange account, put up the full value of the asset to open a position, and store the cryptocurrency tokens in your own wallet until you’re ready to sell.</p>
                            <p className='cus-strip'>Exchanges bring their own steep learning curve as you’ll need to get to grips with the technology involved and learn how to make sense of the data. Many exchanges also have limits on how much you can deposit, while accounts can be very expensive to maintain.</p>
                          <h6 style={{fontSize: '14px'}}>How do cryptocurrency markets work?</h6>
                            <p className='cus-strip'>Cryptocurrency markets are decentralised, which means they are not issued or backed by a central authority such as a government. Instead, they run across a network of computers. However, cryptocurrencies can be bought and sold via exchanges and stored in ‘wallets’.</p>
                            <p className='cus-strip'>Unlike traditional currencies, cryptocurrencies exist only as a shared digital record of ownership, stored on a blockchain. When a user wants to send cryptocurrency units to another user, they send it to that user’s digital wallet. The transaction isn’t considered final until it has been verified and added to the blockchain through a process called mining. This is also how new cryptocurrency tokens are usually created.</p>
                          <h6 style={{fontSize: '14px'}}>What is blockchain?</h6>
                            <p className='cus-strip'>A blockchain is a shared digital register of recorded data. For cryptocurrencies, this is the transaction history for every unit of the cryptocurrency, which shows how ownership has changed over time. Blockchain works by recording transactions in ‘blocks’, with new blocks added at the front of the chain.</p>
                            <p className='cus-strip'>Blockchain technology has unique security features that normal computer files do not have.</p>
                          <h6 style={{fontSize: '14px'}}>Network consensus</h6>
                            <p className='cus-strip'>A blockchain file is always stored on multiple computers across a network – rather than in a single location – and is usually readable by everyone within the network. This makes it both transparent and very difficult to alter, with no one weak point vulnerable to hacks, or human or software error.</p>
                          <h6 style={{fontSize: '14px'}}>Cryptography</h6>
                            <p className='cus-strip'>Blocks are linked together by cryptography – complex mathematics and computer science. Any attempt to alter data disrupts the cryptographic links between blocks, and can quickly be identified as fraudulent by computers in the network.</p>
                          <h6 style={{fontSize: '14px'}}>What is cryptocurrency mining?</h6>
                            <p className='cus-strip'>Cryptocurrency mining is the process by which recent cryptocurrency transactions are checked and new blocks are added to the blockchain.</p>
                          <h6 style={{fontSize: '14px'}}>Checking transactions</h6>
                            <p className='cus-strip'>Mining computers select pending transactions from a pool and check to ensure that the sender has sufficient funds to complete the transaction. This involves checking the transaction details against the transaction history stored in the blockchain. A second check confirms that the sender authorised the transfer of funds using their private key.</p>
                          <h6 style={{fontSize: '14px'}}>Creating a new block</h6>
                            <p className='cus-strip'>Mining computers compile valid transactions into a new block and attempt to generate the cryptographic link to the previous block by finding a solution to a complex algorithm. When a computer succeeds in generating the link, it adds the block to its version of the blockchain file and broadcasts the update across the network.</p>
                          <h6 style={{fontSize: '14px'}}>What moves cryptocurrency markets?</h6>
                            <p className='cus-strip'>Cryptocurrency markets move according to supply and demand. However, as they are decentralised, they tend to remain free from many of the economic and political concerns that affect traditional currencies. </p>
                          <h6 style={{fontSize: '14px'}}>How does cryptocurrency trading work?</h6>
                            <p className='cus-strip'>CFDs are leveraged products, which means you can open a position for a just a fraction of the full value of the trade. Although leveraged products can magnify your profits, they can also magnify losses if the market moves against you.</p>
                          <h6 style={{fontSize: '14px'}}>What is the spread in cryptocurrency trading?</h6>
                            <p className='cus-strip'>The spread is the difference between the buy and sell prices quoted for a cryptocurrency. Like many financial markets, when you open a position on a cryptocurrency market, you’ll be presented with two prices. If you want to open a long position, you trade at the buy price, which is slightly above the market price. If you want to open a short position, you trade at the sell price – slightly below the market price.</p>
                          <h6 style={{fontSize: '14px'}}>What is a lot in cryptocurrency trading?</h6>
                            <p className='cus-strip'>Cryptocurrencies are often traded in lots – batches of cryptocurrency tokens used to standardise the size of trades. As cryptocurrencies are very volatile, lots tend to be very small: most are just one unit of the base cryptocurrency. However, some cryptocurrencies are traded in bigger lots.</p>
                          <h6 style={{fontSize: '14px'}}>What is leverage in cryptocurrency trading?</h6>
                            <p className='cus-strip'>Leverage is the means of gaining exposure to large amounts of cryptocurrency without having to pay the full value of your trade upfront. Instead, you put down a small deposit, known as margin. When you close a leveraged position, your profit or loss is based on the full size of the trade.</p>
                          
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='9' onClick={changeToggle} >Explore Free Ebooks To Aid Your Trading Career  </button></h6></li>
                    <Collapse className={cStage == 9 ? "trans show": "trans"}>
                        <div>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>1. Killer Patterns</h6>
                            <p className='cus-strip'>This eBook shows how to use the MACD histograms to generate trading buy and sell signals.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/killerpatternsupdated_sts.pdf'>Download Killer Patterns</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>2. Introduction to Forex</h6>
                            <p className='cus-strip'>This eBook explains what the Forex market is all about an how to get started trading it.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/introductiontoforex.pdf'>Download Introduction to Forex</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>3. The Trader Business Plan</h6>
                            <p className='cus-strip'>In this eBook, Christopher Terry explains how to put together a trading plan including methodologies, goal setting, and record keeping.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/traderplan.pdf'>Download The Trader Business Plan</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>4. Charting Made Easy</h6>
                            <p className='cus-strip'>This is a great primer for new traders. This eBook covers all forms of technical analysis including support/resistance, trend lines, and chart patterns.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/chartingmadeeasy.pdf'>Download Charting Made Easy</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>5. The Truth About Fibonacci Trading</h6>
                            <p className='cus-strip'>Want to learn more about Fibonacci? Here, Bill Poulos takes you step by step into the world of Fibonacci including retracements and extensions.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/fib.pdf'>Download The Truth About Fibonacci</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>6. The eBook of Technical Market Indicators</h6>
                            <p className='cus-strip'>This eBook contains a wealth of information on market indicators including advance/decline indicators, short sale statistics, and sentiment</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/indicators.pdf'>Download The E-book of Technical Market Indicators</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>7. A Practical Guide To Swing Trading</h6>
                            <p className='cus-strip'>Larry Swing teaches you all about swing trading in this eBook. In it you will learn about candlesticks, trends, moving averages and much more.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/myswingtradingbook.pdf'>Download A Practical Guide to Swing Trading</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>8. Secrets of Millionaire Traders</h6>
                            <p className='cus-strip'>This covers 25 secrets that were collected from interviews with millionaire traders. There is some great wisdom contained in this eBook.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/million.pdf'>Download Secrets of Millionaire Traders</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>9. Day Trading Mind</h6>
                            <p className='cus-strip'>This eBook offers some great tips to stay focused on your trading. Mainly for day traders.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/daytradingmind.pdf'>Download Day Trading Mind</a>
                            <h6 className='mt-4' style={{fontSize: '14px'}}>10. Pattern Cycles</h6>
                            <p className='cus-strip'>Here Alan Farley talks about bottom reversals, breakouts, trends, and even Elliott Wave theory.</p>
                            <a className='link-cus cus-strip' href='https://www.swing-trade-stocks.com/support-files/patterncycles.pdf'>Download Pattern Cycles</a>
                            
                        </div>
                    </Collapse>
                    <li><h6 className='profile-collapse' style={{fontSize:'12px',marginLeft: '-25px'}}><button className='btn cus-strip d-inline pl-3'  id='10' onClick={changeToggle} >Videos  </button></h6></li>
                    <Collapse className={cStage == 10 ? "trans show": "trans"}>
                        <div>
                        <Carousel>
                    
                       
                       
                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy1.mp4' width='100%' height='100%' />
                                </div>
                              </div>  
                              
                              
                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy2.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy3.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy4.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy5.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy6.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy7.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy8.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy9.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy10.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy11.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy12.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy13.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy14.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              
                              
                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy15.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              
                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy16.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              
                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy17.mp4' width='100%' height='100%' />
                                </div>
                              </div>

                              
                              <div className=''>
                                <div className='player-wrapper'>
                                  <ReactPlayer pip={false} controls = {true} className='react-player' url='https://videos.eazy-markets.com/eazy18.mp4' width='100%' height='100%' />
                                </div>
                              </div>
                           
                            </Carousel>                         
                        </div>
                    </Collapse>
                </ol>
                
                
                
            </div>
          </div>
        </div>
      </section>
      </div>
        <Footer />
    </div>
  );
};

export default Education;
