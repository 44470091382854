import React, { useState, useEffect } from 'react';
import { Footer } from '../parts/Footer'; 
// import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import Alert from '../Alert';
import axios from "axios";

import {
  API_URL as api_url  
} from "../../../actions/types.js"; 
const Support = ({setAlert}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    regMember: '',
    contactReason: '',
    moreInfo: ''
    
  });

  const { fullname, email, regMember, contactReason, moreInfo } = formData;

  const onChange = e =>{
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const onSubmit = async e => {
    // setIsLoading(true);
    e.preventDefault();
    console.log('ok');
    
    // console.log(earnings);
    if (fullname === '' || email === '' || regMember === ''  || contactReason === ''  || moreInfo === '') {
      setAlert('All fields are required.', 'danger');
      // setIsLoading(false);
    } else {
    const body = JSON.stringify({ fullname, email, regMember, contactReason, moreInfo });
    // console.log(body);

    axios.post(api_url + "/api/transact/email/support", body, config).then((data)  =>{
      console.log(data.status, "Hello World");
      // setAllUsersData(data.data.data);
      if (data.status == 200) {
        setAlert('Your request was sent successfully', 'success');
      } else {
        setAlert('Something went wrong, try again later', 'danger');
      }      
     
    })
      // let res = await submitResult(deposit, investment, earnings, increase, percentage, email );
      // console.log(res);
      
      // if(res.success == true){
      //   setModal(!modal);
      //   setIsLoading(false);

      // }
    }
  };
  
  return (
    <div className='mt-5'>
      <div className='container'>
        <div>
            <div className='text-center pt-4'>
                <h6>Contact Us Form</h6>
            </div>
            <form onSubmit={e => onSubmit(e)}>
              <div className="form-group">
                  <label className='cus-strip' for="">Fullname</label>
                  <input name='fullname' value={fullname} type="text" onChange={e => onChange(e)} className="form-control cus-form-control" id="" aria-describedby="emailHelp" placeholder="Enter email" />
              </div>
              <div className="form-group">
                  <label className='cus-strip' for="">Email</label>
                  <input name='email' value={email} type="text" onChange={e => onChange(e)} className="form-control cus-form-control" id="" placeholder="Email" />
              </div>
              <div>
                  <label className='cus-strip' for="">Are you a registered member?</label>
                  <select name='regMember' value={regMember} onChange={e => onChange(e)} className="form-control cus-form-control">
                      <option>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                  </select>
              </div>
              <div>
                  <label className='cus-strip' for="">Reason for contacting us</label>
                  <select name='contactReason' value={contactReason} onChange={e => onChange(e)} className="form-control cus-form-control">
                      <option>Choose</option>
                      <option>Payment issues</option>
                      <option>Withdrawal issues</option>
                      <option>How to make payment</option>
                      <option>How to make withdrawal</option>
                      <option>Others</option>
                  </select>
              </div>
              <div className="form-group mb-1">
                  <label className='cus-strip' for="">Provide additional information</label>
                  <textarea name='moreInfo' value={moreInfo} onChange={e => onChange(e)} className="form-control cus-form-control" id="" rows="3"></textarea>
              </div>
              <Alert />
              <button type='submit' className='btn text-primary mb-3' style={{fontSize: '12px'}}>Submit</button>
            </form>
        </div>
      </div>
      <Footer />
    </div>
  )
   
};

// Support.propTypes = {};
const mapStateToProps = state => ({
  // auth: state.auth,
  
});

// export default Support;
export default connect(mapStateToProps, { setAlert })(Support);
