import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../parts/Footer';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from "axios";
import { setAlert } from '../../../actions/alert';
import Alert from '../Alert';

import {  fetchBank } from "../../../actions/transactions";
import {  fetchUser } from "../../../actions/transactions";
import {  submitResult } from "../../../actions/transactions";

import {
  API_URL as api_url  
} from "../../../actions/types.js"; 

export const Withdrawal = ({ setAlert, auth, allusers, bankstat, fetchBank, fetchUser, submitResult }) => {
  const [isLoading, setIsLoading] = useState(false);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  
  const deleteWithdrawal = async e => {
    let emailId = e.currentTarget.id;
    const getEmail = emailId.split("-");
    const email = getEmail[1];
    console.log(email);

    axios.post(
        api_url + "/api/transact/delete/withdrawal/"+email,
        null,
        config
    ).then((data)  =>{
        // console.log(data.data.errors[0].status, "Hello World");
        if (data.data.errors[0].status == true) {
            console.log('ok');
            
        }
    })    

  }

  const [withdrawData, setAllWithdrawData] = useState([]); 

  useEffect(() => {

     axios.get(
      api_url + "/api/transact/get/all/withdrawals",
      null,
      config
    ).then((data)  =>{
      console.log(data.data.data, "Hello World");
      setAllWithdrawData(data.data.data);
    })
    
  }, [])

  return (
    <div>
      <div className='container'>

        <div className='row'>
            <div className='col-md-7 mx-auto'>
              
              <div className='p-3 mt-5 table-responsive'>
                <table className="table table-bordered mt-3 history-table">
                  <thead>
                    <tr>
                        <th className='cus-strip'scope="col">Email</th>
                        <th className='cus-strip'scope="col">Wallet Address</th>
                        <th className='cus-strip'scope="col">amount</th>
                        {/* <th className='cus-strip'scope="col">Date</th> */}
                        <th className='cus-strip'scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                      { 
                            withdrawData.map((dsa, i) =>{
                            return (
                                // <span>{dsa.id}</span>
                                <tr id={'row'+dsa.id}>
                                    <td className='cus-strip'>{dsa.email}</td>
                                    <td className='cus-strip'>{dsa.walletAddress}</td>
                                    <td className='cus-strip'>{dsa.amount}</td>
                                    <td className='cus-strip'><button id={'user-'+dsa.email} className='btn btn-danger btn-sm' onClick={deleteWithdrawal}>Delete</button></td>
                                </tr>
                            )
                            })
                        }
                    </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
    auth: state.auth,
    allusers: state.allusers,
    bankstat: state.bankstat,
    // transact: state.transact
    // isAuthenticated: state.auth.isAuthenticated
  });

// export default Admin;

export default connect(mapStateToProps, { setAlert, fetchUser, fetchBank, submitResult })(Withdrawal);
