import React from 'react';
import { Link } from 'react-router-dom';
import { faEnvelope, faPhoneAlt, faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Footer = () => {
  return (
    <div>
      <footer className="section footer-classic context-dark bg-image" style={{background: '#111111',paddingBottom: '35px',paddingTop: '15px'}}>
        <div className="container">
            <div className="row row-30">
            <div className="col-md-3 col-xl-5">
                <div className="pr-xl-4">
                  <p className='d-block d-md-none text-center' style={{fontSize: '14px'}}>EazyMarkets is developed based on the leading-edge hybrid technology by eazymarkets®</p>
                  <div className='text-center text-xs-center text-sm-left text-md-left left my-lg-0 my-3'>
                    <h5 style={{fontSize: '18px'}}>Contact US 24/7</h5>
                    <ul className="nav-list">
                      <li style={{fontSize: '14px'}}><a href="mailto:team@eazy-markets.com"> <FontAwesomeIcon icon={faEnvelope} className='text-danger' /> team@eazy-markets.com</a></li>
                      <li style={{fontSize: '14px'}}><a href="/support"> <FontAwesomeIcon icon={faPhoneAlt} className='text-danger' /> Contact us page</a></li>
                      {/* <li style={{fontSize: '14px'}}><a href="#"> <FontAwesomeIcon icon={faComments} className='text-danger' /> Live Chat</a></li> */}
                      <li style={{fontSize: '14px'}}><a href="/privacy"> <FontAwesomeIcon icon={faComments} className='text-danger' /> Privacy Policy</a></li>
                    </ul>
                  </div>
                </div>
            </div>
            <div className="col-md-9">
                <div className='footer-right text-center text-xs-center text-sm-left text-md-left mt-lg-0 mt-3'>
                  <p className='d-none d-md-block'>EazyMarkets is developed based on the leading-edge hybrid technology by eazymarkets®</p>
             
                  <p className="footer-text mb-2">
                  Legal: The website is owned and operated by EazyMarkets Group of companies, which include:</p>
                  <ul className='footer-list'>
                    <li>EazyMarkets (SV) Ltd with registered address Suite 265, Griffith Corporate Centre, P.O. Box 8910, Beachmont Kingstown, St. Vincent and the Grenadines.</li>
                    <li>EazyMarkets (Europe) Ltd with registered address Spyrou Kyprianou 30, Irida 6 Tower 8th Floor, Larnaca 6957, Cyprus.</li>
                    <li className='d-none d-md-block'>EazyMarkets SA (PTY) Ltd with registered address Katherine & West Suite 18 Second floor 163 West Street Sandton, Johannesburg 2031.</li>
                    <li className='d-none d-md-block'>EazyMarkets (Seychelles) Ltd with registered address Room S206A, Second Floor, Orion Complex, Victoria, Mahe, Republic of Seychelles.</li>
                    <li className='d-none d-md-block'>EazyMarkets Fintech Services Ltd with registered address Spyrou Kyprianou 50, Irida 3 Tower 10th Floor, Larnaca 6057, Cyprus.</li>
                    <li className='d-none d-md-block'>EazyMarkets Ltd regulated by the Financial Services Commission (FSC) of the Republic of Mauritius, category 1 Global Business No. C110008214 License | Company Reg. No. 094286/GBL.</li>
                  </ul>
                  <p className="footer-text mt-2">
							      <b style={{color:'white',fontSize: '12px'}}>Risk Warning:</b>  Trading Leveraged Products such as Commodities, Forex, Cryptocurrencies and Derivatives may not be suitable for all investors as they carry a high degree of risk to your capital. Please ensure that you fully understand the risks involved, taking into account your investments objectives before trading.
                  </p>
                  <p className="footer-text mt-2">
							      <b style={{color:'white',fontSize: '12px'}}>Regional Restrictions:</b> EazyMarkets (SV) Ltd does not provide services to residents of China, Sudan, Syria, North Korea.
                  </p>
                  <p className="footer-text mt-2">
							      <b style={{color:'white',fontSize: '12px'}}>Copyright © 2021 - All Rights Reserved</b>
                  </p>
                  <hr style={{borderTop: '0.2px solid #3e3e3e'}} />
                  <div className='my-3'>
                    <img style={{height: "50px"}} className='img-fluid logo-img' src='/21.svg' />
                  </div>
                </div>
            </div>
            
            </div>
        </div>
        
    </footer>
    </div>
  );
};

export default Footer;
