import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Footer } from '../parts/Footer'; 

const Howitworks = props => {


  return (
    <div>
        <div className=''>
            
            <div className='container'>
                <div className='my-2'>
                    <div className='row'>
                        <div className='col-md-6 col-6'>
                        <div className='text-center mt-3'>
                            <div className='giveaway2'>
                            <img className='img-fluid' width='80' src='add-friend.svg' />
                            </div>
                            <h6>Create Account</h6>
                        </div>
                        </div>
                        
                        <div className='col-md-6 col-6'>
                        <div className='text-center mt-3'>
                            <div className='giveaway2'>
                            <img className='img-fluid' width='80' src='bank.svg' />
                            </div>
                            <h6>Make Deposit</h6>
                        </div>
                        </div>
                        
                        <div className='col-md-6 col-6'>
                        <div className='text-center mt-3'>
                            <div className='giveaway2'>
                            <img className='img-fluid' width='80' src='return-of-investment.svg' />
                            </div>
                            <h6>Invest</h6>
                        </div>
                        </div>
                        <div className='col-md-6 col-6'>
                        <div className='text-center mt-3'>
                            <div className='giveaway2'>
                            <img className='img-fluid' width='80' src='reward2.svg' />
                            </div>
                            <h6>Earn Profit</h6>
                        </div>
                        </div>
                    </div>
                    </div>
                <br/>
                <p className='cus-strip'>
                EazyMarkets offers various trading software and tools to facilitate individuals and institutional customers to trade Cryptocurrencies, Forex, Stocks and Derivatives online. All Retail, Affiliates and White Label clients have the opportunity to access various spreads and liquidity via state of the art automated trading platforms. EazyMarkets provides an unparalleled variety of account options that clients can select to enjoy a tailored trading experience that perfectly suits their needs. Coupled with superior trading conditions and lightning fast execution, EazyMarkets provides all the tools and services needed for clients of any level to realise their trading ambitions.
                Below is a guide on getting started with our leading-edge services.
                </p>
                <h6>Sign Up</h6>
                <p className='cus-strip'>
                Follow our easy steps to signup and get a free Eazymarkets trade account. Fill the required information on the login page, double check the information provided to eliminate errors and highlight the box to agree to the terms and conditions of trading with EazyMarkets. A verification link would be sent to your mail after submission. Follow the link provided to verify your email address. Input your email and password on the login page to access the customer area. 
                </p>
                <h6>Deposit</h6>
                <p className='cus-strip'>
                You fund your EazyMarkets trade account using Bitcoin or other available Cryptocurrency. We provide financial services to most countries of the world, hence the need for adopting a unanimous method of payment to eliminate the barriers encountered when using traditional payment systems, amidst the inconsistency in the exchange rate of different currency pairs. 
                We're partnered with several cryptocurrency payment services like simplex, moonpay, coinmama. These are to ensure a seamless transaction on the userbase.
                </p>
                <p className='cus-strip'>
                To make a deposit login to your EazyMarkets account and click on 'make deposits'.
                Select your desired cryptocurrency from the drop down list. (The default crypto is BTC)
                a wallet address would appear on the next screen with a 'copy' sign. Copy this address and proceed. You'll be redirected to a third party website where you would be asked to enter your credit/debit card details for payments paste the wallet address you copied earlier into the section labelled address. Remember to paste the corresponding address to the selected crypto (Example only paste a BTC address when buying Bitcoins and an Ether address when your purchases are for Ethereum).
                Enter the amount of BTC or any other available crypto you want to purchase (The amount entered here should be the preferred investment amount and should be quoted in USD). After payments are completed your trade account would be credited.
                </p>
                <p className='cus-strip'>
                Alternatively you can purchase Bitcoin or any other cryptocurrency from any exchange and make a transfer to the company's wallet address.
                </p>
                <h6>Choose Investments</h6>
                <p className='cus-strip mb-3'>
                EazyMarkets offers varieties of investment choices giving users the flexibility to choose from the multiple options, that which best suits their trading goals.
                Below is a list of the different Investment options.
                </p>
                <div className='mb-3'>
                    <h6 className='mt-4'>MICRO</h6>
                    <ul className='ml-3' style={{listStyle:'disc'}}>
                        <li className='cus-strip'>Execution Type: Instant Market Execution</li>
                        <li className='cus-strip'>Automated Trading: Yes</li>
                        <li className='cus-strip'>Min. Investment: $100</li>
                        <li className='cus-strip'>Expected Return in Percentage: 15 - 20%</li>
                        <li className='cus-strip'>Available for Withdrawals: After 3 Working Days</li>
                        <li className='cus-strip'>Traded Instruments: Cryptocurrencies, Forex, CFD, Indices, Commodities</li>
                        <li className='cus-strip'>Commission: 3% on profit</li>
                    </ul>
                    <h6 className='mt-4'>STANDARD</h6>
                    <ul className='ml-3' style={{listStyle:'disc'}}>
                        <li className='cus-strip'>Execution Type: Instant Market Execution</li>
                        <li className='cus-strip'>Automated Trading: Yes</li>
                        <li className='cus-strip'>Min. Investment: $500</li>
                        <li className='cus-strip'>Expected Return in Percentage: 80 - 100%</li>
                        <li className='cus-strip'>Available for Withdrawals: After 10 Working Days</li>
                        <li className='cus-strip'>Traded Instruments: Cryptocurrencies, Forex, CFD, Indices, Commodities</li>
                        <li className='cus-strip'>Commission: 3% on profit</li>
                    </ul>
                    <h6 className='mt-4'>PREMIUM</h6>
                    <ul className='ml-3' style={{listStyle:'disc'}}>
                        <li className='cus-strip'>Execution Type: Instant Market Execution</li>
                        <li className='cus-strip'>Automated Trading: Yes</li>
                        <li className='cus-strip'>Min. Investment: $5000</li>
                        <li className='cus-strip'>Expected Return in Percentage: 350 - 400%</li>
                        <li className='cus-strip'>Available for Withdrawals: After 30 Working Days</li>
                        <li className='cus-strip'>Traded Instruments: Cryptocurrencies, Forex, CFD, Indices, Commodities</li>
                        <li className='cus-strip'>Commission: 3% on profit</li>
                    </ul>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  );
};

export default Howitworks;
