import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';
import PropTypes from 'prop-types';

const Header2 = ({logout, isAuthenticated }) => {
  const [isOpen, setIsOpen] = useState(false);


  const toggle = () => setIsOpen(!isOpen);
  return (
    <div>
      <div className='header-container header-bg fixed-top border-bottom-0'>
        <div className='container-fluid'>
          <nav class='navbar navbar-expand-lg navbar-light bg-light'>
            
            <button
              class='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span class='navbar-toggler-icon'></span>
            </button>

            <div class='collapse navbar-collapse' id='navbarSupportedContent'>
              <ul class='navbar-nav'>
                <li class='nav-item active'>
                  <Link class='nav-link btn-home' style={{
                    color: 'white'
                  }} to='/home2'>
                    Home
                  </Link>
                </li>
                <li class=''>
                  <div class='nav-item dropdown d-inline-block'>
                    <a
                      class='btn btn-home dropdown-toggle'
                      href='#'
                      id='navbarDropdown'
                      role='button'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Categories
                    </a>
                    <div
                      class='dropdown-menu custom-dropdown px-3'
                      aria-labelledby='navbarDropdown'
                    >
                      
                      
                          {/* <h6 className='text-uppercase dropdown-title'>
                            Loan Categories
                          </h6> */}
                          <Link className="dropdown-item" to="/category/agriculture">
                        Agriculture
                        </Link>
                       
                        <Link className="dropdown-item" to="/category/retail">
                        Retail
                        </Link>

                        <Link className="dropdown-item" to="/category/women">
                        Women
                        </Link>
                      
                       
                        <Link className="dropdown-item" to="/category/single parents">
                        Single Parents
                        </Link>
                      
                        <Link className="dropdown-item" to="/category/all loans">
                        All Loans
                        </Link>
                        
                    </div>
                  </div>
                </li>
              </ul>
              <div className='search-animated mr-auto w-65'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='feather feather-search toggle-search'
                >
                  <circle cx='11' cy='11' r='8'></circle>
                  <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                </svg>
                <form class='form-inline my-2 my-lg-0'>
                  <input
                    class='form-control search-form-control rnd w-100 mr-sm-2'
                    type='search'
                    placeholder='Search'
                    aria-label='Search'
                  />
                </form>
              </div>
              <div className='mr-20'>
                <Link to='/login' className='btn btn-login mr-3'>E</Link>
                <div class='nav-item dropdown d-inline-block'>
                  <a
                    class='btn btn-login dropdown-toggle'
                    href='#'
                    id='navbarDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    
                  </a>
                  <div
                    class='dropdown-menu custom-dropdown px-3' style={{
                      left: '-100px'
                    }}
                    aria-labelledby='navbarDropdown'
                  >
                    
                    
                    <a href="#" onClick={logout}>
                    Logout
                    </a>
                      
                  </div>
                </div>
              </div>
            
            </div>
          </nav>
        </div>
      </div>
      <div className='clearfix'></div>
    </div>
  );
};

Header2.propTypes = {
  // login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout })(Header2);
// export default connect(mapStateToProps, { login })(Login);