import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../Alert';
import { resetpassword } from '../../../actions/auth';

export const Resetpassword = ({ setAlert, resetpassword, isAuthenticated, match }) => {
  const [formData, setFormData] = useState({
    password: '',
    password2: '',
    id: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPassChanged, setIsPassChanged] = useState(false);
  // console.log(match.params.id);

  useEffect(() => {
    setFormData({ ...formData, ['id']: match.params.id });
  }, []);
  

  const { password, password2, id } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();
    // console.log(id);

    if (password !== password2) {
      setAlert('passwords do not match', 'danger');
      setIsLoading(false);
    } else {
      // console.log(id);
      let res = await resetpassword(password, id);
        setIsLoading(false);
        if(res.success == true){
          setIsPassChanged(true);

        }
    }
  };
  return (
    <Fragment>
      {!isPassChanged ? (
      <div className="theCoverHeader for-preset">
        <div className='pt-5'>
          <div className='container'>
            <div className='row'>
              
              <div className='col-md-5 mx-auto'>
                <div className='p-3 my-2 auth-con mt-lg-0 mt-5'>
                <Alert />
                  <div className='text-center my-3'>
                    <h3>Change Password</h3>
                
                  </div>
                  <form onSubmit={e => onSubmit(e)}>
                    <div className='form-group mt-4'>
                      <input
                        type='text'
                        className='auth-input form-control'
                        placeholder='New Password'
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='auth-input form-control'
                        placeholder='Confirm Password'
                        name='password2'
                        value={password2}
                        onChange={e => onChange(e)}
                      />
                    </div>
                    <div className='text-center'>
                      <button type='submit' disabled={isLoading} className='btn btn-manager my-2'>
                        {isLoading ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : null}{' '}
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      ) : (
        <div className="text-center mt-4 cus-strip">
          <br></br>
          <br></br>
          <h1>
            <FontAwesomeIcon icon={faCheckCircle} />
            <br></br>
              Success
            </h1>
            <p>Your password has been successfully changed</p>
            <p><Link to='/login' className='text-primary'>Login</Link></p>
            {/* <p>Your registeration was successful.</p> */}
            {/* <Link to="/login" className="btn btn-success btn-sm">Continue to login</Link> */}
        </div>
      )}
    </Fragment>
  );
};

// export default Resetpassword;

Resetpassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  resetpassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, resetpassword })(Resetpassword);
