import React, { useEffect, useState, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import axios from "axios";
import Moment from 'react-moment';
import 'moment-timezone';

import Iframe from 'react-iframe'

import NumberFormat from "react-number-format";
import { faUser, faLessThan, faGreaterThan, faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header2 from "../parts/Header2";
import Slider from './Slider';
import { Footer } from '../parts/Footer'; 
const Home2 = (props) => {
  const [posts, setPosts] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [slideStage, setSlideStage] = useState(1);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    
    axios.get(
        "https://api.experts-forex.com/v1/users/rss.php",{
          "Content-Type": "application/xml; charset=utf-8"
         },
        null
      ).then((data) =>{
        console.log(data);
         setPosts(data.data.items);
       
      }).catch((err) =>{
       setPosts([{
        "title": "Buffett upbeat on U.S. and Berkshire, buys back stock even as pandemic hits results",
        "pubDate": "2021-02-27 19:45:57",
        "link": "https://www.investing.com/news/stock-market-news/buffett-upbeat-on-us-and-berkshire-buys-back-stock-even-as-pandemic-hits-results-2432272",
        "guid": "c6855f54cbc11a91c0c0b4b29e4a4e1a",
        "author": "Reuters",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {
          "link": "https://i-invdn-com.akamaized.net/trkd-images/LYNXMPEH1Q09B_L.jpg",
          "type": "image/jpeg",
          "length": 73105
        },
        "categories": []
      }, {
        "title": "Warren Buffett's $10 billion mistake: Precision Castparts",
        "pubDate": "2021-02-27 21:45:21",
        "link": "https://www.investing.com/news/stock-market-news/warren-buffetts-10-billion-mistake-precision-castparts-2432325",
        "guid": "09453a615203bc52f8d8cfb14b4f3c8d",
        "author": "Reuters",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {
          "link": "https://i-invdn-com.akamaized.net/trkd-images/LYNXNPEH1Q0CH_L.jpg",
          "type": "image/jpeg",
          "length": 74499
        },
        "categories": []
      }, {
        "title": "Biden says Saudi announcement to come Monday; White House plays down new steps",
        "pubDate": "2021-02-28 02:05:28",
        "link": "https://www.investing.com/news/world-news/biden-says-he-will-make-announcement-on-saudi-arabia-on-monday-2432324",
        "guid": "f93fa1bd2b5e28c74a8d684f2e102718",
        "author": "Reuters",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {
          "link": "https://i-invdn-com.akamaized.net/trkd-images/LYNXNPEH1Q0CE_L.jpg",
          "type": "image/jpeg",
          "length": 72077
        },
        "categories": []
      }, {
        "title": "Myanmar police crack down on protests for second day; at least two dead",
        "pubDate": "2021-02-28 07:36:43",
        "link": "https://www.investing.com/news/world-news/more-protests-against-coup-planned-in-myanmar-after-hundreds-arrested-2432380",
        "guid": "f5fa494053039dbe464ad14b1a8c53ef",
        "author": "Reuters",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {
          "link": "https://i-invdn-com.akamaized.net/trkd-images/LYNXNPEH1R00T_L.jpg",
          "type": "image/jpeg",
          "length": 115968
        },
        "categories": []
      }, {
        "title": "Cardano Falls 10% In Bearish Trade",
        "pubDate": "2021-02-28 05:31:56",
        "link": "https://www.investing.com/news/cryptocurrency-news/cardano-falls-10-in-bearish-trade-2432409",
        "guid": "3b4f54b2f2a3a81fbed2963ab9eea0db",
        "author": "Investing.com",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {},
        "categories": []
      }, {
        "title": "Ethereum Falls 10% In Rout",
        "pubDate": "2021-02-28 06:05:09",
        "link": "https://www.investing.com/news/cryptocurrency-news/ethereum-falls-10-in-rout-2432412",
        "guid": "365e76f9a544aeaf6ac77712f746828d",
        "author": "Investing.com",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {
          "link": "https://i-invdn-com.akamaized.net/news/LYNXNPED840XB_M.jpg",
          "type": "image/jpeg",
          "length": 16097
        },
        "categories": []
      }, {
        "title": "New Zealand's Auckland starts second COVID-19 lockdown this month",
        "pubDate": "2021-02-28 05:20:46",
        "link": "https://www.investing.com/news/world-news/new-zealands-auckland-starts-second-lockdown-in-month-2432352",
        "guid": "2a376f86371eedf709dad22efdb2e19a",
        "author": "Reuters",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {
          "link": "https://i-invdn-com.akamaized.net/trkd-images/LYNXNPEH1Q0EU_L.jpg",
          "type": "image/jpeg",
          "length": 52621
        },
        "categories": []
      }, {
        "title": "What\u2019s Next for XRP?",
        "pubDate": "2021-02-28 07:30:42",
        "link": "https://www.investing.com/news/cryptocurrency-news/whats-next-for-xrp-2432426",
        "guid": "72aaf9ce1e92f9c93544532152b836e0",
        "author": "DailyCoin",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {
          "link": "https://d1-invdn-com.akamaized.net/content/pic5f054f36583f9d222dd1e2b2184b48be.jpg",
          "type": "image/jpeg",
          "length": 32548
        },
        "categories": []
      }, {
        "title": "Litecoin Falls 10% In Bearish Trade",
        "pubDate": "2021-02-28 03:56:02",
        "link": "https://www.investing.com/news/cryptocurrency-news/litecoin-falls-10-in-bearish-trade-2432399",
        "guid": "d6a4914456467b4b3d54f254e30a02d4",
        "author": "Investing.com",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {},
        "categories": []
      }, {
        "title": "Berkshire Hathaway profit rises, helped by stock gains",
        "pubDate": "2021-02-27 13:15:23",
        "link": "https://www.investing.com/news/stock-market-news/berkshire-hathaway-profit-rises-helped-by-stock-gains-2432244",
        "guid": "fc64ed7a7505846988d74c7ac31874ad",
        "author": "Reuters",
        "thumbnail": "",
        "description": "",
        "content": "",
        "enclosure": {
          "link": "https://i-invdn-com.akamaized.net/trkd-images/LYNXMPEH1Q082_L.jpg",
          "type": "image/jpeg",
          "length": 49038
        },
        "categories": []
      }])
      })  
     
}, []);
  
  const changeStage = () =>{
    // if(slideStage < 4){
      let currentStage = (slideStage - 1);
      // console.log(currentStage);
      
      setSlideStage(currentStage);
      
    // }
 
  }
  
  const revertStage = () =>{
    // if(slideStage = 0){
      let prevStage = (slideStage + 1);
      // console.log(prevStage);
      
      setSlideStage(prevStage);
      if(prevStage == 0) {
        // console.log(prevStage);
      }
    // }
 
  }
  return (
   
    <div>
      {/* <Header2 /> */}
      <Slider />
      <div className='section-one'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-2 col-6'>
              <a href='/tradeoptions'>
                <div className='text-center con-one px-lg-0 px-3 pt-3'>
                  <img width='60' src='/stock.svg' className='img-fluid' />
                  <div className='mt-2 con-one-text'>
                    <h6>Wide Range of Trading Instruments</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className='col-md-2 col-6'>
              <a href='/tradingconditions'>
                <div className='text-center con-one px-lg-0 px-3 pt-3'>
                  <img width='60' src='/increase.svg' className='img-fluid' />
                  <div className='mt-2 con-one-text'>
                    <h6>Unparalleled Trading Conditions</h6>
                  </div>
                </div>
              </a>
            </div>
            
            <div className='col-md-2 col-6'>
              <a href='/automatedtrade'>
                <div className='text-center con-one px-lg-0 px-3 pt-3 mt-lg-0 mt-3'>
                  <img width='60' src='/trade.svg' className='img-fluid' />
                  <div className='mt-2 con-one-text'>
                    <h6>Instant Execution and Automated Trading</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className='col-md-2 col-6'>
              <a href='/globallicense'>
                <div className='text-center con-one px-lg-0 px-3 pt-3 mt-lg-0 mt-3'>
                  <img width='64' src='/stamp.svg' className='img-fluid' />
                  <div className='mt-2 con-one-text'>
                    <h6>Globally Licensed & Regulated</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className='col-md-2 col-6'>
              <a href='/protectfunds'>
                <div className='text-center con-one px-lg-0 px-3 pt-3 mt-lg-0 mt-3'>
                  <img width='73' src='/padlock2.svg' className='img-fluid' />
                  <div className='mt-2 con-one-text'>
                    <h6>Protection of Funds</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className='col-md-2 col-6'>
              <a href='/education'>
                <div className='text-center con-one px-lg-0 px-3 pt-3 mt-lg-0 mt-3'>
                  <img width='60' src='/books.svg' className='img-fluid' />
                  <div className='mt-2 con-one-text'>
                    <h6>Free Educational Resources</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='section-two'>
        <div className='container'>
          <div className='sec-three-head text-center s-two'>
            <h3>Why Invest With EazyMarkets</h3>
          </div>
          <div className='row'>
            <div className='col-12 col-md-3'>
              <div className='sec-two-con'>
                <div className='sec-two-con-header text-center' style={{background: `url(/sec-two-bg.png) center top no-repeat`, backgroundSize: "100% 100%", borderRadius: "6px"}}>
                  <h2><span className="halo"></span>Educational Resources</h2>
                </div>
                <div className='two-com-mid'>
                  <ul>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Step-by-step tutorials and articles</li>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Ebooks and Videos to get you started with your trading career</li>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Online Webinars and local Seminars</li>
                  </ul>
                </div>
                <div className='text-center'>
                  <a href='/education' className='btn btn-acc btn-acc-color px-3 sp-hov'>Learn more</a>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-3'>
              <div className='sec-two-con'>
                <div className='sec-two-con-header text-center' style={{background: `url(/sec-two-bg.png) center top no-repeat`, backgroundSize: "100% 100%", borderRadius: "6px"}}>
                  <h2><span className="halo"></span>Multiple Choices</h2>
                </div>
                <div className='two-com-mid'>
                  <ul>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Various level of funding from micro to unlimited</li>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Complete flexibility to trade at the level you want</li>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Get started with as little as $100</li>
                  </ul>
                </div>
                <div className='text-center'>
                  <a href='/howitworks' className='btn btn-acc btn-acc-color px-3 sp-hov'>Learn more</a>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-3'>
              <div className='sec-two-con'>
                <div className='sec-two-con-header text-center' style={{background: `url(/sec-two-bg.png) center top no-repeat`, backgroundSize: "100% 100%", borderRadius: "6px"}}>
                  <h2><span className="halo"></span>Automated Trading</h2>
                </div>
                <div className='two-com-mid'>
                  <ul>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Relax and let us handle the trades</li>
                    <li style={{listStyleImage: `url(/check.svg)`}}>No need to be experienced trader to begin with EazyMarkets</li>
                    <li style={{listStyleImage: `url(/check.svg)`}}>95% Accuracy (proven)</li>
                    <li style={{listStyleImage: `url(/check.svg)`}}>No profit sharing</li>
                  </ul>
                </div>
                <div className='text-center'>
                  <a href='/automatedtrade' className='btn btn-acc btn-acc-color px-3 sp-hov'>Learn more</a>
                </div>
              </div>
            </div>
            <div className=' col-12 col-md-3'>
              <div className='sec-two-con'>
                <div className='sec-two-con-header text-center' style={{background: `url(/sec-two-bg.png) center top no-repeat`, backgroundSize: "100% 100%", borderRadius: "6px"}}>
                  <h2><span className="halo"></span>Referral Scheme</h2>
                </div>
                <div className='two-com-mid'>
                  <ul>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Get an instant $50 for every member you introduced</li>
                    <li style={{listStyleImage: `url(/check.svg)`}}>Earn commissions weekly after a minimum of 10 referrals</li>
                  </ul>
                </div>
                <div className='text-center'>
                  <a href='/register' className='btn btn-acc btn-acc-color px-3 sp-hov'>Register</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='section-three'>
        <div className='container d-none d-md-block'>
          <div className='sec-three-head text-center'>
            <h3>Getting Started With EazyMarkets</h3>
            <a to='' className='text-dark'><p>Quick Steps</p></a>
          </div>
          <div className='pt-4 pb-2'>
            <div className='row'>
              <div className='col-md-3 px-2 px-lg-0'>
                <div className='mb-4 text-center'>
                  <h6 className='mb-3 pb-4'>Create an Account</h6>
                  <div className='step-parent'>
                    <div className='step-line'></div>
                    <div className='step-circle'></div>
                  </div>
                  <h6 className='pt-4'>2 MIN</h6>
                </div>
              </div>
              <div className='col-md-3 px-2 px-lg-0'>
                <div className='mb-4 text-center'>
                  <h6 className='mb-3 pb-4'>Make Deposit</h6>
                  <div className='step-parent'>
                    <div className='step-line'></div>
                    <div className='step-circle'></div>
                  </div>
                  <h6 className='pt-4'>5 MIN</h6>
                </div>
              </div>
              <div className='col-md-3 px-2 px-lg-0'>
                <div className='mb-4 text-center'>
                  <h6 className='mb-3 pb-4'>Choose Investment</h6>
                  <div className='step-parent'>
                    <div className='step-line'></div>
                    <div className='step-circle'></div>
                  </div>
                  <h6 className='pt-4'>1 MIN</h6>
                </div>
              </div>
              <div className='col-md-3 px-2 px-lg-0'>
                <div className='mb-4 text-center'>
                  <h6 className='mb-3 pb-4'>Place an Order</h6>
                  <div className='step-parent'>
                    <div className='step-line'></div>
                    <div className='step-circle'></div>
                  </div>
                  <h6 className='pt-4'>1 MIN</h6>
                </div>
              </div>
              
            </div>
          </div>
          
          <div className='sec-three-head text-center'>
            {/* <h3>Getting Started With EazyMarkets</h3> */}
            <h4>Make investments in any digital asset or commodity with a minimum of $100 and earn</h4>
            <p><em>...You invest, we trade.</em></p>
          </div>
        </div>
        <div className='py-4 d-block d-md-none' style={{background: '#252c48',height: '395px'}}>
          <div className='container'>
            <div className='py-3 position-relative'>
              <div className='position-absolute' style={{right: '0px'}}>
                { slideStage !== 1 ? (
                <button className='btn text-light' onClick={ changeStage}><FontAwesomeIcon style={{fontSize: '25px'}} icon={faArrowCircleLeft} /></button>
                ) : null}
                {/* <button className='btn text-light' onClick={ changeStage}><FontAwesomeIcon icon={faLessThan} /></button> */}
                
                { slideStage !== 4 ? (
                <button className='btn text-light' onClick={ revertStage}><FontAwesomeIcon style={{fontSize: '25px'}} icon={faArrowCircleRight} /></button>

                ) : null}
              </div>
              
              { slideStage == 1 ? (
                <div>
                  <h4 className='text-primary'>Register</h4>
                  <small className='text-white'>Open your EazyMarkets trading account in a minute</small>
                </div>
                ) : slideStage == 2 ? (
                  <div>
                    <h4 className='text-primary'>Make Deposit</h4>
                    <small className='text-white'>Fund your trading account with multiple <br /> payment options</small>
                  </div>
                ) : slideStage == 3 ? (
                  <div>
                    <h4 className='text-primary'>Choose Investment</h4>
                    <small className='text-white'>Access varieties of investment option</small>
                  </div>
                ) : slideStage == 4 ? (
                  <div>
                    <h4 className='text-primary'>Earn with our experts</h4>
                    <small className='text-white'>Instant order execution</small>
                  </div>
                  ) : null}
            </div>
            {
              slideStage == 1  ? (
                <Fragment>
                    {/* <div className='text-center'>
                      <h3 className='text-white'>Header</h3>
                      <img width='170' className='img-fluid my-5' src='phone1.png' />
                    </div> */}
                    <div className='text-center'>
                      <img width='170' className='img-fluid my-5' src='phone2.png' />
                    </div>
                </Fragment>
              ) : null
            }

            {
              slideStage == 2  ? (
                <Fragment>
                    <div className='text-center'>
                      <img width='170' className='img-fluid my-5' src='phone3.png' />
                    </div>
                </Fragment>
              ) : null
            }

            {
              slideStage == 3  ? (
                <Fragment>
                    <div className='text-center'>
                      <img width='170' className='img-fluid my-5' src='phone4.png' />
                    </div>
                </Fragment>
              ) : null
            }

            {
              slideStage == 4  ? (
                <Fragment>
                    <div className='text-center'>
                      <img width='170' className='img-fluid my-5' src='phone5.png' />
                    </div>
                </Fragment>
              ) : null
            }
           
          </div>
        </div>
      </div>
      <div className='section-four' style={{background: `url(/sec-four.jpg)`,backgroundAttachment: "fixed",position: "relative"}}>
        <div className='container pb-4'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='sec-four-head text-center'>
              <h3 className="text-white">In-Depth Daily Market Analysis</h3>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-5' id="tradingVD">
            <Iframe url="https://chart.eazy-markets.com/"
        height="500"
        width="100%"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
           
             
            
            </div>
            <div className='col-md-7'>
              <div className='news-con'>
                <div className='sec-four-subhead'>
                  <h4 class="text-white">Latest News</h4>
                </div>
                <div className='news-frame'>
                {posts.map((post, i) =>{

                  return(
                    <a href={post.link} target="_blank">
                    <div className='news-item'>
                    <div className='news-item-img'>
                      <img className='img-fluid' src={post.enclosure.link} />
                    </div>
                    <div className='news-item-content'>
                      <div className="news-date">{post.pubDate}</div>
                      <div className="news-title"><h5>{post.title}</h5></div>
                      {/* <div className="news-description">It only felt like yesterday that the United States and China reached&nbsp;...</div> */}
                    </div>
                  </div>
                  </a>
                  )

                })}
                 
             
                  <div className='see-more mt-3'>
                    <a href='#' style={{color: '#f16321'}}>Read More Articles</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='section-six whole-w'>
        <div className="large-font text-center text-black">Trusted By</div>
        <div className='section-six-bg'>
          <div className='container'>
            <div className='row'>
              {/* <div className='col-md-3 col-6'>
                <div className='text-center py-4 pay-con'>
                  <img className='img-fluid' width='105' src='/pay-1.png' />
                </div>
              </div> */}
              <div className='col-md-3 col-6'>
                <div className='text-center py-4 pay-con'>
                  <img className='img-fluid' width='105' src='/pay-2.png' />
                </div>
              </div>
              <div className='col-md-3 col-6'>
                <div className='text-center py-4 pay-con'>
                  <img className='img-fluid' width='105' src='/pay-3.png' />
                </div>
              </div>
              <div className='col-md-3 col-6'>
                <div className='text-center py-4 pay-con'>
                  <img className='img-fluid' width='105' src='/pay-4.png' />
                </div>
              </div>
              <div className='col-md-3 col-6'>
                <div className='text-center py-4 pay-con'>
                  <img className='img-fluid' width='105' src='/pay-5.png' />
                </div>
              </div>
              <div className='col-md-3 col-6'>
                <div className='text-center py-4 pay-con'>
                  <img className='img-fluid' width='105' src='/pay-6.png' />
                </div>
              </div>
              <div className='col-md-3 col-6'>
                <div className='text-center py-4 pay-con'>
                  <img className='img-fluid' width='105' src='/pay-7.png' />
                </div>
              </div>
              {/* <div className='col-md-3 col-6'>
                <div className='text-center py-4 pay-con'>
                  <img className='img-fluid' width='105' src='/pay-8.png' />
                </div>
              </div> */}
              
            </div>
          </div>
        </div>
      </div>
     
  
    

      <Footer />
    </div>
    
  );
};


export default Home2;
