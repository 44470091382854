import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CustomInput, Form, FormGroup, Label } from 'reactstrap';
import { setAlert } from '../../../actions/alert';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../Alert';
import { register } from '../../../actions/auth';
let countries = require('../../../Countries.json');
export const Register = ({  setAlert, register, isAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRegSuccess, setIsRegSuccess] = useState(false);
  const [IAgree, setIAgree] = useState(0);


  const agree22 = e => {
    // console.log(e.currentTarget.id);
    
    setIAgree(1);

    console.log(IAgree);
    
  };
  


  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    country: '',
    countrycode: '',
    phonenumber: '',
    useremail: '',
    password: '',
    password2: '',
    reff: ''
    
  });
  const [countryLists, setCountryLists] = useState(countries);

const { firstname, lastname, country, countrycode, phonenumber, useremail, password, password2, reff } = formData;

// if (typeof localStorage.referrer !== 'undefined') {
//   // console.log('okkkk');
//   const reff = localStorage.getItem('referrer');
//   // setFormData({ ...getData, [reff]: reff });
  
  
// }

// log


  const onChange = e =>{
   
    if(e.target.name == "country"){
      setFormData({ ...formData, [e.target.name]: e.target.value,   ['countrycode']: countries[e.target.value].dial_code});
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }


  const onSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();
    // console.log(username);
 

    if (password !== password2) {
      setAlert('passwords do not match', 'danger');
      setIsLoading(false);
    }else if(password.length < 7){
      setAlert('passwords must be seven(7) characters long or more!', 'danger');
      setIsLoading(false);
    } else {
      if (typeof localStorage.referrer !== 'undefined') {
      let res = await register(firstname, lastname, country, countrycode, phonenumber, useremail, password, localStorage.getItem('referrer'));
        setIsLoading(false);
        if(res.success == true){
          setIsRegSuccess(true);

        }
          
      } else {
        let res = await register(firstname, lastname, country, countrycode, phonenumber, useremail, password, '');
        setIsLoading(false);
        if(res.success == true){
          setIsRegSuccess(true);

        }
      }

      
    }
  };


  return (
    <Fragment>
      {!isRegSuccess ? (
        <div className="theCoverHeader " style={{paddingTop: "50px"}}>
        <div className='container'>
          <div className=''>
            <div className='row'>
              
              <div className='col-md-5 mx-auto' >
                <div className='p-3 my-2 auth-con mt-lg-0 mt-5'>
                  
                  <div className='text-center my-3'>
                    <h3>Sign Up</h3>
                    
                  </div>
                  <form onSubmit={e => onSubmit(e)}>
                    <div className='form-group mt-4'>
                      <input
                        type='text'
                        className='auth-input form-control'
                        placeholder='Enter First Name'
                        name='firstname'
                        value={firstname}
                        onChange={e => onChange(e)}
                      />
                    </div>
                    <div className='form-group mt-4'>
                      <input
                        type='text'
                        className='auth-input form-control'
                        placeholder='Enter Last Name'
                        name='lastname'
                        value={lastname}
                        onChange={e => onChange(e)}
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='auth-input form-control'
                        placeholder='Enter Email Address'
                        name='useremail'
                        value={useremail}
                        onChange={e => onChange(e)}
                      />
                    </div>
                    <div className='form-group mt-4'>

                      <select className='auth-input form-control'
                      name='country'
                      value={country}
                      onChange={e => onChange(e)}
                      
                      >
                      
                        {
                          countries.map((country, i) => {
                            return (
                              <option value={i}>{country.name}</option>
                            )
                          })
                        }
                      </select>
                    

                    </div>
                    <div className='row'>
                      <div className='col-md-3 col-3'>
                        <div className='form-group mt-4'>
                          <input
                            type='text'
                            className='auth-input form-control'
                            placeholder='+1'
                            name='countrycode'
                            value={countrycode}
                           
                          />
                        </div>
                      </div>
                      <div className='col-md-9 col-9'>
                        <div className='form-group mt-4'>
                          <input
                            type='text'
                            className='auth-input form-control'
                            placeholder='Enter Phone Number'
                            name='phonenumber'
                            value={phonenumber}
                            onChange={e => onChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    
                    
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group mb-1'>
                          <input
                            type='password'
                            className='auth-input form-control'
                            placeholder='Enter Password'
                            name='password'
                            value={password}
                            onChange={e => onChange(e)}
                          />
                          
                          {/* { slideStage !== 1 ? (
                            
                            ) : null} */}
                        </div>
                        {/* <small className='d-block d-md-none' style={{fontSize: '12px'}}>(7-15 letters, should contain Alphabets and numbers)</small> */}
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group mb-1'>
                          <input
                            type='password'
                            className='auth-input form-control'
                            placeholder='Confirm Password'
                            name='password2'
                            value={password2}
                            onChange={e => onChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                          {/* <small className='d-none d-md-block' style={{fontSize: '12px'}}>(7-15 letters, should contain Alphabets and numbers)</small> */}

                    <FormGroup className='mt-3'>
                      <div class="custom-checkbox custom-control">
                        <input type="checkbox" id="i-agree" class="custom-control-input" onClick={agree22} />
                        <label class="custom-control-label cus-strip" for="i-agree">By signing up I have read and agreed to the <a className='text-primary' href='/privacy'>terms and conditions</a> of EazyMarkets.</label>
                      </div>
                    </FormGroup>
                      
                    <Alert />
                    
                    <div className='text-center'>
                      <button type='submit' disabled={isLoading} class="btn btn-manager my-2" disabled={IAgree === 1 ? false : true }>
                        {isLoading ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : null}{' '}
                        Sign Up
                      </button>
                    </div>
                  </form>
                  <div className='mt-4'>
                    <span>Already have account?</span>
                    <span className='float-right'>
                      <a className='text-primary' href='/login'>Sign In</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
      ) : (
        <div className="text-center mt-4 cus-strip">
          <br></br>
          <br></br>
          <h1>
            <FontAwesomeIcon className='text-success' icon={faCheckCircle} />
            <br></br>
              Success
            </h1>
            <p className='mb-1'>An activation link has been sent to:</p>
            <span><b>{useremail}</b></span>
            <p>Open the email and click on the activation link to get started!</p>
            <p>You may need to check your spam folder if this messege does not appear in your inbox</p>
            <p>didn't receive an email?</p>
            <p><Link className='text-primary'>Resend email</Link> or <a className='text-primary' href='/support'>Contact Support</a>.</p>
            {/* <p>Your registeration was successful.</p> */}
            {/* <Link to="/login" className="btn btn-success btn-sm">Continue to login</Link> */}
        </div>
      )}
      
    </Fragment>
  );
};

// export default Register;

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
// export default connect({ setAlert })(Register);
export default connect(mapStateToProps, { setAlert, register })(Register);
