import React, { Fragment, useEffect } from 'react';
import Select from 'react-select';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

////////////////---UI---///////////////




// Redux
import { Provider } from 'react-redux';
import store from './store';

import './css/App.css';

import { Header } from './components/layout/parts/Header';
import { Footer } from './components/layout/parts/Footer';

import Dashboard from './components/layout/dashboard/Dashboard2';
import Admin from './components/layout/dashboard/Admin';
import Users from './components/layout/dashboard/Users';
import Withdrawal from './components/layout/dashboard/Withdrawal';
import Depositlink from './components/layout/dashboard/Depositlink';
import Support from './components/layout/home/Support';
import PrivateRoute from './components/routing/PrivateRoute';
import Register from './components/layout/user/Register';
import Referal from './components/layout/user/Referal';
import Login from './components/layout/user/Login';
import Forgetpassword from './components/layout/user/Forgetpassword';
import Resetpassword from './components/layout/user/Resetpassword';
import Home2 from './components/layout/home/Home2';
import About from './components/layout/home/About';
import Privacy from './components/layout/home/Privacy';
import Tradeoptions from './components/layout/home/Tradeoptions';
import Activate from './components/layout/user/Activate';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import Tradingconditions from './components/layout/home/Tradingconditions';
import Automatedtrade from './components/layout/home/Automatedtrade';
import Howitworks from './components/layout/home/Howitworks';
import Invitefriend from './components/layout/dashboard/Invitefriend';
import Globallicense from './components/layout/home/Globallicense';
import Protectfunds from './components/layout/home/Protectfunds';
import Education from './components/layout/home/Education';
import Deposit from './components/layout/dashboard/Deposit';
import  Uniquereferers  from './components/layout/dashboard/Uniquereferers';
// import Detail from './components/layout/home/Detail';

if (localStorage.token) {
  setAuthToken(localStorage.token);   
}

const App = () => {

  useEffect(() => {
    store.dispatch(loadUser())
  }, [])
 
  return (
   
      <Provider store={store}>
        <Router>
          <Fragment>
            <Header />
            {/* <Header2  /> */}
            {/* <PrivateRoute exact path='/' component={Home2} /> */}
            <section className='dash-body'>
            <Route exact path='/' component={Home2} />
            {/* <section className='mt-6'> */}
              <Switch>
                <PrivateRoute exact path='/dashboard' component={Dashboard} />
                <PrivateRoute exact path='/deposit' component={Deposit} />
                <Route exact path='/support' component={Support} />
                <Route exact path='/privacy' component={Privacy} />
                <Route exact path='/about' component={About} />
                <Route exact path='/register' component={Register} />
                <Route exact path='/referal/:ref' component={Referal} />
                <Route exact path='/activate/:id' component={Activate} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/scapio' component={Admin} />
                <Route exact path='/scapio/users' component={Users} />
                <Route exact path='/scapio/users/referers/:id' component={Uniquereferers} />
                <Route exact path='/scapio/withdrawal' component={Withdrawal} />
                <Route exact path='/scapio/address' component={Depositlink} />
                <Route exact path='/tradeoptions' component={Tradeoptions} />
                <Route exact path='/education' component={Education} />
                <Route exact path='/howitworks' component={Howitworks} />
                <PrivateRoute exact path='/invitefriend' component={Invitefriend} />
                <Route exact path='/tradingconditions' component={Tradingconditions} />
                <Route exact path='/globallicense' component={Globallicense} />
                <Route exact path='/protectfunds' component={Protectfunds} />
                <Route exact path='/automatedtrade' component={Automatedtrade} />
                <Route exact path='/password/reset/:id' component={Resetpassword} />
                <Route
                  exact
                  path='/password/forget'
                  component={Forgetpassword}
                />
              </Switch>
            </section>
            {/* <Footer /> */}
          </Fragment>
        </Router>
      </Provider>
    
  );
};


export default App;
