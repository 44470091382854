import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
// import loans from './loans';
// import companies from './companies';
// import statistics from './statistics';
import userstat from './userstat';
import transact from './transact';
import bankstat from './bankstat';
import allusers from './allusers';
import editadmin from './editadmin';
import myreferrals from './myreferrals';
import depositlinks from './depositlinks';
// import submitdeplink from './depositlinks';
// import votes from './votes';

export default combineReducers({
  alert,
  auth,
  userstat,
  transact,
  bankstat,
  allusers,
  editadmin,
  myreferrals,
  depositlinks
});
