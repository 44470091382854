import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../parts/Footer'; 

const Globallicense = props => {
 

  return (
    <div>
        <div className=' mt-4'>
            <div className='container'>
                <img className='img-fluid' width='90' src='/license.jpeg' />
                <br/>
                <p className='cus-strip'>
                EazyMarkets Limited is regulated by the Financial Conduct Authority (FCA) of UK, with an Investment Dealer License number C113467295.

                </p>
                <p className='cus-strip'>
                 EazyMarkets Limited has been granted an Investment Dealer (Full Service Dealer, excluding Underwriting) Licence pursuant to Section 29 of the Securities Act 2005, Rule 4 of the Securities (Licencing) Rules 2007 and the Financial Services (Consolidated Licencing and Fees) Rules 2008. The foremost aim of the FCA is to position UK as a jurisdiction of integrity with a fair balance of regulation and business development.

                </p>
                <p className='cus-strip'>
                EazyMarkets Limited is licensed to offer trading with Cryptocurrencies, Forex and CFD products, among other instruments and securities.
 
                </p>
            </div>
        </div>
        <Footer />
    </div>
  );
};

export default Globallicense;
