export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const ACTIVATION = 'ACTIVATION';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS';
export const FETCH_STATISTICS_FAILED = 'FETCH_STATISTICS_FAILED';
export const USER_LOADED = 'USER_LOADED';
export const USER_STATS = 'USER_STATS';
export const USER_STATS_FAIL = 'USER_STATS_FAIL';
export const USER_REFERRAL = 'USER_REFERRAL';
export const USER_REFERRAL_FAIL = 'USER_REFERRAL_FAIL';
export const USER_INVEST = 'USER_INVEST';
export const USER_INVEST_FAIL = 'USER_INVEST_FAIL';
export const BANK_STATS = 'BANK_STATS';
export const BANK_STATS_FAIL = 'BANK_STATS_FAIL';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const ADMIN_EDIT = 'ADMIN_EDIT';
export const ADMIN_EDIT_FAIL = 'ADMIN_EDIT_FAIL';
export const ADD_WALLET = 'ADD_WALLET';
export const ADD_WALLET_FAIL = 'ADD_WALLET_FAIL';
export const MAKE_DEPOSIT = 'MAKE_DEPOSIT';
export const MAKE_DEPOSIT_FAIL = 'MAKE_DEPOSIT_FAIL';
export const MAKE_WITHDRAWAL = 'MAKE_WITHDRAWAL';
export const MAKE_WITHDRAWAL_FAIL = 'MAKE_WITHDRAWAL_FAIL';
export const FETCH_LINKS = 'FETCH_LINKS';
export const FETCH_LINKS_FAIL = 'FETCH_LINKS_FAIL';
export const CHANGE_DEP_LINK = 'CHANGE_DEP_LINK';
export const CHANGE_DEP_LINK_FAIL = 'CHANGE_DEP_LINK_FAIL';
export const CHANGE_PROCEED_LINK = 'CHANGE_PROCEED_LINK';
export const CHANGE_PROCEED_LINK_FAIL = 'CHANGE_PROCEED_LINK_FAIL';

//export const API_URL = "http://68.183.45.252:7802";
//export const API_URL = "http://localhost:7819";
export const API_URL = "https://tea.eazy-markets.com";

