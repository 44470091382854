import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../Alert';
import { forgetpassword } from '../../../actions/auth';

export const Forgetpassword = ({ setAlert, forgetpassword, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const { email } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();
    // console.log(email);

    let res = await forgetpassword(email);
    console.log(res, "the");
    if(res.success == true){
      setIsSent(true);
      setIsLoading(false);

      console.log('email sent');
      setAlert('email sent', 'success');

    }
    //  else {
    //   console.log('not sent');
    //   setAlert('email not sent', 'danger');
    //   setIsLoading(false);
    // }
  };
  return (
    <Fragment>
      {!isSent ? (
        <div className="theCoverHeader for-preset">
        <div className='pt-5'>
          <div className='container'>
            <div className='row'>
              
              <div className='col-md-5 mx-auto'>
                <div className='p-3 my-2 auth-con mt-lg-0 mt-5'>
                  <div className='text-center my-3'>
                    <h3>Reset Password </h3>
                  
                  </div>
                  <form onSubmit={e => onSubmit(e)}>
                    <div className='form-group mt-4'>
                      <input
                        type='text'
                        className='auth-input form-control'
                        placeholder='Enter Email Address'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                      />
                    </div>
                    <Alert />
                    <div className='text-center'>
                      <button type='submit' className='btn btn-manager my-2'>
                        {isLoading ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : null}{' '}
                        Submit
                      </button>
                    </div>
                  </form>
                  <div className='mt-4'>
                  <span>Don't have an account?</span>
                  <span className='float-right'>
                    <Link to='/register'>Sign Up</Link>
                  </span>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ) : (
        <div className="text-center mt-4 cus-strip">
          <br></br>
          <br></br>
          <h1>
            <FontAwesomeIcon icon={faCheckCircle} />
            <br></br>
              Success
            </h1>
            <p>A link has been sent to your email to reset your password:</p>
            {/* <span><b></b></span> */}
            <p>Open the email and click on the activation link to get started!</p>
            <p>You may need to check your spam folder if this messege does not appear in your inbox</p>
            <p>didn't receive an email?</p>
            <p><Link className='text-primary'>Resend email</Link> or <Link className='text-primary'>Contact Support</Link>.</p>
            {/* <p>Your registeration was successful.</p> */}
            {/* <Link to="/login" className="btn btn-success btn-sm">Continue to login</Link> */}
        </div>
      )}
    </Fragment>
  );
};

// export default Forgetpassword;

Forgetpassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  forgetpassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {  setAlert, forgetpassword })(Forgetpassword);
