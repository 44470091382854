import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import PropTypes from 'prop-types';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeDeposit } from '../../../actions/transactions';
import axios from "axios";
import { setAlert } from '../../../actions/alert';
import Alert from '../Alert';
import {
  API_URL as api_url  
} from "../../../actions/types.js"; 

const Deposit = ({auth, makeDeposit, setAlert }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [depLinkData, setDepLinkData] = useState([]);
  const [proceedLink, setProceedLink] = useState({
    pLink: ""
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [userInfo, setUserInfo] = useState({
    userid: "",
    firstname: "",
    lastname: "",
    useremail: "",
    walletAddress: ""
    // balance: ""
  });

  useEffect(() => {
    if(auth.user !== null){
      // setUserId(auth.user.id);
      const firstname = auth.user.firstname;
      const lastname = auth.user.lastname;
      const userid = auth.user.id;
      const useremail = auth.user.email;
      const walletAddress = auth.user.walletAddress;
      // console.log(userid); 
      setUserInfo({
        userid: userid,
        firstname: firstname,
        lastname: lastname,
        useremail: useremail,
        walletAddress: walletAddress
      });
      
      
    }
  }, [auth]);
  const { firstname, lastname, userid, useremail, walletAddress } = userInfo;

  useEffect(() => {
    // fetchDepositLinks();

    axios.get(
      api_url + "/api/transact/deposit/links",
      null,
      config
    ).then((data)  =>{
      // console.log(data.data.data, "Hello World");
      setDepLinkData(data.data.data);
    })


    axios.get(
      api_url + "/api/transact/proceed/link",
      null,
      config
    ).then((data)  =>{
    console.log(data);
       setProceedLink({
         pLink: data.data.data[0].proceedlink || null,
       });
      
    })
  }, []);
  const { pLink } = proceedLink;

  const [formData, setFormData] = useState({
    coinselect: ''
  });

  const [formData1, setFormData1] = useState({
    depAmount: ''
  });
 

const { coinselect } = formData;
const { depAmount } = formData1;

// const coinsymbol = '';

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  
  const onChange1 = e =>
    setFormData1({ ...formData1, [e.target.name]: e.target.value });


  const [copySuccess, setCopySuccess] = useState('Copy');
    

    // your function to copy here

    const copyToClipBoard = async e => {
      e.preventDefault();
      console.log('ok');
      
        try {
        await navigator.clipboard.writeText(coinselect);
        setCopySuccess('Copied!');
        } catch (err) {
        setCopySuccess('Failed to copy!');
        }
    };


    const onSubmit = async e => {
      e.preventDefault();
      setIsLoading(true);
      
      

      if (coinselect === '' || depAmount === '') {
        setIsLoading(false);
        setAlert('Please supply all fields', 'danger');
      } else {
        window.location.href='http://'+pLink;
        let res = await makeDeposit( firstname, lastname, useremail, coinselect, depAmount );
        // console.log(res);
        
        if(res.success == true){
          setAlert('Deposit request successful', 'success');
          // window.location.href='http://'+pLink;

        }
      }

    }
    
  
  return (
    <div style={{marginTop: '150px'}}>
      <div className='container'>
      
        <div className='row'>
          <div className='col-md-7 mx-auto'>
            <form onSubmit={e => onSubmit(e)}>
              <div className='row'>
                <div className='col-6 mx-auto'>
                  <div className='form-group mb-5'>
                    <input type="number" name='depAmount' value={depAmount} onChange={e => onChange1(e)} className='form-control cus-form-control' placeholder='Enter amount' />
                  </div>
                </div>
              </div>
              <p className='cus-strip my-2' style={{fontSize: '12px'}}>
                You fund your EazyMarkets trade account using Bitcoin and other listed altcoins. We're partnered with several cryptocurrency payment services like simplex, moonpay, coinmama. These are to ensure a seamless transaction on the userbase.
              </p>
              <div className="form-group">
                  <label for="" className='cus-strip'>select</label>
                  <select name='coinselect' value={coinselect} 
                        onChange={e => onChange(e)} className="form-control cus-form-control custom-select" id="">
                      <option value=''>Choose coin</option>
                        {depLinkData.map((option) => (
                            <option value={option.address}>{option.coin}</option>
                        ))}
                  </select>
              </div>
              <div>
                <div className="input-group mb-2 mr-sm-2">
                  
                  <input type="text" className="form-control cus-form-control" id="" value={coinselect} placeholder="" disabled/>
                  <div className="input-group-append">
                    <div className="input-group-text py-1">
                      <button onClick={copyToClipBoard} className='btn p-0'>{copySuccess}</button>
                    </div>
                  </div>
                </div>
                <p className='cus-strip my-3' style={{fontSize: '12px'}}>
                  You are now leaving this website to a secure payment platform.
                </p>
                <p className='cus-strip my-3' style={{fontSize: '12px'}}>
                Paste the wallet address you copied on the next page as the receiving address and retype your investment amount in USD
                </p>
                <p className='cus-strip my-3' style={{fontSize: '12px'}}>
                  Click the proceed button to continue
                </p>
                <Alert />
                <div className='text-center'>
                  <button type='submit' disabled={isLoading} className='btn btn-primary'>
                    {isLoading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : null}{' '}
                    Proceed
                  </button>
                </div>


                <p className='cus-strip mt-5' style={{fontSize: '12px'}}>Alternatively you can purchase bitcoin or other altcoin from any suitable exchange and make a transfer to the wallet address above. Send a request via mail to team@eazy-markets.com with a screenshot of your payment details to facilitate account crediting</p>
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>
  )
   
};

// Deposit.propTypes = {};

// export default Deposit;

Deposit.propTypes = {
  setAlert: PropTypes.func.isRequired,
  makeDeposit: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  // isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, makeDeposit })(Deposit);
