import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { getReferals } from '../../../actions/auth';
import axios from "axios";
import {
  API_URL as api_url  
} from "../../../actions/types.js"; 

const Invitefriend = ({auth, getReferals, myreferrals }) => {
    const [userInfo, setUserInfo] = useState({
        userid: "",
        firstname: "",
        lastname: "",
        useremail: "",
        walletAddress: ""
        // balance: ""
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
    useEffect(() => {
        if(auth.user !== null){
          // setUserId(auth.user.id);
          const firstname = auth.user.firstname;
          const lastname = auth.user.lastname;
          const userid = auth.user.id;
          const useremail = auth.user.email;
          const walletAddress = auth.user.walletAddress;
          // console.log(userid); 
          setUserInfo({
            userid: userid,
            firstname: firstname,
            lastname: lastname,
            useremail: useremail,
            walletAddress: walletAddress
          });
         
         
          console.log(useremail);
          axios.get(
            api_url + "/api/users/get/referrals/" + useremail,
            null,
            config
          ).then((data)  =>{
            console.log(data.data.data, "Hello World");
           setRefInfo(data.data.data);
          })
        }
        
      }, [auth]);

      
      const { userid, useremail } = userInfo;
      
      const [refInfo, setRefInfo] = useState([]);
      
      const [copySuccess, setCopySuccess] = useState('Copy');
    

    // your function to copy here

    const copyToClipBoard = async copyMe => {
        try {
        await navigator.clipboard.writeText(copyMe);
        setCopySuccess('Copied!');
        } catch (err) {
        setCopySuccess('Failed to copy!');
        }
    };
  
  return (
    <div>
      <div className='container mt-4' style={{paddingTop: "100px"}}>
        <div>
            
            <h6 className='cus-strip'>Share your EazyMarkets referral link and get an Instant $50 credited to your trade account</h6>
            <div className="input-group mb-3">
                <input type="text" className="form-control cus-form-control cus-strip" value={'https://eazy-markets.com/referal/'+userid} disabled/>
                <div className="input-group-append">
                    <span className="input-group-text py-1 cus-strip" ><button onClick={() => copyToClipBoard('https://eazy-markets.com/referal/'+userid)}  className='btn p-0'>{copySuccess}</button></span>
                </div>
            </div>
            <p className='cus-strip mb-1 mt-3'>You affiliate bonus can only be withdrawn</p>
            <ul className='ml-3' style={{listStyle: 'disc'}}>
            <li className='cus-strip'>When you make an investment of $500 minimum on any of the investment plan.</li>
            <li className='cus-strip'>When your referral has an investment Portfolio of $200 or above.</li>
            </ul>
            {/* <button className='btn cus-strip text-primary'>Save</button> */}
        </div>
        <div> 
            <h6 className='text-center mt-3'>My Referrals</h6>
            <table className="table table-bordered mt-3 history-table mb-5">
                <thead>
                <tr>
                    <th className='cus-strip'scope="col">Downliners</th>
                    <th className='cus-strip'scope="col">Referral Earnings</th>
                </tr>
                </thead>
                <tbody>
                { 
                    refInfo.map((dsa, i) =>{
                    return (
                    
                        <tr>
                            <td className='cus-strip'>{dsa.firstname+' '+dsa.lastname}</td>
                            <td className='cus-strip'>$50</td>
                        </tr>
                    
                        )
                    })
                }
                
                </tbody>
            </table>

            
        </div>
      </div>
  </div>
  )
   
};

const mapStateToProps = state => ({
    auth: state.auth,
    userstat: state.userstat,
    transact: state.transact,
    myreferrals: state.myreferrals
    // isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { getReferals })(Invitefriend);


// export default Invitefriend;
