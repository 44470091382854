import axios from "axios";
import { setAlert } from "./alert";
import {
  FETCH_USER,
  FETCH_USER_FAIL,
  USER_INVEST,
  USER_INVEST_FAIL,
  BANK_STATS,
  BANK_STATS_FAIL,
  ADMIN_EDIT,
  ADMIN_EDIT_FAIL,
  MAKE_DEPOSIT,
  MAKE_DEPOSIT_FAIL,
  MAKE_WITHDRAWAL,
  MAKE_WITHDRAWAL_FAIL,
  FETCH_LINKS,
  FETCH_LINKS_FAIL,
  CHANGE_DEP_LINK,
  CHANGE_DEP_LINK_FAIL,
  CHANGE_PROCEED_LINK,
  CHANGE_PROCEED_LINK_FAIL,
    
    
    
    API_URL as api_url
    
} from "./types"; 
// import Deposit from "../components/layout/dashboard/Deposit";


// Fetch User Stat
export const fetchUser = () => async (dispatch) => { 
  // console.log("Fetching stats");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      api_url + "/api/transact/get/user",
      null,
      config
    );
  console.log(res.data, "the REX");

    dispatch({
      type: FETCH_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_USER_FAIL,
    });
  }
};


// Fetch Deposit Links
export const fetchDepositLinks = () => async (dispatch) => { 
  // console.log("Fetching stats");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      api_url + "/api/transact/deposit/links",
      null,
      config
    );
  console.log(res.data, "the links");

    dispatch({
      type: FETCH_LINKS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_LINKS_FAIL,
    });
  }
};


// export const fetchStats = () => async (dispatch) => {
  export const fetchBank = (email) => async (dispatch) => { 
  console.log("Fetching stats");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });
    // console.log(body);
  try {
    const res = await axios.post(api_url + "/api/transact/get/bank", body, config);
    // const res = await axios.post(api_url+'/api/transact/invest', body, config);
  // console.log(res.data, "the REX33333");

    dispatch({
      type: BANK_STATS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BANK_STATS_FAIL,
    });
  }
};


// Fetch Laons
export const submitDepLink = (coinselect, walletAdd) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ coinselect, walletAdd });
  console.log(body);
  

  try {
    const res = await axios.post(api_url+'/api/transact/deposit/changelink', body, config);
    
    console.log(res.data);
    dispatch({
      type: CHANGE_DEP_LINK,
      payload: res.data
    });
    return {
      success: true
    }

    //dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(errors);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CHANGE_DEP_LINK_FAIL
    });

    return {
      success: false
    }
  }
};


export const submitProceedLink = (depLink) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ depLink });
  // console.log(body);
  

  try {
    const res = await axios.post(api_url+'/api/transact/change/proceedlink', body, config);
    
    console.log(res.data);
    dispatch({
      type: CHANGE_PROCEED_LINK,
      payload: res.data
    });
    return {
      success: true
    }

    //dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(errors);

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CHANGE_PROCEED_LINK_FAIL
    });

    return {
      success: false
    }
  }
};



// Fetch Laons
export const makeInvestment = ( useremail, usermicro, investPlan ) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ useremail, usermicro, investPlan });
    console.log(body);
    

    try {
      const res = await axios.post(api_url+'/api/transact/invest', body, config);
      
      console.log(res.data);
      dispatch({
        type: USER_INVEST,
        payload: res.data
      });
      return {
        success: true
      }
  
      //dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;
      console.log(errors);
  
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }
  
      dispatch({
        type: USER_INVEST_FAIL
      });
  
      return {
        success: false
      }
    }
  };


  export const submitResult = ( deposit, investment, earnings, increase, percentage, email ) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ deposit, investment, earnings, increase, percentage, email });
    console.log(body);
    

    try {
      const res = await axios.post(api_url+'/api/transact/admin/edit', body, config);
      
      console.log(res.data);
      dispatch({
        type: ADMIN_EDIT,
        payload: res.data
      });
      return {
        success: true
      }
  
      //dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;
      console.log(errors);
  
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }
  
      dispatch({
        type: ADMIN_EDIT_FAIL
      });
  
      return {
        success: false
      }
    }
  };


  // Make Withdrawal

  export const makeWithdrawal = ( walletAddress, firstname, lastname, email, earnings, coinName ) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ walletAddress, firstname, lastname, email, earnings, coinName });
    console.log(body);
    

    try {
      const res = await axios.post(api_url+'/api/transact/make/withdrawal', body, config);
      
      console.log(res.data);
      dispatch({
        type: MAKE_WITHDRAWAL,
        payload: res.data
      });
      return {
        success: true
      }

      //dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;
      console.log(errors);
  
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }
  
      dispatch({
        type: MAKE_WITHDRAWAL_FAIL
      });
  
      return {
        success: false
      }
    }
  };


  // Deposit

  export const makeDeposit = ( firstname, lastname, useremail, coinselect, depAmount ) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ firstname, lastname, useremail, coinselect, depAmount });
    console.log(body);
    

    try {
      const res = await axios.post(api_url+'/api/transact/make/deposit', body, config);
      
      console.log(res.data);
      dispatch({
        type: MAKE_DEPOSIT,
        payload: res.data
      });
      return {
        success: true
      }

      //dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;
      console.log(errors);
  
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }
  
      dispatch({
        type: MAKE_DEPOSIT_FAIL
      });
  
      return {
        success: false
      }
    }
  };




  // export const fetchCompanies = (limit) => async (dispatch) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.get(
  //       api_url + "/api/loans/companies/" + limit,
  //       null,
  //       config
  //     );
  // console.log(res);
  //     dispatch({
  //       type: FETCH_COMPANIES_SUCCESS,
  //       payload: res.data,
  //     });
  //   } catch (err) {
  //     console.log(err);
  //     dispatch({
  //       type: FETCH_COMPANIES_FAILED,
  //     });
  //   }
  // };




