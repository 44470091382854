import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../parts/Footer';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from "axios";
import { setAlert } from '../../../actions/alert';
import Alert from '../Alert';


import {
  API_URL as api_url  
} from "../../../actions/types.js"; 

export const Uniquereferers = ({ setAlert, auth, match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const [usersRefs, setUsersRefs] = useState([]);
  const [mReferer, setmReferer] = useState({
    refs: ""
    // balance: ""
  });

  useEffect(() => {
    console.log(match.params.id);
    setmReferer({
        refs: match.params.id
    })
    
    axios.get(
      api_url + "/api/users/get/unique/referals/"+match.params.id,
      null,
      config
    ).then((data)  =>{
      console.log(data.data.data, "Hello World");
      setUsersRefs(data.data.data);
    })
    
  }, [match])
    
  const { refs } = mReferer;



  return (
    <div>
      <div className='container'>

        <div className='row'>
            <div className='col-md-7 mx-auto'>

                <h4 className='text-center mt-3'>{refs} Referals</h4>
              <div className='p-3 table-responsive'>
                <table className="table table-bordered mt-3 history-table">
                  <thead>
                    <tr>
                        <th className='cus-strip' scope="col">No</th>
                        <th className='cus-strip' scope="col">Name</th>
                        <th className='cus-strip' scope="col">Email</th>
                        <th className='cus-strip' scope="col">Phone Number</th>
                        {/* <th className='cus-strip' scope="col">Action</th> */}
                    </tr>
                    </thead>
                    <tbody>
                      { 
                          usersRefs.map((dsa, i) =>{
                              if (dsa.referal) {
                                let ii = 1;
                                return (
                                    <tr>
                                        <td className='cus-strip'>{ii++}</td>
                                        <td className='cus-strip'>{dsa.firstname+' '+dsa.lastname}</td>
                                        <td className='cus-strip'>{dsa.email}</td>
                                        <td className='cus-strip'>{dsa.phonenumber}</td>
                                        {/* <td className='cus-strip'><a href={'/admin/referals/'+dsa.referal} className='btn btn-primary btn-sm text-white'>View Referrals</a></td> */}
                                    </tr>
                                   )
                              }
                          
                         })
                       }
                    </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
    // auth: state.auth,
    // allusers: state.allusers,
    // bankstat: state.bankstat,
    // transact: state.transact
    // isAuthenticated: state.auth.isAuthenticated
  });

// export default Users;

export default connect(mapStateToProps, { setAlert })(Uniquereferers);
