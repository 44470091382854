import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../parts/Footer';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setAlert } from '../../../actions/alert';
import Alert from '../Alert';

import {  fetchDepositLinks } from "../../../actions/transactions";
import {  submitDepLink } from "../../../actions/transactions";
import {  submitProceedLink } from "../../../actions/transactions";
import axios from "axios";
import {
  API_URL as api_url  
} from "../../../actions/types.js"; 


export const Depositlink = ({ setAlert, fetchDepositLinks, depositlinks, submitDepLink, submitProceedLink }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [depLinkData, setDepLinkData] = useState([]);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  

  const [formData, setFormData] = useState({
    coinselect: '',
    walletAdd: ''
  });

  
  const [proceedLink, setProceedLink] = useState({
    depLink: ''
  });

    const { depLink } = proceedLink;
    const { coinselect, walletAdd } = formData;

    const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onChange2 = e =>
    setProceedLink({ ...proceedLink, [e.target.name]: e.target.value });


    useEffect(() => {
        fetchDepositLinks();
    }, []);
    useEffect(() => {
        // fetchDepositLinks();
    
        axios.get(
          api_url + "/api/transact/deposit/links",
          null,
          config
        ).then((data)  =>{
          console.log(data.data.data[0], "Hello World");
          setDepLinkData(data.data.data);
        })
    }, []);

    // useEffect(() => {
    //     console.log(depositlinks);
    //     setDepLinkData([{"id": 1, "coin": "Bitcoin", "address": "Available For Withdrawal Bitcoin", "createdAt": null, "updatedAt": null},{"id": 2, "coin": "Ethereum", "address": "Available For Withdrawal Ethereum", "createdAt": null, "updatedAt": null},{"id": 3, "coin": "Lite Coin", "address": "Available For Withdrawal Lite Coin", "createdAt": null, "updatedAt": null},{"id": 4, "coin": "Bitcoin Cash", "address": "Available For Withdrawal Bitcoin Cash", "createdAt": null, "updatedAt": null}])

    // }, [depositlinks]);


//   const onChange = e =>
//     setEditInfo({ ...editInfo, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        // setIsLoading(true);
        e.preventDefault();
        console.log(walletAdd);
    
        let res = await submitDepLink(coinselect, walletAdd);
        if(res.success == true){
            // setIsLoading(false);
            setAlert(coinselect+' link has been updated successfully', 'success');

        } else {
            setAlert(coinselect+' link update was unsuccessful', 'danger');
            // setIsLoading(true);
        }

    };

    const onSubmit2 = async e => {
        // setIsLoading(true);
        e.preventDefault();
        console.log(depLink);
        if (depLink === '') {
            setAlert('Please enter link', 'danger');
        } else {
            let res = await submitProceedLink(depLink);
            if(res.success == true){
                // setIsLoading(false);
                setAlert('Link has been updated successfully', 'success');
    
            } else {
                setAlert('Link update was unsuccessful', 'danger');
                // setIsLoading(true);
            }
        }
    }

  return (
    <div>
      <div className='container'>

        <div className='row'>
            <div className='col-md-7 mx-auto'>
                <div className='mt-5'>
                <Alert />
                    {/* <Link to='/admin/address'>Deposit Address</Link> */}
                    <form onSubmit={e => onSubmit(e)}>
                        <div className='form-group'>
                            <label for="" className='cus-strip'>select</label>
                            <select name='coinselect' value={coinselect} 
                                    onChange={e => onChange(e)} className="form-control cus-form-control custom-select" id="">
                                
                                <option value=''>Choose address</option>
                                {depLinkData.map((option) => (
                                    <option value={option.coin}>{option.coin}</option>
                                ))}
                            </select>
                        </div>
                        <div className='form-group'>
                            <label for="" className='cus-strip'>Wallet Address</label>
                            <input type='text' className='form-control cus-form-control' placeholder='Enter wallet address' name='walletAdd' value={walletAdd} onChange={e => onChange(e)}  />
                        </div>
                        <div className='text-center'>
                            <button type='submit' disabled={isLoading} class="btn btn-manager my-2">
                                {/* {isLoading ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                ) : null}{' '} */}
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                <div className='mt-4'>
                    <form onSubmit={e => onSubmit2(e)}>
                        <div className='form-group'>
                            <label for="" className='cus-strip'>Proceed Link</label>
                            <input type='text' className='form-control cus-form-control' placeholder='Enter link address' name='depLink' value={depLink} onChange={e => onChange2(e)} />
                        </div>
                        <div className='text-center'>
                            <button type='submit' class="btn btn-manager my-2">
                                {/* {isLoading ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                ) : null}{' '} */}
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};


Depositlink.propTypes = {
    setAlert: PropTypes.func.isRequired,
  };


const mapStateToProps = state => ({
    // auth: state.auth,
    depositlinks: state.depositlinks,
    // bankstat: state.bankstat,

  });

// export default Admin;

export default connect(mapStateToProps, { setAlert, fetchDepositLinks, submitDepLink, submitProceedLink })(Depositlink);
