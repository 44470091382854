import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
// import banner1 from '../../../img/banner1.jpg';


// const dangerouslySetInnerHTML={{__html: loanData.story}}


const items = [
  {
    src: '/d1.JPG',
    src2: '/mb1.JPG',
    altText: '',
    caption: "<div class='signup-inner'><h2 class='masthead'><strong>Discover a world of opportunities</strong></h2><p class='text-white mastparagraph mt-4'>Get more from trading with EazyMarkets.</p><div class='text-xs-center text-sm-left text-md-left'><a href='/register' class='btn btn-signup mt-4'>Get Started</a></div><p class='text-dim cus-strip mt-5'>Invest globally in stocks, options, forex, cryptocurrencies and lots more at the lowest cost.</p></div>"
  },
  {
    src: '/d2.JPG',
    src2: '/mb2.JPG',
    altText: '',
    caption: "<div class='signup-inner'><h2 class='masthead'><strong>Trade Varieties of Assets</strong></h2><p class='text-white mastparagraph mt-3'>Currencies & Cryptocurrencies, stocks, commodities, indices.</p><div class='text-xs-center text-sm-left text-md-left'><a href='/tradeoptions' class='btn btn-signup mt-3'>Learn More</a></div><p class='text-dim  mt-3  cus-strip'>Eazy-markets provides hundreds of tradable assets guaranteeing all the best possible opportunities to diversify your trading portfolio</p></div>"
  },
  {
    src: '/d3.JPG',
    src2: '/mb3.JPG',
    altText: '',
    caption: "<div class='signup-inner text-center text-xs-center text-sm-left text-md-left'><p></p></p><h2 class='masthead'><strong>Need help to start trading?</strong></h2><p class='text-white mastparagraph mt-3'>Build your skills with EazyMarkets free educational resources</p><div class='text-xs-center text-sm-left text-md-left'><a href='/education' class='btn btn-signup mt-3'>Learn More</a><p class='text-dim mt-2 cus-strip ' >Utilise our expert programmers and benefit from the automated strategy</p></p></div></div>"
  },
  {
    src: '/d4.JPG',
    src2: '/mb4.JPG',
    altText: '',
    caption: "<div class='signup-inner'><h2 class='masthead mb-4'><strong>Build a team with EazyMarkets</strong></h2><p class='text-white mastparagraph mb-4'>Refer a friend to eazyMarkets and get a free $50 credited to your account</p><div class='text-xs-center text-sm-left text-md-left'><a href='/login' class='btn btn-signup my-3'>Get Started</a></div><p class='text-dim mb-4 cus-strip '>T&Cs Apply</p></div>"
  }
];

const Slider = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        {/* <img className='img-fluid' src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        /> */}
        <div
          className='banner-img d-none d-md-block'
          style={{
            backgroundImage: `url(${item.src})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className='slide-overlay'>
            
          </div>
          <div className='z-index position-relative' dangerouslySetInnerHTML={{__html: item.caption}}>

            </div>
          {/* <CarouselCaption
            captionText={item.caption}
            captionHeader={item.caption}
            
          /> */}
        </div>
        <div
          className='banner-img d-block d-md-none'
          style={{
            backgroundImage: `url(${item.src2})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className='slide-overlay'>
            
          </div>
          <div className='z-index position-relative' dangerouslySetInnerHTML={{__html: item.caption}}>

            </div>
          {/* <CarouselCaption
            captionText={item.caption}
            captionHeader={item.caption}
            
          /> */}
        </div>
        
      </CarouselItem>
    );
  });

  return (
    <div className='position-relative'>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          className='c-indicator'
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          
        />
        {slides}
        {/* <CarouselControl
          direction='prev'
          directionText='Previous'
          onClickHandler={previous}
        />
        <CarouselControl
          direction='next'
          directionText='Next'
          onClickHandler={next}
        /> */}
      </Carousel>
      {/* <div className='position-absolute signup-div'>
        <div className='signup-inner'>
          <h2 className='masthead'><strong>Get more with the 30% Deposit Bonus</strong></h2>          
          <p className='text-white mastparagraph'>Deposit with FXTM today to get an extra 30%. <br /> Earn up to $250!</p>
          <div className='text-xs-center text-sm-left text-md-left'>
            <button type="button" class="btn btn-signup">Get Started</button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Slider;
