import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { faHome, faLessThan, faGreaterThan, faListAlt, faWallet, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import { Footer } from '../parts/Footer'; 

const Tradeoptions = props => {
  const [activeTab, setActiveTab] = useState('1');
  const [isOpen26, setIsOpen26] = useState(false);

  const toggle26 = () => setIsOpen26(!isOpen26);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <div className='py-5 text-center text-white' style={{backgroundImage: `url(hero-bg.jpg)`}}>
        <div className='px-3'>
          <h5 className='mb-3'>Your Gateway to the <br /> World's Markets</h5>
          <h6 style={{fontSize: '10px'}} className='cus-strip'>Clients from over 200 countries and territories invest globally in stocks, options, 
              futures, currencies, bonds and funds from a singe integrated account.</h6>
        </div>
        
      </div>
      <div className='container'>
        <p className='cus-strip mt-4'>EazyMarkets brings together millions of people around the world to invest in a wide array of financial instruments. There are various securities and instruments which our traders specializes in to get the most out of the financial market.</p>
        <p className='cus-strip mt-4'>Decades ago, investors primarily relied on limited financial instruments like bonds and stocks to constitute their portfolio. However, with the advancement of time, investing options have amplified manyfold. Today, there is no shortage of financial instruments. But with multiple options, the complexity and confusion have also heightened. In such situations, finding the right investment instruments is critical, which is why EazyMarkets allows the expert in managing the trade portfolio of investors.</p>
        <p className='cus-strip mt-4'>A strong and well-balanced portfolio helps you earn higher returns, mitigate risk, and substantially accumulate wealth over time to achieve your monetary goals.</p>
        <p className='cus-strip mt-4'>Financial instruments are classified into five broad categories:  Indices, Equities, Commodities, Bonds, and Forex.</p>
        <p className='cus-strip mt-4'>The financial market is not all about trading currencies as assets. There are many types of financial instruments. Many instruments are custom agreements that the parties tailor to their own needs. However, many financial instruments are based on standardized contracts with predetermined characteristics.</p>

        <h6>Forex</h6>
        <div className='row'>
          <div className='col-md-7'>
            <img className='img-fluid mb-2' src='t-currency.jpeg' />
          </div>
        </div>

        <p className='cus-strip'>The foreign exchange market (Forex, FX, or currency market) is a global decentralized or over-the-counter (OTC) market for the trading of currencies. Foreign exchange market being the largest market in the world also comes with some of the best financial instruments that one can trade on a daily basis. The market accounts for more than $4 trillion in average daily volume.</p>
        <p className='cus-strip'>Foreign exchange is the art of trading currencies by merely taking advantage of changes in currency pair prices. Some of the commonly traded Forex instruments include currency pairs of the biggest economies in the world. Such instruments include n EUR/USD, GBP/USD, USD/JPY, and USD/CHF among others.</p>
        <p className='cus-strip'>This market determines foreign exchange rates for every currency. It includes all aspects of buying, selling and exchanging currencies at current or determined prices. In terms of trading volume, it is by far the largest market in the world, followed by the credit market. Currency trading are always in pairs, for example USD/JPY where the second currency is the amount that could be purchased by one unit of the first currency.</p>
        <p className='cus-strip'>Forex instruments are some of the best to trade in part because of their relative stability. The fact that these instruments are available round the clock also makes them ideal to trade at any given the time of day regardless of jurisdiction. EazyMarkets also tend to provide a significant amount of leverage that makes it easy for our expert traders to place a trade.</p>
        

        <h6>Cryptocurrency</h6>
        <div className='row'>
          <div className='col-md-7'>
            <img className='img-fluid mb-2' src='t-crypto.jpeg' />
          </div>
        </div>

        <p className='cus-strip'>A cryptocurrency (or crypto currency or crypto for short) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records, to control the creation of additional coins, and to verify the transfer of coin ownership. Trading crypto's is the act of speculating on the price movements via a CFD trading account, or buying and selling the underlying coins via an exchange.</p>
        <p className='cus-strip'>Cryptocurrency markets are decentralised, which means they are not issued or backed by a central authority such as a government. Instead, they run across a network of computers. However, cryptocurrencies can be bought and sold via exchanges and stored in ‘wallets’.</p>
        <p className='cus-strip'>Unlike traditional currencies, cryptocurrencies exist only as a shared digital record of ownership, stored on a blockchain. When a user wants to send cryptocurrency units to another user, they send it to that user’s digital wallet. The transaction isn’t considered final until it has been verified and added to the blockchain through a process called mining. This is also how new cryptocurrency tokens are usually created.</p>
        <p className='cus-strip'>Cryptocurrency trading is very similar to forex, allowing traders to purchase cryptocurrency with U.S. dollars. As with forex, cryptocurrency our traders handled some trades with a buy-and-hold strategy and also trade the daily/weekly up-and-down volatility.</p>
        <p className='cus-strip'></p>
        <p className='cus-strip'></p>
        <p className='cus-strip'></p>


        <h6>Commodities</h6>
        
        <div className='row'>
          <div className='col-md-7'>
            <img className='img-fluid mb-2' src='t-commodity.jpeg' />
          </div>
        </div>

        <p className='cus-strip'>Commodities refer to physical goods like gold, silver,and oil. Following geopolitical events is the key to commodity trading as these events affect the price fluctuations of these assets. Keeping updated on these events give traders a better understanding of commodities which in turn help them make better predictions.</p>
        <p className='cus-strip'>Liquid commodities such as crude oil, gold, and silver also provide a way for people to trade the financial markets. Commodities as financial instruments provide a way of diversifying away from equities or stock indices.</p>
        <p className='cus-strip'>Under the metals category, the most traded financial instrument is gold, silver, Platinum, and Copper. Under Energy, crude oil remains the most traded followed by natural gas and gasoline. Gold is the most traded financial instrument in the commodity sector as traders view it reliable and dependable as a way of conveying value.</p>
        <p className='cus-strip'>EazyMarkets traders also turn to precious metals to hedge against high inflation or during periods of currency devaluation.  Crude is another popular financial instrument as changes to oil outputs from wells around the world affect prices, allowing our traders to take advantage of price changes.</p>
        <p className='cus-strip'></p>

        
        <h6>Stock Indices</h6>
        <div className='row'>
          <div className='col-md-7'>
            <img className='img-fluid mb-2' src='t-stock.jpeg' />
          </div>
        </div>

        <p className='cus-strip'>Stock can be explained as a company’s ownership share and represents a claim on the company’s assets and earnings. The shareholders profit when the company is doing well but the risks of shareholders are high as they stand to lose a great deal if the company faces turbulent times. </p>
        <p className='cus-strip'>Stock indices highlight the performance of a broader stock market or a portion of the market. Such indices list stocks of companies from a given sector or based on their market cap. Prices of selected stocks usually are weighted as a way of making it easy for investors to compare returns of specific investments.</p>
        <p className='cus-strip mb-1'>A stock index could represent the performance country’s economy or the entire world’s stock market.  Stocks of some of the biggest companies are some of the best for day trading given the high levels of liquidity and volatility they come with. Such stocks include Apple, Microsoft, Amazon, and Google Qualcomm Intel, etc.</p>
        {/* <p className='cus-strip'></p>
        <p className='cus-strip'></p>
        <p className='cus-strip'></p> */}

        <div>
          {!isOpen26 ? (
            // <FontAwesomeIcon icon={faSpinner} spin />
          <button className="pl-0 py-0 btn btn-block text-left cus-strip text-primary" onClick={toggle26} style={{ marginBottom: '1rem' }}> See more</button>
          ) : null}{' '}
          <Collapse isOpen={isOpen26}>
            <div>
              <div className="row">
                <div className="col-12">
                  <h4 className='my-2'>Americas</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h6 className='my-2'>Stocks</h6>
                </div>
              </div>
              <div className="row">
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <h5 className='my-2'><img className='mr-2'src="/USA.png" alt="Online global trading stocks: USA" />United States</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Arca (NYSE)</li>
                      <li className="paralist">ArcaEdge</li>
                      <li className="paralist">BATS</li>
                      <li className="paralist">Chicago Stock Exchange (CHX)</li>
                      <li className="paralist">Direct Edge</li>
                      <li className="paralist">EDGEA</li>
                      <li className="paralist">IB VWAP Dealing Network </li>
                      <li className="paralist">IEX </li>
                      <li className="paralist">INET</li>
                      <li className="paralist">ISE Stock Exchange</li>
                      <li className="paralist">Knight Securities</li>
                      <li className="paralist">Long-Term Exchange (LTSE)</li>
                      <li className="paralist">MEMX</li>
                      <li className="paralist">MIAX PEARL</li>
                      <li className="paralist">NASDAQ</li>
                      <li className="paralist">NASDAQ BX</li>
                      <li className="paralist">NASDAQ PSX</li>
                      <li className="paralist">New York Stock Exchange </li>
                      <li className="paralist">NSX</li>
                      <li className="paralist">NYSENAT</li>
                      <li className="paralist">NYSE American</li>
                      <li className="paralist">Pink OTC Markets</li>
                      </ul>
                      <br />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <h5 className='my-2'><img className='mr-2'src="/Canada.png" alt="Online global trading stocks: Canada" />Canada</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Alpha ATS</li>
                      <li className="paralist">Aequitas NEO</li>
                      <li className="paralist">Aequitas NEO Lit</li>
                      <li className="paralist">Chi-X</li>
                      <li className="paralist">LYNX</li>
                      <li className="paralist">OMEGA ATS</li>
                      <li className="paralist">Canadian Securities Exchange</li>
                      <li className="paralist">Toronto Stock Exchange</li>
                      <li className="paralist">TSX Venture</li>
                      </ul>
                      <br />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <h5 className='my-2'><img className='mr-2'src="/Mexico.png" alt="Online global trading stocks: Mexico" />Mexico</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Mexican Stock Exchange</li>
                      </ul>
                      <br />
                  </div>
              </div> 
              <div className="row">
                <div className="col-12">
                  <h6 className='my-2'>ETFs</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <h5 className='my-2'><img className='mr-2'src="/USA.png" alt="" />Online global trading ETFs: USA</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Arca (NYSE)</li>
                      <li className="paralist">Chicago Board Options Exchange (CBOE)</li>
                      <li className="paralist">Chicago Stock Exchange (CHX)</li>
                      <li className="paralist">NASDAQ BX</li>
                      <li className="paralist">New York Stock Exchange</li>
                      <li className="paralist">NYSE American</li>
                      </ul>
                      <br />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <h5 className='my-2'><img className='mr-2'src="/Canada.png" alt="Online global trading ETFs: Canada" />Canada</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Chi-X</li>
                      <li className="paralist">OMEGA ATS</li>
                      <li className="paralist">PURE</li>
                      <li className="paralist">Toronto Stock Exchange</li>
                      </ul>
                      <br />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <h5 className='my-2'><img className='mr-2'src="/Mexico.png" alt="Online global trading ETFs: Mexico" />Mexico</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Mexican Stock Exchange</li>
                      </ul>
                      <br />
                  </div>
                </div> 
                <div className="row">
                  <div className="col-12">
                    <h6 className='my-2'>Securities Options</h6>
                  </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <h5 className='my-2'><img className='mr-2'src="/USA.png" alt="Online global trading Securities Options: USA" />United States</h5>
                          <ul className='ml-4' style={{listStyleType: 'disc'}}>
                          <li className="paralist">BATS</li>
                          <li className="paralist">Boston Options Exchange</li>
                          <li className="paralist">C2</li>
                          <li className="paralist">Chicago Board Options Exchange</li>
                          <li className="paralist">EDGX Options Exchange</li>
                          <li className="paralist">ISE</li>
                          <li className="paralist">ISE Mercury</li>
                          <li className="paralist">GEMINI</li>
                          <li className="paralist">MIAX Options Exchange</li>
                          <li className="paralist">MIAX PEARL</li>
                          <li className="paralist">NASDAQ OMX BX Options Exchange</li>
                          <li className="paralist">NASDAQOM</li>
                          <li className="paralist">NYSE American</li>
                          <li className="paralist">Pacific Exchange</li>
                          <li className="paralist">Philadelphia Stock Exchange</li>
                          </ul>
                          <br />
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <h5 className='my-2'><img className='mr-2'src="/Canada.png" alt="Online global trading Securities Options: Canada" />Canada</h5>
                          <ul className='ml-4' style={{listStyleType: 'disc'}}>
                          <li className="paralist">Montreal Exchange</li>
                          </ul>
                          <br />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4"> </div>
                </div> 
                <div className="row">
                  <div className="col-12">
                    <h6 className='my-2'>Mutual Funds</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <h5 className='my-2'><img className='mr-2'src="/USA.png" alt="Online trading Mutual Funds: USA" />United States</h5>
                        </div>
                </div>
                <div className="row">
                      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4"> 
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                    <li className="paralist">361 Capital Funds</li>
                    <li className="paralist">AAAMCO Funds</li>
                    <li className="paralist">ABR Dynamic Mutual Funds</li>
                    <li className="paralist">Aberdeen Funds</li>
                    <li className="paralist">ACM Funds</li>
                    <li className="paralist">Adirondack Funds</li>
                    <li className="paralist">Advisors Preferred Funds</li>
                    <li className="paralist">Advisory Research Funds</li>
                    <li className="paralist">AGF Funds</li>
                    <li className="paralist">AINN Funds</li>
                    <li className="paralist">Akre Funds</li>
                    <li className="paralist">Alger Family of Funds</li>
                    <li className="paralist">Allianz</li>
                    <li className="paralist">AlphaCentric Funds</li>
                    <li className="paralist">ALPS Funds</li>
                    <li className="paralist">ALPS Smith Funds </li>
                    <li className="paralist">Altegris Funds</li>
                    <li className="paralist">American Beacon</li>
                    <li className="paralist">American Funds</li>
                    <li className="paralist">American Century Funds</li>
                    <li className="paralist">AMG Funds</li>
                    <li className="paralist">Anchor Capital Funds</li>
                    <li className="paralist">Anfield Capital Management</li>
                    <li className="paralist">Angel Oak Funds</li>
                    <li className="paralist">Aperture Investors</li>
                    <li className="paralist">Aquila Funds</li>
                    <li className="paralist">AQR Funds</li>
                    <li className="paralist">Arin</li>
                    <li className="paralist">Ashmore Funds</li>
                    <li className="paralist">Astor Funds</li>
                    <li className="paralist">ATAC Funds</li>
                    <li className="paralist">Ave Maria Funds</li>
                    <li className="paralist">AXS Investments</li>
                    <li className="paralist">Azzad Funds</li>
                    <li className="paralist">Baird Funds</li>
                    <li className="paralist">Balter Funds</li>
                    <li className="paralist">Baron Funds</li>
                    <li className="paralist">Belmont Capital Funds</li>
                    <li className="paralist">Bernzott Funds</li>
                    <li className="paralist">BlackRock Funds</li>
                    <li className="paralist">BMO Funds</li>
                    <li className="paralist">BNY Mellon Funds</li>
                    <li className="paralist">Bridgeway Funds</li>
                    <li className="paralist">Bramshill Funds</li>
                    <li className="paralist">Brown Advisory Funds</li>
                    <li className="paralist">BTS Funds</li>
                    <li className="paralist">Buffalo Funds</li>
                    <li className="paralist">Calamos Funds</li>
                    <li className="paralist">Cambiar Funds</li>
                    <li className="paralist">Calvert Funds</li>
                    <li className="paralist">Cannabis Growth Fund</li>
                    <li className="paralist">Canyon Partners, LLC</li>
                    <li className="paralist">Carillon Funds</li>
                    <li className="paralist">Catalyst Funds</li>
                    <li className="paralist">Causeway Funds</li>
                    <li className="paralist">Cavalier Funds</li>
                    <li className="paralist">Cavanal Hill Funds</li>
                    <li className="paralist">CBOE Vest Funds</li>
                    <li className="paralist">Cedar Ridge Funds</li>
                    <li className="paralist">Centerstone Funds</li>
                    <li className="paralist">Centre Funds</li>
                    <li className="paralist">Champlain Funds</li>
                    <li className="paralist">Chartwell Investment Partners</li>
                    <li className="paralist">Chiron Funds</li>
                    <li className="paralist">CMG Funds</li>
                    <li className="paralist">Columbia Funds</li>
                    <li className="paralist">Copley Funds</li>
                    <li className="paralist">Counterpoint Mutual Funds</li>
                    <li className="paralist">Crow Point Partners Funds</li>
                    <li className="paralist">Cullen Funds</li>
                    <li className="paralist">Davenport Funds</li>
                    <li className="paralist">Davis Funds</li>
                    <li className="paralist">Day Hagen Funds</li>
                    <li className="paralist">Deerpark Fund</li>
                    <li className="paralist">Dimensional Fund Advisors</li>
                    <li className="paralist">Dimensional Fund Advisors (DFA) Offshore Funds</li>
                    <li className="paralist">Direxion Funds</li>
                    <li className="paralist">Dodge &amp; Cox Funds</li>
                    <li className="paralist">Donoghue Forlines Funds</li>
                    <li className="paralist">Doubleline Funds</li>
                    <li className="paralist">Dreyfus</li>
                    <li className="paralist">Driehaus Mutual Funds</li>
                    <li className="paralist">Eaton Vance Mutual Funds</li>
                    <li className="paralist">Emerald Mutual Funds</li>
                    <li className="paralist">Empiric Funds</li>
                    <li className="paralist">EntreprenuerShares </li>
                    <li className="paralist">Equinox Funds</li>
                    <li className="paralist">Euro Pacific Funds</li>
                    <li className="paralist">Eventide Funds</li>
                    </ul>
                    </div>
                      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">  
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                    <li className="paralist">Evermore Funds Trust</li>
                    <li className="paralist">Exceed Funds</li>
                    <li className="paralist">Fairholme Funds Inc.</li>
                    <li className="paralist">Federated Investors</li>
                    <li className="paralist">Fidelity Funds</li>
                    <li className="paralist">First Eagle Funds</li>
                    <li className="paralist">FMI Funds</li>
                    <li className="paralist">Forward Funds</li>
                    <li className="paralist">FPA Funds</li>
                    <li className="paralist">Frank Funds</li>
                    <li className="paralist">Franklin Templeton Investments</li> 
                    <li className="paralist">Frost Funds</li> 
                    <li className="paralist">FS Investments</li>
                    <li className="paralist">Fuller &amp; Thaler Funds</li>
                    <li className="paralist">Gabelli Funds</li>           
                    <li className="paralist">Gator Funds</li>
                    <li className="paralist">Gavekal Fund</li>
                    <li className="paralist">Goehring &amp; Rozencwajg Fund</li>
                    <li className="paralist">Goodwood Funds</li>
                    <li className="paralist">Good Harbor Funds</li>
                    <li className="paralist">Gotham Funds</li>
                    <li className="paralist">GQG Partners</li>
                    <li className="paralist">Grandeur Peak Funds</li>
                    <li className="paralist">Greenspring Fund</li>
                    <li className="paralist">Guggenheim</li>
                    <li className="paralist">Guinness Atkinson Funds</li>
                    <li className="paralist">Hancock Horizon Funds</li>
                    <li className="paralist">Harbor Funds</li>
                    <li className="paralist">Harding Loevner Funds</li>
                    <li className="paralist">Hartford Funds</li>
                    <li className="paralist">Harvest Funds</li>
                    <li className="paralist">Henderson Global Inv Ltd</li>
                    <li className="paralist">Hennessy Funds</li>
                    <li className="paralist">Highland Funds</li>
                    <li className="paralist">Hodges Funds</li>
                    <li className="paralist">Horizon Funds</li>
                    <li className="paralist">Hotchkis and Wiley</li>
                    <li className="paralist">HSBC Funds</li>
                    <li className="paralist">Huber Capital Funds</li>
                    <li className="paralist">Index Funds</li>
                    <li className="paralist">Invesco</li>
                    <li className="paralist">Investec - US</li>
                    <li className="paralist">Issachar Funds</li>
                    <li className="paralist">IVA Funds</li>
                    <li className="paralist">Ivy Funds</li>
                    <li className="paralist">Jackson Square Funds</li>
                    <li className="paralist">James Advantage</li>
                    <li className="paralist">James Alpha Funds</li>
                    <li className="paralist">Janus Henderson Funds</li>
                    <li className="paralist">Jensen Funds</li>
                    <li className="paralist">JOHCM Funds</li>
                    <li className="paralist">J.P. Morgan Funds</li>
                    <li className="paralist">Kellner Funds</li>
                    <li className="paralist">KCM Funds</li>
                    <li className="paralist">KKM Funds</li>
                    <li className="paralist">Kinetics Funds</li>
                    <li className="paralist">Kopernik Funds</li>
                    <li className="paralist">Lateef Funds</li>
                    <li className="paralist">Lazard Funds</li>
                    <li className="paralist">Leader Funds</li>
                    <li className="paralist">Legg Mason</li>
                    <li className="paralist">Leland Funds</li>
                    <li className="paralist">Liberty Street Funds</li>
                    <li className="paralist">Liebenthal Funds</li>
                    <li className="paralist">Litman Gregory Masters Funds</li>
                    <li className="paralist">LJM Funds</li>
                    <li className="paralist">Longboard Funds</li>
                    <li className="paralist">Loomis Sayles Mutual Funds</li>
                    <li className="paralist">LS Theta Fund</li>
                    <li className="paralist">Mainstay Funds Mutual Funds</li>
                    <li className="paralist">Mairs &amp; Power Funds</li>
                    <li className="paralist">Manning &amp; Napier Mutual Funds</li>
                    <li className="paralist">Marketfield Fund</li>
                    <li className="paralist">Matisse Funds Mutual Funds</li>
                    <li className="paralist">Matrix Asset Advisors Funds</li>
                    <li className="paralist">Matthews Asia Funds</li>
                    <li className="paralist">Merk Funds</li>
                    <li className="paralist">MetWest Funds</li>
                    <li className="paralist">Miller Howard Funds Trust</li>
                    <li className="paralist">MFS Mutual Funds</li>
                    <li className="paralist">Motley Fool Funds Mutual Funds</li>
                    <li className="paralist">Nationwide Funds</li>
                    <li className="paralist">Natixis Mutual Funds</li>
                    <li className="paralist">Navigator Funds</li>
                    <li className="paralist">Needham Funds</li>
                    <li className="paralist">Neuberger Berman Funds</li>
                    <li className="paralist">Newfound Funds</li>
                    <li className="paralist">Northern Funds</li>
                    <li className="paralist">North Square Investments</li>
                    </ul>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                    <li className="paralist">Nuveen Investments Mutual Funds</li>
                    <li className="paralist">Oak Associates Funds</li>
                    <li className="paralist">Oak Ridge Funds</li> 
                    <li className="paralist">Oakmark Funds</li>
                    <li className="paralist">OCM Funds</li>
                    <li className="paralist">Orinda Funds</li>
                    <li className="paralist">Osterweis Funds</li>
                    <li className="paralist">Otter Creeek Funds</li>
                    <li className="paralist">Pacific Funds</li>
                    <li className="paralist">Palmer Square Funds</li>
                    <li className="paralist">Parnassus Investments</li>
                    <li className="paralist">Pax World Mutual Funds</li>
                    <li className="paralist">Payden &amp; Rygel Funds</li>
                    <li className="paralist">Penn Capital Funds Trust</li>
                    <li className="paralist">Performance Trust Funds</li>
                    <li className="paralist">Permanent Portfolio</li>
                    <li className="paralist">PGIM Investments</li>
                    <li className="paralist">Pimco Funds</li>
                    <li className="paralist">Pioneer Investment</li>
                    <li className="paralist">Polen Capital</li>
                    <li className="paralist">Power Funds</li>
                    <li className="paralist">Preserver Funds</li>
                    <li className="paralist">PRIMECAP Odyssey Funds</li>
                    <li className="paralist">Princeton Fund Advisors</li>
                    <li className="paralist">Principal Funds</li>
                    <li className="paralist">Principal Street Funds</li>
                    <li className="paralist">Probabilities Fund</li>
                    <li className="paralist">Prudential Investments</li>
                    <li className="paralist">PSI Funds</li>
                    <li className="paralist">Putnam Investments</li>
                    <li className="paralist">Q3 All-Weather Funds</li>
                    <li className="paralist">Quaker Funds</li>
                    <li className="paralist">Rareview Funds</li>
                    <li className="paralist">Rational Funds</li>
                    <li className="paralist">RBB Funds</li>
                    <li className="paralist">Recurrent Investment Advisors</li>
                    <li className="paralist">Redwood Funds</li>
                    <li className="paralist">RiverNorth</li>
                    <li className="paralist">Russell Investments</li>
                    <li className="paralist">Salient Funds</li>
                    <li className="paralist">Saratoga Advantage Trust Funds</li>
                    <li className="paralist">Saturna Capital Funds</li>
                    <li className="paralist">Schooner Funds'</li>
                    <li className="paralist">Schroder Funds</li>
                    <li className="paralist">Schwab</li>
                    <li className="paralist">Semper Capital Management</li>
                    <li className="paralist">Selected Funds</li>
                    <li className="paralist">Seven Canyons Funds</li>
                    <li className="paralist">Shelton Capital Management</li>
                    <li className="paralist">Shenkman Funds</li>
                    <li className="paralist">Sierra Funds</li>
                    <li className="paralist">SilverPepper Funds</li>
                    <li className="paralist">Smead Funds</li>
                    <li className="paralist">Soundwatch Capital</li>
                    <li className="paralist">Sparrow Growth Fund</li>
                    <li className="paralist">Standpoint Funds</li>
                    <li className="paralist">State Street Global Advisors (SSgA)</li>
                    <li className="paralist">Sterling Capital Funds</li>
                    <li className="paralist">Swan Funds</li>
                    <li className="paralist">Taylor Frigon Funds</li>
                    <li className="paralist">TIAA-CREF</li>
                    <li className="paralist">T Rowe Price</li>
                    <li className="paralist">TCW Funds</li>
                    <li className="paralist">The Royce Funds Mutual Funds</li>
                    <li className="paralist">Third Avenue Funds</li>
                    <li className="paralist">Thompson IM Funds</li>
                    <li className="paralist">Thornburg Funds Mutual Funds</li>
                    <li className="paralist">Tocqueville Funds Mutual Funds</li>
                    <li className="paralist">Toews Funds</li>
                    <li className="paralist">Transamerica Mutual Funds</li>
                    <li className="paralist">USA Mutuals</li>
                    <li className="paralist">U.S. Global Investors Funds</li>
                    <li className="paralist">Van Eck Funds</li>
                    <li className="paralist">Vanguard Funds</li>
                    <li className="paralist">Victory Funds</li>
                    <li className="paralist">Virtus Funds</li>
                    <li className="paralist">Voya Investment Management</li>
                    <li className="paralist">Wasatch Funds</li>
                    <li className="paralist">Wavelength Funds</li>
                    <li className="paralist">Weitz Funds</li>
                    <li className="paralist">Wells Fargo Advantage Funds</li>
                    <li className="paralist">Westwood Funds</li>
                    <li className="paralist">William Blair Funds</li>
                    <li className="paralist">Winning Points Funds</li>
                    <li className="paralist">World Funds</li>
                    <li className="paralist">Yorktown Funds</li>
                    <li className="paralist">Zacks Funds</li>
                    <li className="paralist">Zeo Strategic Income Fund</li>
                    <li className="paralist">Zevenbergen Funds</li>
                      </ul>
                      </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                          <h5 className='my-2'><img className='mr-2'src="/Global.png" alt="Online global trading Mutual Funds" />Global</h5>
                  </div>
                </div>
                <div className="row">
                      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4"> 
                        <ul className='ml-4' style={{listStyleType: 'disc'}}>
                        <li className="paralist">Adepa Asset Management Ltd.</li>
                        <li className="paralist">Alliance Bernstein Global Funds</li>
                        <li className="paralist">Allianz Global Investors</li>
                        <li className="paralist">AMUNDI Offshore Funds</li>
                        <li className="paralist">Bank Vontobel Funds</li>
                        <li className="paralist">Blackrock Global Funds</li>
                        <li className="paralist">BNP Paribas Asset Management</li>
                        <li className="paralist">Carmignac Gestion</li>
                        <li className="paralist">COLCHESTER GLOBAL FUNDS </li>
                        <li className="paralist">Columbia Threadneedle Global Funds</li>
                        <li className="paralist">Cullen Capital Mgmt LLC (IE) </li> 
                        <li className="paralist">DFA Offshore Funds</li>
                        <li className="paralist">EFG Asset Management</li>
                        <li className="paralist">Euronext Mutual Funds</li>
                        </ul>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <ul className='ml-4' style={{listStyleType: 'disc'}}>
                        <li className="paralist">Franklin Templeton Offshore Funds</li>
                        <li className="paralist">GAM Offshore Funds</li>
                        <li className="paralist">Invesco Global Funds</li>
                        <li className="paralist">Investec Asset Management</li>
                        <li className="paralist">Janus Henderson Global Investors</li>
                        <li className="paralist">Lazard Freres Gestion</li>
                        <li className="paralist">Lazard Fund Mgrs LTD (IE)</li>
                        <li className="paralist">LYXOR AM (IE)</li>
                        <li className="paralist">Meridian Global Investments Ltd.</li>
                        <li className="paralist">MFS Meridian Funds</li>
                        <li className="paralist">M&amp;G International Investments Ltd</li>
                        <li className="paralist">Muzinich &amp; Co LTD (IE)</li>
                        <li className="paralist">Natixis Offshore Funds</li>
                        <li className="paralist">Neuberger Berman Europe LTD</li>
                        </ul>
                        </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <ul className='ml-4' style={{listStyleType: 'disc'}}>
                        <li className="paralist">Ninety One</li>
                        <li className="paralist">Pictet Offshore Funds</li>
                        <li className="paralist">PIMCO Global Funds</li>
                        <li className="paralist">Principal Global Inv (Europe) LTD (IE)</li>
                        <li className="paralist">Robeco Asset Management (LU)</li>
                        <li className="paralist">Sanlam Asset Management</li>
                        <li className="paralist">Schroders Offshore Funds</li>
                        <li className="paralist">SWISSCANTO AM FUNDS</li>
                        <li className="paralist">Thornubrg (IE)</li>
                        <li className="paralist">VANGUARD GROUP (IRELAND) LTD (IE)</li>
                        <li className="paralist">VANGUARD INV UK LTD (GB)</li>
                        <li className="paralist">Vontobel Funds</li>
                        <li className="paralist">Wellington Funds</li>
                        </ul>
                    </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4 className='my-2'>Europe</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h6 className='my-2'>Stocks</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <h5 className='my-2'><img className='mr-2'src="/Austria.png" alt="Online global trading Stocks: Austria" />Austria</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Vienna Stock Exchange</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Belgium.png" alt="Online global trading Stocks: Belgium" />Belgium</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">BATS Europe (BATEEN)</li>
                      <li className="paralist">CHI-X Europe Ltd Clearnet</li>
                      <li className="paralist">Euronext Brussels Stocks</li>
                      <li className="paralist">Turquoise TRQXEN</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Estonia.png" alt="Online global trading Stocks: Estonia" />Estonia</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">NASDAQ Baltic (N.TALLINN)</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/France.png" alt="Online global trading Stocks: France" />France</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">BATS Europe (BATEEN)</li>
                      <li className="paralist">CHI-X Europe Ltd Clearnet</li>
                      <li className="paralist">Euronext France</li>
                      <li className="paralist">Turquoise TRQXEN</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Germany.png" alt="Online global trading Stocks: Germany" />Germany</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">BATS Europe (BATEDE)</li>
                      <li className="paralist">CHI-X Europe Ltd Clearstream</li>
                      <li className="paralist">Frankfurt Stock Exchange</li>
                      <li className="paralist">GETTEX</li>
                      <li className="paralist">Stuttgart Stock Exchange</li>
                      <li className="paralist">Tradegate Exchange</li>
                      <li className="paralist">Turquoise TRQXDE</li>
                      <li className="paralist">XETRA</li>
                      </ul>
                      <br />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <h5 className='my-2'><img className='mr-2'src="/Hungary.png" alt="Online global trading Stocks: Hungary" />Hungary</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Budapest Stock Exchange</li>
                      </ul>
                      <br />
                <h5 className='my-2'><img className='mr-2'src="/Italy.png" alt="Online global trading Stocks: Italy" />Italy</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Borsa Italiana</li>
                      </ul>
                      <br />
                <h5 className='my-2'><img className='mr-2'src="/Israel.png" alt="Online global trading Stocks: Israel" />Israel</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Tel Aviv Stock Exchange</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Netherlands.png" alt="Online global trading Stocks: Netherlands" />Netherlands</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">BATS Europe (BATEEN)</li>
                      <li className="paralist">CHI-X Europe Ltd Clearnet</li>
                      <li className="paralist">Euronext Netherlands</li>
                      <li className="paralist">Turquoise TRQXEN</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Latvia.png" alt="Online global trading Stocks: Latvia" />Latvia</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">NASDAQ Baltic (N.RIGA)</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Lithuania.png" alt="Online global trading Stocks: Lithuania" />Lithuania</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">NASDAQ Baltic (N.VILNIUS)</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Norway.png" alt="Online global trading Stocks: Norway" />Norway</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">OMXNO</li>
                      </ul>
                      <br />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <h5 className='my-2'><img className='mr-2'src="/Poland.png" alt="Online global trading Stocks: Pland" />Poland</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Warsaw Stock Exchange</li>
                      </ul>
                      <br />
                <h5 className='my-2'><img className='mr-2'src="/Russia.png" alt="Online global trading Stocks: Russia" />Russia</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Moscow Exchange (MOEX)</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Spain.png" alt="Online global trading Stocks: Spain" />Spain</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">BATS Europe (BATEES)</li>
                      <li className="paralist">Bolsa de Madrid</li>
                      <li className="paralist">CHI-X Europe Ltd. Clearnet (CHIXES)</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Sweden.png" alt="Online global trading Stocks: Sweden" />Sweden</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Swedish Stock Exchange</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Switzerland.png" alt="Online global trading Stocks: Switzerland" />Switzerland</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">BATS Europe (BATECH)</li>
                      <li className="paralist">Chi-X Europe Ltd Swiss</li>
                      <li className="paralist">SIX Swiss Exchange</li>
                      <li className="paralist">Turquoise TRQXCH</li>
                      <li className="paralist">VIRT-X</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/United_Kingdom.png" alt="Online global trading Stocks: United Kingdom" />United Kingdom</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">BATS Europe (BATEUK)</li>
                      <li className="paralist">CHI-X Europe Ltd Crest</li>
                      <li className="paralist">London Stock Exchange</li>
                      <li className="paralist">LSE International Order Book</li>
                      </ul>
                      <br />
                  </div>
              </div>
              
              <div className="row">
                <div className="col-12">
                  <h4 className='my-2'>Asia Pacific</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h6 className='my-2'>Stocks</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <h5 className='my-2'><img className='mr-2'src="/Australia.png" alt="Online global trading Stocks: Australia" />Australia</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Australian Stock Exchange (ASX)</li>
                    <li className="paralist">ASXCEN</li>
                      <li className="paralist">Chi-X Australia</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Hong_Kong.png" alt="Online global trading Stocks: Hong Kong" />Hong Kong</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Hong Kong Stock Exchange (SEHK)</li>
                      <li className="paralist">Shanghai - Hong Kong Stock Connect (SEHKNTL)</li>
                      </ul>
                      <br />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <h5 className='my-2'><img className='mr-2'src="/India.png" alt="Online global trading Stocks: India" />India</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">National Stock Exchange of India (NSE)</li>
                      </ul>
                      <br />
                      <h5 className='my-2'><img className='mr-2'src="/Japan.png" alt="Online global trading Stocks: Japan" />Japan</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">CHI-X Japan</li>
                      <li className="paralist">JAPANNEXT</li>
                      <li className="paralist">Tokyo Stock Exchange</li>
                      </ul>
                      <br />
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <h5 className='my-2'><img className='mr-2'src="/Singapore.png" alt="Online global trading Stocks: Singapore" />Singapore</h5>
                      <ul className='ml-4' style={{listStyleType: 'disc'}}>
                      <li className="paralist">Singapore Exchange</li>
                      </ul>
                      <br />
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        
        
        <h6>Bond</h6>
        <div className='row'>
          <div className='col-md-7'>
            <img className='img-fluid mb-2' src='t-indices.jpeg' />
          </div>
        </div>

        <p className='cus-strip'>Bonds are commonly referred to as fixed income securities and are one of the asset classes EazyMarkets specializes on trading.  We trade many corporate and government bonds, others are traded only over-the-counter (OTC) or privately between the borrower and lender.</p>
        <p className='cus-strip'>The initial price of most bonds is typically set at par, usually $100 or $1,000 face value per individual bond. The actual market price of a bond depends on a number of factors: the credit quality of the issuer, the length of time until expiration, and the coupon rate compared to the general interest rate environment at the time. The face value of the bond is what will be paid back to the borrower once the bond matures.</p>
        <p className='cus-strip'>Two features of a bond—credit quality and time to maturity—are the principal determinants of a bond's coupon rate. If the issuer has a poor credit rating, the risk of default is greater, and these bonds pay more interest. Bonds that have a very long maturity date also usually pay a higher interest rate. This higher compensation is because the bondholder is more exposed to interest rate and inflation risks for an extended period.</p>
        <p className='cus-strip'>Bonds that are not considered investment grade, but are not in default, are called “high yield” or “junk” bonds. These bonds have a higher risk of default in the future and investors demand a higher coupon payment to compensate them for that risk.</p>
        <p className='cus-strip'>Bonds and bond portfolios will rise or fall in value as interest rates change. The sensitivity to changes in the interest rate environment is called “duration.” The use of the term duration in this context can be confusing to new bond investors because it does not refer to the length of time the bond has before maturity. Instead, duration describes how much a bond’s price will rise or fall with a change in interest rates.</p>
        {/* <p className='cus-strip'></p>
        <p className='cus-strip'></p>
        <p className='cus-strip'></p> */}

        

      </div>
      <Footer />
    </div>
  );
};

export default Tradeoptions;
